import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification, columns } from 'utils';
import AppNotification from 'components/shared/AppNotification';

import Page from 'shared/AppPage';
import AppModal from 'components/shared/AppModal';
import AppIndex from 'shared/AppIndex';
import UpsellOfferForm from './components/UpsellOfferForm';

import { 
  Card, CardContent, LinearProgress, Typography, 
  Button, Stack, TextField,
  RadioGroup, FormControl, FormLabel, FormControlLabel, Radio
} from '@mui/material';
import { useQuery, useMutation, ApolloProvider } from '@apollo/client';
import apolloClient, { userErrorsToFormik, gql } from 'api/apollo-client';

import { useFormik, FormikProvider } from 'formik';
import dayjs from 'dayjs';

import lodashPick from 'lodash/pick';
import debounce from 'lodash/debounce';

const propTypes = {
  createURL: PropTypes.string.isRequired,
}

const defaultProps = {

}


// Depending if new or edit
const modalTextOptions = {
  new: { title: "Agregar Checkout Upsell", submitButton: "Crear" },
  edit: { title: "Editar Checkout Upsell", submitButton: "Actualizar" }
};

const createWhitelistFields = ['sku', 'shopifyProductId', 'shopifyVariantId', 'shopifyVariantTitle', 'shopifyImageUrl', 'enabled', 'shopifyProductHandle'];

const UpsellOffersIndex = (props) => {
  const [filterState, setFilterState] = useState({});
  const [sendNotification, notificationProps] = useMuiNotification();
  const formModalRef = useRef(null);

  const { loading, error, refetch, data } = useQuery(RECORDS_INDEX, { 
    fetchPolicy: 'network-only',
    variables: {
      filter: filterState,
      page: 1,
    },
    client: apolloClient,
  });

  const [recordCreate] = useMutation(RECORD_CREATE, { client: apolloClient });
  const [recordUpdate] = useMutation(RECORD_UPDATE, { client: apolloClient });
  const [recordDelete] = useMutation(RECORD_DELETE, { client: apolloClient });
  const [recordChangePosition] = useMutation(RECORD_CHANGE_POSITION, { 
    client: apolloClient,
    onCompleted: (resultData) => {
      const updatedRecordId = resultData?.upsellOfferChangePosition?.upsellOffer?.id;
      if(!!updatedRecordId){
        refetch();
      }
      else{
        const errors = resultData?.upsellOfferChangePosition?.errors.map(error => error.message).join(", ");
        sendNotification({ message: `Error al cambiar posición: ${errors}`, severity: "error" });
      }
    }
  });

  // Currently refetch to update UI but in the future to optimize the UI can update itself without refetch
  const handleFormSubmit = async (values, formikBag) => {
    
    try {
      let mutationResult; // Common result for create and update (record and errors)
      
      if(!!values.id){ // Update
        const updateResult = await recordUpdate({
          variables: { id: values.id, input: lodashPick(values, 'enabled') }
        });
        mutationResult = updateResult?.data?.upsellOfferUpdate;
      }
      else{ // Create
        const formValues = lodashPick(values, createWhitelistFields);
        const createResult = await recordCreate({
          variables: { input: formValues }
        });
        mutationResult = createResult?.data?.upsellOfferCreate;
      }
      
      if(mutationResult.upsellOffer?.id){ // Success
        if(!values.skipToast){
          sendNotification({ message: "Operación exitosa" });
        }
        formModalRef.current.close();
        refetch();
      }
      else{
        sendNotification({ message: "Error en los datos del formulario", severity: "error" });
        formikBag.setErrors(userErrorsToFormik(mutationResult.errors));
      }
    } catch (error) {
      console.log("error", error);
      sendNotification({ message: "Ocurrio un error, revise los datos o notifique al administrador", severity: "error" });
    }
  };

  const modalFormik = useFormik({
    initialValues: {
      sku: "",
      shopifyProductId: "",
      shopifyVariantId: "",
      shopifyVariantTitle: "",
      shopifyImageUrl: "",
      shopifyProductHandle: "",
      enabled: true,
    },
    enableReinitialize: true,
    onSubmit: handleFormSubmit
  });

  // Open modal with new form
  const handleNewItem = () => {
    modalFormik.resetForm();
    formModalRef.current.open();
  }

  const handleDelete = async (itemId) => {
    if(confirm("¿Estás seguro de eliminar este registro?")){
      const result = await recordDelete({ variables: { id: itemId } });
      const deletedResourceId = result?.data?.upsellOfferDelete?.deletedResourceId;
      if(!!deletedResourceId){
        sendNotification({ message: "Operación exitosa" });
        refetch();
      }
      else{
        console.log("result", result);
        const errors = result?.data?.upsellOfferDelete?.errors.map(error => error.message).join(", ");
        sendNotification({ message: `Error al eliminar: ${errors}`, severity: "error" });
      }
    }
  };

  const handleChangePositionTrigger = (itemId, direction) => {
    recordChangePosition({ variables: { id: itemId, direction } });
  }

  const handleEnableToggle = (itemId, newEnabledValue) => {
    modalFormik.setValues({ id: itemId, enabled: newEnabledValue, skipToast: true })
    modalFormik.handleSubmit();
  }

  const handleQueryChange = useCallback(debounce( (event) => {
    console.log("query", event.target.value)
    const query = event.target.value;
    setFilterState((prevState) => ({ ...prevState, query }));
  }, 800), []);

  const handleEnabledFilterChange = (event) => {
    const value = event.target.value;
    if(value === ""){
      setFilterState((prevState) => ({ ...prevState, enabled: undefined }))
    }
    else{
      setFilterState((prevState) => ({ ...prevState, enabled: value == "true" }))
    }
  }

  const items = data?.upsellOffers?.items || [];

  if(!!error){
    console.log("useQuery error", error);
  }

  const modalTexts = modalFormik.values.id ? modalTextOptions.edit : modalTextOptions.new;

  return(
    <Page 
      title="Checkout Upsell"
      primaryAction={{ content: "Crear", onClick: handleNewItem }}
    >
      <AppNotification {...notificationProps} />

      <div style={{ minHeight: 4 }}>
        { loading && <LinearProgress color="primary" /> }
      </div>

      <div>
        <AppIndex
          items={items}
          columnsData={columns.upsellOffersColumns}
          columnUtils={{
            handleDelete,
            handleEnableToggle,
            handleChangePositionTrigger,
            positionTopId: data?.upsellOffers?.positionsMetadata?.positionTopId,
            positionBottomId: data?.upsellOffers?.positionsMetadata?.positionBottomId,
          }}
          header={
            <CardContent>
              <TextField
                fullWidth
                size='small'
                className='mb8'
                label="Buscar"
                placeholder='Producto, SKU, ID Shopify'
                variant="outlined"
                
                onChange={ handleQueryChange }
              />
              <FormControl>
                <FormLabel id="enabled-filter">Habilitado</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="enabled-filter"
                  name="row-radio-buttons-group"
                  onChange={ handleEnabledFilterChange }
                  defaultValue=""
                >
                  <FormControlLabel value="" control={<Radio />} label="Todos" />
                  <FormControlLabel value="true" control={<Radio />} label="Habilitado" />
                  <FormControlLabel value="false" control={<Radio />} label="No Habilitado" />
                </RadioGroup>
              </FormControl>
            </CardContent>
          }
        />

        <FormikProvider value={ modalFormik }>
          <AppModal
            ref={ formModalRef }
            title={ modalTexts.title }
            modalStyle={{ width: '50vw' }}
            footer={
              <Button variant='contained' onClick={ modalFormik.handleSubmit }>{ modalTexts.submitButton }</Button>
            }
          >
            <form onSubmit={ modalFormik.handleSubmit }>
              <UpsellOfferForm  />
            </form>
          </AppModal>
        </FormikProvider>
      </div>
    </Page>
  )
};

const RECORDS_INDEX = gql`
  query($filter: UpsellOfferFilterInput, $pagination: PaginationInput) {
    upsellOffers(filter: $filter, pagination: $pagination) {
      items{
        id
        sku
        shopifyProductId
        shopifyVariantId
        shopifyVariantTitle
        shopifyImageUrl
        enabled
        position

        lastUpdatedBy{
          id
          name
        }
      } # items
      metadata{
        page
        pages
      } # metadata

      positionsMetadata{
        positionTopId
        positionBottomId
      } # positionsMetadata
    } # upsellOffers
  } 
`;

const RECORD_CREATE = gql`
  mutation($input: UpsellOfferInput!) {
    upsellOfferCreate(input: $input) {
      upsellOffer{
        id
      } # upsellOffer
      errors{
        field
        message
      }
    } # upsellOfferCreate
  }
`;

const RECORD_UPDATE = gql`
  mutation($id: ID!, $input: UpsellOfferInput!) {
    upsellOfferUpdate(id: $id, input: $input) {
      upsellOffer{
        id
      } # upsellOffer
      errors{
        field
        message
      }
    } # upsellOfferUpdate
  }
`;

const RECORD_DELETE = gql`
  mutation($id: ID!) {
    upsellOfferDelete(id: $id) {
      deletedResourceId
      errors{
        field
        message
      }
    } # upsellOfferDelete
  }
`;

const RECORD_CHANGE_POSITION = gql`
  mutation($id: ID!, $direction: DirectionEnum!) {
    upsellOfferChangePosition(id: $id, direction: $direction) {
      upsellOffer{
        id
      } # upsellOffer
      errors{
        field
        message
      }
    } # upsellOfferChangePosition
  }
`;

UpsellOffersIndex.propTypes = propTypes;
UpsellOffersIndex.defaultProps = defaultProps;

export default UpsellOffersIndex;