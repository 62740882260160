import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import InputGroup from 'react-bootstrap/InputGroup';

import axios from 'utils/axios-rails';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
}

const defaultProps = {
  isAdmin: false
}

const InvoiceAdminIssue = (props) => {
  const [bpId, setBpId] = useState("");
  const [forceIssue, setForceIssue] = useState(false); // Only relevant to admins
  const [error, setError] = useState(null); // Only relevant to admins
  
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(false);

  const closeToast = () => setToast(null);

  const handleSubmit = async (event, basura) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(props.submitURL, {
        bp_id: bpId,
        force_issue: forceIssue
      });
      setBpId("");
      setForceIssue(false);
      setToast(response.data.message);
      console.log("R", response)
    } catch (error) {
      const ex = error;
      console.log("MAX", error);
      if(error.response && error.response.data){
        console.log(error.response.data);
        setToast(error.response.data.message);
        setError(error.response.data.error);
      } // errors
    }
    setLoading(false);
  }

  const handleCheckboxChange = (event, value) => {
    setForceIssue(event.target.checked);
  }

  const handleIdChange = (event) => {
    setBpId(event.target.value);
  }

  return (
    <Container className="mt20">
      <Toast style={{ position: "absolute", right: 0, top: 50, zIndex: 90 }} delay={3500} autohide show={ !!toast } onClose={ closeToast }>
        <Toast.Header>
          <strong className="mr-auto">{ toast } </strong>
        </Toast.Header>
      </Toast>

      <Card>
        <Card.Body>
          <Card.Title>Generar factura electrónica manualmente</Card.Title>
            <Form onSubmit={ handleSubmit }>
              <Form.Label>ID de órden de Brightpearl</Form.Label>
              <InputGroup>
                <Form.Control value={ bpId } onChange={ handleIdChange } placeholder="000" isInvalid={ !!error } />
                { error && <Form.Control.Feedback type="invalid">{ error }</Form.Control.Feedback> }
              </InputGroup>

              {/* checkbox */}
              { props.isAdmin &&
                <Form.Check
                  className="mt8"
                  type={ "checkbox" }
                  id={ "force_issue" }
                  label={'Reenviar'}
                  checked={ forceIssue }
                  onChange={ handleCheckboxChange }
                />
              }

              <Button variant="dark" type="submit" className="border-0 pv8 ph16 font-weight-bold mt20" disabled={ loading }>
              { loading ? "ENVIANDO..." : "ENVIAR" }
              </Button>
            </Form>
        </Card.Body>
      </Card>
    </Container>
  )
};

InvoiceAdminIssue.propTypes = propTypes;
InvoiceAdminIssue.defaultProps = defaultProps;

export default InvoiceAdminIssue;