import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  Card, CardContent, CardHeader, CardActions,
  IconButton 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const propTypes = {
  title: PropTypes.string,
  headerProps: PropTypes.shape({
    ...CardHeader.propTypes
  }),
  modalStyle: PropTypes.object.isRequired,
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {
  headerProps: {},
  modalStyle: {},
}

// To use with AppModal or hand made modals
const AppModalContent = (props) => {
  return (
    <Card
      className='mui-app-modal'
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        overflowY: 'auto',
        ...props.modalStyle,
      }}
    >
      { props.title &&  
        <>
          <CardHeader 
            title={ props.title }
            action={
              <IconButton onClick={ props.onClose }>
                <CloseIcon />
              </IconButton>
            }
            {...props.headerProps} 
          /> 
          <hr className='m0' />
        </>
      }
      <CardContent>
        { props.children }
      </CardContent>

      { props.footer &&
        <>
          <hr className='m0' />
          <CardActions className='p16 flex justify-end'>
            { props.footer }
          </CardActions>
        </>
      }
    </Card>
  )
};

AppModalContent.propTypes = propTypes;
AppModalContent.defaultProps = defaultProps;

export default AppModalContent;