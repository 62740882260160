import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';

import { Typography } from '@mui/material';

import axios from 'utils/axios-rails';

import AppInput from 'shared/AppInput';

import LocationDropdowns from './components/LocationDropdowns';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  employees_count_options: PropTypes.array.isRequired,
  sector_options: PropTypes.array.isRequired,
  company_department_options: PropTypes.array.isRequired,

  
  locationsDataset: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
}

const defaultProps = {

}

// Iframe reference (to allow submit and redirection)
// <iframe id="shogunBusinessIframe" sandbox="allow-scripts allow-top-navigation allow-forms allow-top-navigation-by-user-activation allow-same-origin" style="border: none;" width="100%"  src="https://factura.unimart.com/business_applications/new" title="Business SignUp"></iframe>
const UnimartBusinessSignUp = (props) => {
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const closeToast = () => setDisplaySuccess(false); 

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await axios.post(props.submitURL, {
        business_application: values
      });
      formik.resetForm();
      setDisplaySuccess(true);
      window.top.location.href = "https://www.unimart.com/pages/thankyouregistro-unimart-business"
    } catch (error) {
      const ex = error;
      if(error.response && error.response.data && error.response.status != 500){
        console.log("Errors", error.response.data);
        formik.setErrors(error.response.data);
      } // errors
      else{
        formik.setErrors({ general: "Ocurrió un error con la conexión. Por favor intente de nuevo o recargue la página" });
      }
    }
    formik.setSubmitting(false);
  }

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company_name: '',
      national_id: '',
      canton: '',
      province: '',
      address: '',
      sector: '',
      employees_count: '',
      district: '',
      company_department:'',
      country: props.country,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const { values } = formik;

  return (
    <div id="UnimartBusinessFormContainer" className="app-container">
      <Form style={{ position: "relative" }} className="pv28 ph12" onSubmit={ formik.handleSubmit } >
  
        <Toast style={{ position: "absolute", right: 0, top: 0, zIndex: 90 }} delay={3500} autohide show={displaySuccess} onClose={ closeToast }>
          <Toast.Header>
            <strong className="mr-auto">Envíado con éxito</strong>
          </Toast.Header>
        </Toast>
  
        {/* <h4 className="mb28"><strong>Aplicá a cuenta <u>gratuita</u> de Unimart Business</strong></h4> */}
        { formik.errors.general && <Typography variant="body1" color="error" className="mb20">{ formik.errors.general }</Typography> }
  
        <Form.Row>
          <Form.Group as={Col} sm={6} xs={12}>
            <AppInput 
              label="Nombre" 
              name="first_name" 
              value={ values.first_name } onChange={ formik.handleChange } placeholder=""
              error={ formik.errors.first_name }
            />
          </Form.Group>
          <Form.Group as={Col} sm={6} xs={12} className="mb20">
            <AppInput 
              label="Apellidos"
              name="last_name" value={ values.last_name } onChange={ formik.handleChange } placeholder="" 
              error={ formik.errors.last_name } />
          </Form.Group>
          
          <Form.Group as={Col} sm={6} xs={12}>
            <AppInput 
              label="Correo electrónico empresarial"
              name="email" value={ values.email } onChange={ formik.handleChange } type="email" placeholder="" 
              error={ formik.errors.email } />
          </Form.Group>
          <Form.Group as={Col} sm={6} xs={12} className="mb20">
            <AppInput 
              label="Teléfono"
              name="phone" value={ values.phone } onChange={ formik.handleChange } placeholder="" 
              error={ formik.errors.phone } />
          </Form.Group>
          
          <Form.Group as={Col} sm={6} xs={12}>
            <AppInput 
              label="Nombre de empresa"
              name="company_name" value={ values.company_name } onChange={ formik.handleChange } placeholder="" 
              error={ formik.errors.company_name } />
          </Form.Group>
          <Form.Group as={Col} sm={6} xs={12} className="mb20">
            <AppInput 
              label="Cédula jurídica"
              name="national_id" value={ values.national_id } onChange={ formik.handleChange } type="number" placeholder="" 
              error={ formik.errors.national_id } />
          </Form.Group>
          
          {/* <Form.Group as={Col} xs={12} className="mb20">
            <AppInput 
              label="Dirección de entrega"
              name="address" value={ values.address } onChange={ formik.handleChange } placeholder="" 
              error={ formik.errors.address } /> 
            {<Form.Text muted>
              La dirección solo se puede cambiar por medio de un Ejecutivo de Cuentas Corporativas una vez aprobada la cuenta por lo que le recomendamos ser lo más preciso posible.
            </Form.Text> }
          </Form.Group> */}

          {/* TODO redundant props */}
          
          {/* <LocationDropdowns
            onChange={ formik.setFieldValue }
            errors={ formik.errors }
            locationsDataset={ props.locationsDataset }
          /> */}
  
          {/* Dropdowns (TODO when changing to MUI use a component!)*/}

          <Form.Group as={Col} sm={12} xs={12}>
            <Form.Label>Departamento</Form.Label>
            <Form.Control onChange={ formik.handleChange } name="company_department"  as="select" custom>
              { props.company_department_options.map( (option, index) => 
                <option key={ option.value } value={ option.value }>{ option.label }</option>
              )}
            </Form.Control>
          </Form.Group>
          
          <Form.Group as={Col} sm={6} xs={12}>
            <Form.Label>Sector</Form.Label>
            <Form.Control onChange={ formik.handleChange } name="sector"  as="select" custom>
              { props.sector_options.map( (option, index) => 
                <option key={ option.value } value={ option.value }>{ option.label }</option>
              )}
            </Form.Control>
          </Form.Group>
  
          <Form.Group as={Col} sm={6} xs={12} className="mb20">
            <Form.Label>Cantidad de empleados</Form.Label>
            <Form.Control onChange={ formik.handleChange } name="employees_count"  as="select" custom>
              { props.employees_count_options.map( (option, index) => 
                <option key={ option.value } value={ option.value }>{ option.label }</option>
              )}
            </Form.Control>
          </Form.Group>
        </Form.Row>
  
        <div className="flex">
          <Button className="border-0 pv8 ph16 font-weight-bold" variant="dark" type="submit" disabled={ formik.isSubmitting } block>
            { formik.isSubmitting ? "ENVIANDO..." : "SOLICITAR CUENTA GRATUITA" }
          </Button>
        </div>
      </Form>
    </div>
  )
};

UnimartBusinessSignUp.propTypes = propTypes;
UnimartBusinessSignUp.defaultProps = defaultProps;

export default UnimartBusinessSignUp;