import React from 'react';
import PropTypes from 'prop-types';

import { LEAF_TYPES } from './functions/actions';

const propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  leaf: PropTypes.object.isRequired,
}

// Markdown renderers. Must be inline elements. - A node can have multiple marks
const Leaf = ({ attributes, children, leaf }) => {
  // i.e. leaf.bold, leaf.link, etc. whereas a leaf looks like { text: 'Some text for section', bold: true, link: "example.com", italic: true }
  if (leaf[LEAF_TYPES.BOLD]) {
    children = <strong>{children}</strong>
  }

  if (leaf[LEAF_TYPES.CODE]) {
    children = <code>{children}</code>
  }

  if (leaf[LEAF_TYPES.ITALIC]) {
    children = <em>{children}</em>
  }

  if (leaf[LEAF_TYPES.UNDERLINE]) {
    children = <u>{children}</u>
  }

  if (leaf[LEAF_TYPES.LINK]) {
    children = <a href={ leaf.link }>{children}</a>
  }

  return <span {...attributes}>{children}</span>
}

export default Leaf;