import { gql } from '@apollo/client';

export const APP_PAGES = gql`
query($filter: AppPageFilterInput, $pagination: PaginationInput){
  appPages(filter: $filter, pagination: $pagination){
    items{
      id
      kindHuman
      status
      statusHuman
      shopifyTitle
      shopifyHandle

      updatedAtHuman
      publishedAtHuman

      lastUpdatedBy{
        id
        name
      } # lastUpdatedBy
      lastPublishedBy{
        id
        name
      } # lastPublishedBy
    } # items
    metadata{
      page
      pages
    }
  } # appPages
}
`;

export const APP_PAGE_GET = gql`
query($id: ID!){
  appPage(id: $id){
    id
    contentLatest
    kind
    status
    publishable
    shopifyResourceGid
    shopifyTitle
    shopifyHandle
    lastUpdatedBy{
      id
      name
    }
  }
}
`;

export const APP_PAGE_CREATE = gql`
mutation($input: AppPageInput!){
  appPageCreate(input: $input){
    appPage{
      id
    }
    errors{
      field
      message
    }
  }
}
`;

export const APP_PAGE_UPDATE = gql`
mutation($id: ID!, $input: AppPageInput!){
  appPageUpdate(id: $id, input: $input){
    appPage{
      id
    }
    errors{
      field
      message
    }
  }
}
`;

export const APP_PAGE_DELETE = gql`
mutation($id: ID!){
  appPageDelete(id: $id){
    deletedResourceId
    errors{
      field
      message
    }
  }
}
`;

export const APP_PAGE_SHOPIFY_RESOURCE_SEARCH = gql`
query($kind: AppPageKindEnum!, $query: String!){
  items: shopifyResourceSearch(kind: $kind, query: $query){
    id
    title
    handle
  }
}
`;

export const APP_PAGE_SHOPIFY_RESOURCE_BY_URL = gql`
query($url: String!){
  shopifyResourceByUrl(url: $url){
    resource{
      id
    }
    error
  }
}
`;

// Assets
export const PAGE_ASSET_UPSERT = gql`
mutation($input: AppPageAssetInput!){
  appPageAssetUpsert(input: $input){
    appPageAsset{
      id
      mediaUrl
    }
    errors{
      field
      message
    }
  }
}
`;