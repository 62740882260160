import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Stack, Skeleton } from '@mui/material';

const propTypes = {

}

const defaultProps = {

}

const SkeletonCard = (props) => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rectangular"  height={ 300 }/>
    </Stack>
  )
};

SkeletonCard.propTypes = propTypes;
SkeletonCard.defaultProps = defaultProps;

export default SkeletonCard;