import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNode } from "@craftjs/core";

import ContentEditable from 'react-contenteditable'

import { FormControl, FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AlignHorizontalLeft, AlignHorizontalCenter } from '@mui/icons-material';
import DropdownSelect from 'components/shared/form/DropdownSelect';

const HEADING_VARIANTS = [
  { label: "h1", value: "heading-1", tag: "h1" },
  { label: "h2", value: "heading-2", tag: "h2" },
  { label: "h3", value: "heading-3", tag: "h3" },
  { label: "h4", value: "heading-4", tag: "h4" },
  { label: "h5", value: "heading-5", tag: "h5" },
];

const TEXT_ALIGNMENTS = ["left", "center"]; // Available options for text alignment

const propTypes = {
  content: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(HEADING_VARIANTS.map((variant) => variant.value)),
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
}

const defaultProps = {
  content: "Edit me",
  variant: "heading-2",
  textAlign: "center",
}

const Hero = (props) => {
  const { content, variant, textAlign, ...builderProps } = props;
  const { connectors: { connect, drag }, selected, actions: { setProp } } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const [editable, setEditable] = useState(false);

  // Disable editing when not selected
  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  const headingVariant = HEADING_VARIANTS.find((item) =>item.value === variant);

  const contentClassNames = [
    'user-hero-content',
    `user-hero-text-align-${textAlign}`,
  ]

  const heroVariantClass = `user-hero-variant-${props.variant}`;

  return (
    <div 
      {...builderProps} 
      className={`user-block user-hero ${selected ? 'selected' : ''} ${heroVariantClass}`}
      ref={ ref => connect(drag(ref)) }
      onClick={() => selected && setEditable(true)}
    >
      <ContentEditable
        html={content}
        disabled={!editable}
        onChange={e => 
          setProp(props => 
            props.content = e.target.value.replace(/<\/?[^>]+(>|$)/g, "")  
          )
        }
        className={contentClassNames.join(" ")}
        tagName={ headingVariant.tag }
        style={{ textAlign: textAlign }}
      />
    </div>
  )
};

// Settings panel for block
const HeroSettings = () => {
  const { actions: {setProp}, editableProps } = useNode((node) => ({
    editableProps: {
      // content: node.data.props.content,
      variant: node.data.props.variant,
      textAlign: node.data.props.textAlign,
    }
  }));

  const handleAlignmentChange = (e, value) => {
    if(!!value){
      setProp(props => props.textAlign = value);
    }
  }

  return (
    <>
      <DropdownSelect
        fullWidth
        label="Variant"
        name="variant"
        value={editableProps.variant}
        onSelect={(_name, value) => setProp(props => props.variant = value)}
        options={HEADING_VARIANTS}
      />

      <FormLabel className='mt8' component="legend">Text Align</FormLabel>
      <ToggleButtonGroup
        exclusive
        fullWidth
        value={editableProps.textAlign}
        onChange={handleAlignmentChange}
        >
          <ToggleButton value="left" aria-label="left aligned">
            <AlignHorizontalLeft />
          </ToggleButton>
          <ToggleButton value="center" aria-label="centered">
            <AlignHorizontalCenter />
          </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

Hero.craft = {
  props: defaultProps,
  related: {
    name: () => "Títular",
    settings: HeroSettings
  }  
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;