import $ from "jquery";

document.addEventListener("turbolinks:load", () => {
    let $container = $("#filter-container");

    $container.find("[data-clear]").on('click', function(){
      let $this = $(this);
      let $inputGroup = $this.closest(".input-group")
      $inputGroup.find("select").val("");
      $inputGroup.find("input").val("");
    });

    $('.input-daterange').datepicker({
      language: "es",
      format: "dd-mm-yyyy",
    });

    const tenantCountry = $('input#invoices_filter_region').val();

    $container.find("button[name=invoice-filter]").on('click', function(){
      let filterStatus = $container.find('#filter-status').val();
      let filterDocType = $container.find('#filter-doc-type').val();
      let filterText = $container.find('#filter-text').val();
      let filterFrom = $container.find('#filter-from').val();
      let filterUntil = $container.find('#filter-until').val();
      let loc = `${location.protocol}//${location.host}/${tenantCountry}/invoices/?st=${filterStatus}&doc=${filterDocType}&txt=${filterText}&from=${filterFrom}&until=${filterUntil}` ;
      // window.location.replace( loc );
      window.location.href = loc;
    })

    // Cuotas/Orders filters
    $container.find("button[name=order-filter]").on('click', function (){
        let filterStatus = $container.find('#filter-status').val();
        let filterText = $container.find('#filter-text').val();
        let filterGateway = $container.find('#filter_gateway').val();
        let loc = `${location.protocol}//${location.host}${location.pathname}?status=${filterStatus}&txt=${filterText}&gateway=${filterGateway}`;
        // window.location.replace( loc );
        window.location.href = loc;
    })
  }
)