import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logo from 'images/logo-app.png';
import CrFlag from 'images/flags/flag-cr.svg';
import GtFlag from 'images/flags/flag-gt.svg';

const propTypes = {
  rootURL: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string, // Required if not group
    target: PropTypes.string,
    group: PropTypes.bool,
  }),),
  logoutLink: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    method: PropTypes.string,
  }),

  currentPath: PropTypes.string.isRequired,
  currentRegion: PropTypes.oneOf(['cr', 'gt']).isRequired,
}

const REGIONS_DATA = {
  cr: {
    name: 'Costa Rica',
    flag: CrFlag,
  },
  gt: {
    name: 'Guatemala',
    flag: GtFlag,
  }
}

const defaultProps = {
  links: []
}

const AppHeader = (props) => {

  const replaceRegionInUrl = (newRegion) =>{
    return props.currentPath.replace(/^\/(cr|gt)(\/|$)/, `/${newRegion}$2`);
  }

  const currentRegionData = REGIONS_DATA[props.currentRegion];

  return (
    <Navbar id="app-navbar" expand="lg" style={{ backgroundColor: "#262c37" }}>
      <Navbar.Brand href={ props.rootURL }>
        <img src={ Logo } height="30"/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          { props.links.map( (link, index) => (
            link.group ? <DropdownLinks key={index} group={ link } /> : <Nav.Link key={index} href={ link.url } target={ link.target || "_self" }>{ link.name }</Nav.Link>
          ))}
        </Nav>
        <Nav>
          <NavDropdown 
            title={
              <>
                { !currentRegionData && <span>Región</span> }
                { currentRegionData && <RegionItem region={currentRegionData} /> }
              </>
            }
            className='header-region-dropdown'
          >
            <NavDropdown.Item 
              href={ replaceRegionInUrl('cr') }
            >
              <RegionItem region={REGIONS_DATA.cr} />
            </NavDropdown.Item>
            <NavDropdown.Item 
              href={ replaceRegionInUrl('gt') }
            >
              <RegionItem region={REGIONS_DATA.gt} />
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav>
          <Nav.Link className="" data-method="delete" href={ props.logoutLink.url }>{ props.logoutLink.name }</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
};

const RegionItem = ({region}) => (
  <div className='flex justify-center items-center'>
    <img src={ region.flag } height="20" className='mr-2' />
    <span>{ region.name }</span>
  </div>
)

const DropdownLinks = ({group}) => (
  <NavDropdown title={ group.name }>
    { group.items.map( (link, index) => (
      link.divider ? <NavDropdown.Divider key={index} /> : <NavDropdown.Item key={index} href={ link.url }>{ link.name }</NavDropdown.Item>
    ))}
  </NavDropdown>
)

AppHeader.propTypes = propTypes;
AppHeader.defaultProps = defaultProps;

export default AppHeader;