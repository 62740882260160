import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PreviewEditableTitleAndDescription from './components/PreviewEditableTitleAndDescription';
import SettingsBannerWithDescription from './components/SettingsBannerWithDescription';

import { useNode } from '@craftjs/core';
import { useFormikContext } from 'formik';

import { Grid, FormLabel, Divider } from '@mui/material';

import PreviewBannerImage from './components/PreviewBannerImage';

import * as SHAPES from './types';

const propTypes = {
  item1: PropTypes.shape(SHAPES.shapeForBannerWithDescription),
  item2: PropTypes.shape(SHAPES.shapeForBannerWithDescription),
  item3: PropTypes.shape(SHAPES.shapeForBannerWithDescription),
}

const defaultProps = {
  item1: SHAPES.defaultPropsForBannerWithDescription,
  item2: SHAPES.defaultPropsForBannerWithDescription,
  item3: SHAPES.defaultPropsForBannerWithDescription,
}

const BannerTrio = (props) => {
  const { status } = useFormikContext();

  const {
    item1, item2, item3,
    ...builderProps 
  } = props;

  const { connectors: { connect, drag }, selected, actions: { setProp } } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  const gridItemSize = status.viewport === "DESKTOP" ? 4 : 12;

  return (
    <div
      className="user-block user-banner-trio"
      ref={ref => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
      {...builderProps}
    >
      <Grid container spacing={3}>
        <BannerTrioItem item={item1} gridItemSize={gridItemSize} editable={editable} sequence={1} />
        <BannerTrioItem item={item2} gridItemSize={gridItemSize} editable={editable} sequence={2} />
        <BannerTrioItem item={item3} gridItemSize={gridItemSize} editable={editable} sequence={3} />
      </Grid>
    </div>
  )
};

const BannerTrioItem = ({ item, gridItemSize, editable, sequence }) => {
  const valuesPrefix = `item${sequence}`; // Keys need to have the full path to be able to write on the parent component PROPS.
  return(
    <Grid item xs={gridItemSize}>
      <div className='item-container'>
        <PreviewBannerImage banner={item}/>
  
        <PreviewEditableTitleAndDescription
          values={{ [valuesPrefix]: item}}
          titleKey={`${valuesPrefix}.title`}
          descriptionKey={`${valuesPrefix}.description`}
          editable={editable} 
        />
      </div>
    </Grid>
  )
};

const BannerTrioSettings = () => {
  return(
    <>
      <BannerTrioSettingsItem sequence={1} />
      <Divider />
      <BannerTrioSettingsItem sequence={2} />
      <Divider />
      <BannerTrioSettingsItem sequence={3} />
    </>
  )
};

const BannerTrioSettingsItem = ({ sequence }) => {
  return(
    <>
      <FormLabel>Banner {sequence}</FormLabel>
      <SettingsBannerWithDescription
        imageUrlKey={`item${sequence}.imageUrl`}
        resourceIdKey={`item${sequence}.resourceId`}
        resourceUrlKey={`item${sequence}.resourceUrl`}
        imageSettings={{
          sequence,
          display_type: "GENERAL"
        }}
        titleKey={`item${sequence}.title`}
        descriptionKey={`item${sequence}.description`}
        imageWidth={380}
        imageHeight={253}
      />
    </>
  )
}

BannerTrio.craft = {
  props: defaultProps,
  related: {
    name: () => "Banner Trio",
    settings: BannerTrioSettings
  }  
}

BannerTrio.propTypes = propTypes;
BannerTrio.defaultProps = defaultProps;

export default BannerTrio;