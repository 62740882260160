import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { 
  Modal, Card, CardContent, CardHeader, CardActions,
  IconButton 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AppModalContent from './AppModalContent';

const propTypes = {
  initialOpen: PropTypes.bool,
}

const defaultProps = {
  initialOpen: false,
}

const defaultMessage = { title: null, content: null, status: "success" };

/**
 * @type React.ForwardRefRenderFunction<React.FunctionComponent, propTypes>
 */
const GeneralModal = forwardRef( (props, ref) => {
  const { 
    initialOpen,
    children, title, headerProps, modalStyle, footer, // Content
    ...modalProps 
  } = props;
  const [open, setOpen] = useState(initialOpen);
  
  const openModal = useCallback( () => setOpen(true), []);
  const closeModal = useCallback( () => setOpen(false), []);

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
    // isOpen: open, Doesn't send value inmediately after updates and cause issues
  }), []);

  // console.log("GeneralModal", props);
  
  return (
    <Modal
      open={ open }
      onClose={ closeModal }
      {...modalProps}
    >
      <div>
        <AppModalContent
          title={ title }
          headerProps={ headerProps }
          modalStyle={ modalStyle }
          footer={ footer }
          onClose={ closeModal }
        >
          { children }
        </AppModalContent>
      </div>
    </Modal>
  )
});

GeneralModal.propTypes = propTypes;
GeneralModal.defaultProps = defaultProps;

export default GeneralModal;