// Currently using the formik context instead, if more values are needed, this context can be used.
import { createContext, useContext, useState } from 'react';

const PageBuilderContext = createContext(null);

export const ThemeProvider = ({ children }) => {
  const [appPageId, setAppPageId] = useState(null);

  return (
    <PageBuilderContext.Provider value={{ theme, setTheme }}>
      {children}
    </PageBuilderContext.Provider>
  );
}