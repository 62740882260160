import { gql } from '@apollo/client';

// Move to independent files and import here when the file grows too much
export const PRODUCT_DEALS = gql`
query($filter: ProductDealFilterInput, $pagination: PaginationInput){
  productDeals(filter: $filter, pagination: $pagination){
    items{
      id
      status
      shopifyVariantId
      sku
      shopifyVariantTitle
      shopifyImageUrl
      skipHomePage

      startsAt
      endsAt
      quantity
      quantitySold

      dealPrice
      dealCompareAtPrice
      originalPrice
      originalCompareAtPrice

      dealPriceMoney
      dealCompareAtPriceMoney

      humanDateRange
      humanStatus
      lastUpdatedBy{
        id
        name
      } # lastUpdatedBy
      updatedAt
    } # items
    metadata{
      page
      pages
    }
  } # productDeals
}
`;

export const PRODUCT_DEAL_CREATE = gql`
mutation($input: ProductDealInput!){
  productDealCreate(input: $input){
    productDeal{
      id
      status
    }
    errors{
      field
      message
    }
  }
}
`;

export const PRODUCT_DEAL_UPDATE = gql`
mutation($id: ID!, $input: ProductDealInput!){
  productDealUpdate(id: $id, input: $input){
    productDeal{
      id
      status
      quantity
      startsAt
      endsAt
    }
    errors{
      field
      message
    }
  }
}
`;

export const PRODUCT_DEAL_DELETE = gql`
mutation($id: ID!){
  productDealDelete(id: $id){
    deletedResourceId
    errors{
      field
      message
    }
  }
}
`;

export const SHOPIFY_VARIANTS_SEARCH = gql`
query($query: String!){
  items: shopifyVariantsSearch(query: $query){ # alias for autocomplete
    id
    productId
    productHandle
    sku
    displayName
    price
    titleWithSku # calculated!
    imageUrl
  } # items
}
`;