import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Typography, IconButton, Button, CircularProgress } from '@mui/material';
import { Delete, Image as ImagePlaceholderIcon } from '@mui/icons-material';

import {useDropzone} from 'react-dropzone'

const propTypes = {
  // imageFile: PropTypes.shape({
  //   url: PropTypes.string, // For existing images
  //   type: PropTypes.string,
  //   name: PropTypes.string,
  // }),
  imageUrl: PropTypes.string,
  
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

const defaultProps = {
  disabled: false,
  loading: false,
}

const ImageDropZone = (props) => {
  const { imageUrl, onDelete, label, disabled, loading, ...dropZoneProps } = props;
  
  const handleDrop = useCallback(acceptedFiles => {
    props.onChange(acceptedFiles[0]);
    // Do something with the files
  }, [])

  const {getRootProps, getInputProps} = useDropzone({ onDrop: handleDrop })

  const isDisabled = disabled || loading;

  const uploadPlaceholder = (
    <>
      <ImagePlaceholderIcon fontSize="large" color="primary" />
      <Button className='mv12' variant="outlined" disabled={isDisabled}>
        Añadir imagen
      </Button>
      <span>o arrastra la imagen</span>
    </>
  );

  return (
    <div className='components-image-dropzone mv8'>
      <div className="actions-container">
        <div className="components-label">
          <Typography>{ label }</Typography>
        </div>
        { onDelete &&
          <IconButton onClick={onDelete} color='error' size='small' disabled={ isDisabled }>
            <Delete />
          </IconButton>
        }
      </div>

      <div {...getRootProps({className: 'dropzone'})}>
        <input disabled={ isDisabled } {...getInputProps()} />
        <div className="dropzone-placeholder" >
          {/* Loading is placed on top of anything */}
          { loading && <CircularProgress /> }

          {/* Display image if provided */}
          { !loading && imageUrl && (
            <img style={{ maxWidth: "100%" }} src={ props?.imageUrl } />
          )}

          {/* No image, display placeholder instructions */}
          { !loading && !imageUrl && uploadPlaceholder }
        </div>
      </div>
    </div>
  )
};

ImageDropZone.propTypes = propTypes;
ImageDropZone.defaultProps = defaultProps;

export default ImageDropZone;