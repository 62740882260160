import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import AppInput from 'shared/AppInput';

import axios from 'utils/axios-rails';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const GiftCardOrderNew = (props) => {
  const [message, setMessage] = useState(null);

  const closeToast = () => setMessage(null); 

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await axios.post(props.submitURL, {
        gift_card_order: values
      });
      formik.resetForm();
      setMessage(response.data.message);
    } catch (error) {
      const ex = error;
      console.log("MAX", error);
      if(error.response && error.response.data){
        formik.setErrors(error.response.data);
      } // errors
    }
    formik.setSubmitting(false);
  }

  const formik = useFormik({
    initialValues: {
      quantity: "",
      amount: "",
      notes: "",
    },
    onSubmit: handleSubmit
  });

  const { values, errors } = formik;

  console.log("E", errors)

  return (
    <Container className="mt20">
      <Card>
        <Card.Body>
          <Card.Title>Generar Gift Cards</Card.Title>
          <Form onSubmit={ formik.handleSubmit } >
            { !!message &&
              <Toast style={{ position: "absolute", right: 0, top: 0, zIndex: 90 }} delay={3500} autohide show onClose={ closeToast }>
                <Toast.Header>
                  <strong className="mr-auto">{ message }</strong>
                </Toast.Header>
              </Toast>
            }
            <Form.Row>
              <Form.Group as={Col} sm={6} xs={12}>
                <AppInput
                  label="Cantidad"
                  name="quantity"
                  value={ values.quantity } onChange={ formik.handleChange } placeholder=""
                  error={ errors.quantity }
                  type="number"
                />
              </Form.Group>
              <Form.Group as={Col} sm={6} xs={12} className="mb20">
                <AppInput
                  label="Monto (Colones)"
                  attachedText="₡"
                  name="amount" value={ values.amount } onChange={ formik.handleChange } placeholder=""
                  error={ errors.amount } />
              </Form.Group>
          
              <Form.Group as={Col} sm={12} xs={12}>
                <AppInput
                  label="Notas"
                  name="notes" value={ values.notes } onChange={ formik.handleChange } placeholder=""
                  error={ errors.notes } />
              </Form.Group>
            </Form.Row>
            <div className="flex justify-center">
              <Button className="border-0 pv8 ph16 font-weight-bold" variant="dark" type="submit" disabled={ formik.isSubmitting }>
                { formik.isSubmitting ? "ENVIANDO..." : "CREAR" }
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
};

GiftCardOrderNew.propTypes = propTypes;
GiftCardOrderNew.defaultProps = defaultProps;

export default GiftCardOrderNew;