import $ from "jquery";

document.addEventListener("turbolinks:load", () => {
    let $container = $("#container_action");
    $container.find("#submit_action").on('click', function (){
        let value_input = $container.find("input").val();
        let input_name = $container.find("input").attr('name');
        let loc = `${location.protocol}//${location.host}${location.pathname}?${input_name}=${value_input}`;
        window.location.href = loc
    })
})

document.addEventListener("turbolinks:load", () => {
  let $container = $("#container_action1");
  $container.find("#submit_action").on('click', function (){
      let value_input = $container.find("input").val();
      let input_name = $container.find("input").attr('name');
      let loc = `${location.protocol}//${location.host}${location.pathname}?${input_name}=${value_input}`;
      window.location.href = loc
  })
})

document.addEventListener("turbolinks:load", () => {
  let $container = $("#container_action2");
  $container.find("#submit_action").on('click', function (){
      let value_input = $container.find("input").val();
      let input_name = $container.find("input").attr('name');
      let loc = `${location.protocol}//${location.host}${location.pathname}?${input_name}=${value_input}`;
      window.location.href = loc
  })
})

document.addEventListener("turbolinks:load", () => {
  let $container = $("#container_action3");
  $container.find("#submit_action").on('click', function (){
      let value_input = $container.find("input").val();
      let input_name = $container.find("input").attr('name');
      let loc = `${location.protocol}//${location.host}${location.pathname}?${input_name}=${value_input}`;
      window.location.href = loc
  })
})

document.addEventListener("turbolinks:load", () => {
  let $container = $("#container_action4");
  $container.find("#submit_action").on('click', function (){
      let value_input = $container.find("input").val();
      let input_name = $container.find("input").attr('name');
      let loc = `${location.protocol}//${location.host}${location.pathname}?${input_name}=${value_input}`;
      window.location.href = loc
  })
})


