import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification, columns } from 'utils';
import AppNotification from 'components/shared/AppNotification';

import { useQuery, useMutation } from '@apollo/client';
import apolloClient, { APP_PAGES, APP_PAGE_DELETE } from 'api/apollo-client';

import { TextField, Card, CardContent } from '@mui/material';

import DropdownSelect from 'shared/form/DropdownSelect';
import Page from 'shared/AppPage';
import AppIndex from 'shared/AppIndex';

import debounce from 'lodash/debounce';

const propTypes = {
  kinds: PropTypes.array.isRequired,
  builderURLBase: PropTypes.string.isRequired, // stuff/:ID
}

const defaultProps = {

}

const AppPagesIndex = (props) => {
  const [filterState, setFilterState] = useState({});
  const [sendNotification, notificationProps] = useMuiNotification();

  const { loading, error, refetch, data } = useQuery(APP_PAGES, {
    fetchPolicy: "network-only",
    variables: {
      filter: filterState,
      page: 1,
    },
    client: apolloClient,
  });

  const [deleteItem] = useMutation(APP_PAGE_DELETE, { client: apolloClient });

  const handleDelete = async (itemId) => {
    if(confirm("¿Estás seguro de eliminar este registro?")){
      const result = await deleteItem({ variables: { id: itemId } });
      const deletedResourceId = result?.data?.appPageDelete?.deletedResourceId;
      if(!!deletedResourceId){
        sendNotification({ message: "Operación exitosa" });
        refetch();
      }
      else{
        console.log("result", result);
        const errors = result?.data?.appPageDelete?.errors.map(error => error.message).join(", ");
        sendNotification({ message: `Error al eliminar: ${errors}`, severity: "error" });
      }
    }
  }

  const items = data?.appPages?.items || [];

  if(!!error){
    console.log("Error", error);
  }

  const newURL = props.builderURLBase.replace(":ID", "");

  // Debounced filter change. Push to filter state after 800ms
  const handleQueryChange = useCallback(debounce( (event) => {
    const query = event.target.value;
    setFilterState((prevState) => ({ ...prevState, query }));
  }, 800), []);

  const handleKindChange = (_name, value) => {
    setFilterState((prevState) => ({ ...prevState, kind: value }));
  }

  return (
    <Page
      title="Content Manager"
      primaryAction={{
        content: "Crear",
        url: newURL,
      }}
      >
      <AppNotification {...notificationProps} />

      <AppIndex
        items={items}
        columnsData={columns.appPagesColumns}
        columnUtils={{
          handleDelete: handleDelete,
          builderURLBase: props.builderURLBase,
        }}
        header={
          <CardContent>
            <TextField
              fullWidth
              size='small'
              className='mb8'
              label="Buscar"
              placeholder='ID, Title, Handle'
              variant="outlined"
              
              onChange={ handleQueryChange }
            />

            <DropdownSelect
              fullWidth
              autoWidth={false}
              label="Tipo"
              options={ props.kinds }
              onSelect={ handleKindChange }
              initialValue=""
            />
        </CardContent>
        }
      />
    </Page>
  )
};

AppPagesIndex.propTypes = propTypes;
AppPagesIndex.defaultProps = defaultProps;

export default AppPagesIndex;