import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {

}

const defaultProps = {

}

const Echo = (props) => {
  console.log(props);
  return (
    <div>
      <h1>ECHO</h1>
    </div>
  )
};

Echo.propTypes = propTypes;
Echo.defaultProps = defaultProps;

export default Echo;