import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField } from "@mui/material";
import { useField } from 'formik';

const propTypes = {
  name: PropTypes.string.isRequired,
}

const defaultProps = {

}

// MUIxFormik TextField
const AppTextField = (props) => {
  const [field, meta] = useField(props);

  return (
    <TextField 
      {...field}
      {...props}
      error={ meta.touched && !!meta.error }
      helperText={ meta.touched && meta.error }
    />
  )
};

AppTextField.propTypes = propTypes;
AppTextField.defaultProps = defaultProps;

export default AppTextField;