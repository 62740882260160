import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { 
  ButtonGroup, Button, Popper, Grow, Paper,  
  ClickAwayListener, MenuList, MenuItem
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.string.isRequired,
  url: PropTypes.string,

  secondaryActions: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }))
}

const defaultProps = {

}

const ButtonWithActions = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = useCallback( () => setOpen( (currentOpen) => !currentOpen ), []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button onClick={ props.onClick } href={ props.url }>
          { props.content }
        </Button>

        {/* Only displays dropdown icon if any secondary action added */}
        { props.secondaryActions &&
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={ handleToggle }
          >
            <ArrowDropDownIcon />
          </Button>
        }
      </ButtonGroup>

      {/* Secondary action popup menu */}
      { props.secondaryActions &&
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={ open }
          anchorEl={ anchorRef.current }
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {/* For some reason using just the href component isn't working as it should so component prop is specified! */}
                    { props.secondaryActions.map((option, index) => (
                      <MenuItem
                        key={index}
                        href={ option.url }
                        target="_blank"
                        component={ !!option.url ? "a" : "li" }
                        onClick={ option.onClick }
                      >
                        { option.content }
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      }
    </React.Fragment>
  )
};

ButtonWithActions.propTypes = propTypes;
ButtonWithActions.defaultProps = defaultProps;

export default ButtonWithActions;