import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, ListSubheader, ListItemIcon,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderIcon from '@mui/icons-material/Folder';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

const propTypes = {
  value: PropTypes.any, // string, object, array, etc.
  indentLevel: PropTypes.number, // Number representing the indentation level (default: 0)
}

const defaultProps = {
  indentLevel: 0,
}

const IngramValue = (props) => {
  const { value, indentLevel } = props;

  if(isNil(value)){
    return null;
  }

  const indentStyle = { marginLeft: `${indentLevel * 12}px` }; // Small left padding for each indent level. Only works for object values

  if(Array.isArray(value)){ // array
    return (
      <span className='flex flex-column' aria-describedby='value-array'>
        { value.map((item, index) => {
          const itemIsObject = typeof(item) === 'object';
          const itemNumber = index + 1;
          if(itemIsObject){ // Array of objects
            return(
              <List key={index} aria-describedby='value-array-object'>
                <NestedObjectSubheader title={ itemNumber } />
                <IngramValue value={ item } indentLevel={ indentLevel + 1 } />
              </List>
            )
          }
          else{ // Array of strings
            return(
              <span key={ index }>{ index + 1 } - <IngramValue value={ item } /> </span>
            )
          }
        })}
      </span>
    )
  }
  else if (typeof value === 'object') {
    const nextValue = Object.values(value)[0];
    if(typeof nextValue === 'object'){ // Load items as headers! // i.e. { author1: { id, name }, author2: { id, name }. This level will load author1 and author2 as headers
      return (
        <List aria-describedby='value-object'>
          { Object.keys(value).map((attribute, index) => (
            <React.Fragment key={ attribute }>
              <NestedObjectSubheader title={ attribute } />
              <IngramValue value={value[attribute]} indentLevel={indentLevel + 1} />
            </React.Fragment>
          ))}
        </List>
      );
    }
    else{ // Load items as list items! // i.e. { author1: { id, name }, author2: { id, name }. This level will load id and name as list items
      return Object.keys(value).map((key, index) => {
        const attributeValue = value[key];
        const color = (!!attributeValue && !isEmpty(attributeValue)) ? 'blueviolet' : 'gray';
        return(
          <ListItem key={ key } style={ indentStyle }>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: color }}>
                <DescriptionIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={ key } secondary={ <IngramValue value={value[key]} indentLevel={indentLevel + 1} /> } />
          </ListItem>
        )
      });
    }
  }
  else{ // string
    return value;
  }
};

// Simple Subheader for Nested Object Values
const NestedObjectSubheader = ({ title }) => (
  <ListSubheader component="div" disableGutters>
    <ListItem>
      <ListItemIcon>
        <Avatar sx={{ bgcolor: "gray" }}>
          <FolderIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText primary={ title } />
    </ListItem>
  </ListSubheader>
)

IngramValue.propTypes = propTypes;
IngramValue.defaultProps = defaultProps;

export default IngramValue;