import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  TextField, Stack, InputAdornment, Typography, CardMedia, 
  Checkbox, FormGroup, FormControlLabel
} from '@mui/material';
import { formikProps } from 'utils/shape-props';

import AppAutocomplete from 'components/shared/form/AppAutocompleteApi';
import { SHOPIFY_VARIANTS_SEARCH } from 'api/apollo-client';

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const propTypes = {
  ...formikProps,
}

const defaultProps = {

}

const ProductDealForm = (props) => {
  const { values, errors, handleChange } = props;

  const handleShopifyVariantSelection = (value, option) => {
    console.log("handleShopifyVariantSelection", value, option);
    props.setFieldValue("shopifyVariantId", value);
    props.setFieldValue("sku", option.data.sku);
    props.setFieldValue("shopifyVariantTitle", option.data.displayName);
    props.setFieldValue("shopifyImageUrl", option.data.imageUrl);
    props.setFieldValue("shopifyProductId", option.data.productId);
  }

  const handleDateChange = (fieldName, data) => {
    if(data.$d){ // JS Date Object
      const dateStr = data.$d.toISOString();
      console.log("dateStr", dateStr);
      props.setFieldValue(fieldName, dateStr);
    }
  }

  // console.log("ProductDealForm", props);

  const dateTimePickerFormat = "DD/MM/YYYY hh:mm a";

  return (
    <div>
      <div className="p4">
        <AppAutocomplete
          fullWidth
          label='Producto en Shopify'
          placeholder='Título, SKU, etc...'
          graphqlQuery={ SHOPIFY_VARIANTS_SEARCH }
          onSelection={ handleShopifyVariantSelection }
          itemValueAccessor="id"
          itemLabelAccessor="titleWithSku"
        />
      </div>

      <Stack direction='row' justifyContent='space-between'>
        {/* Readonly - Display from search*/}
        <TextField
          label="SKU"
          disabled
          className='mh4 mv8 flex1'
          variant="outlined"
          
          error={ !!errors.sku }
          value={ values.sku }
        />
        
        <TextField
          label="ID de Variante"
          disabled
          className='mh4 mv8 flex1'
          variant="outlined"
          
          error={ !!errors.shopifyVariantId }
          value={ values.shopifyVariantId }
        />

        {/* Not really required to be here but for visibility */}
        <TextField hidden name="shopifyVariantTitle" value={ values.shopifyVariantTitle } />
        <TextField hidden name="shopifyImageUrl" value={ values.shopifyImageUrl } />
        <TextField hidden name="shopifyProductId" value={ values.shopifyProductId } />
      </Stack>

      <Stack direction='row' justifyContent='space-between'>
        <TextField
          name="dealPrice"
          label="Precio de Oferta"
          type="number"
          className='mh4 mv8 flex1'
          variant="outlined"
          InputProps={{ startAdornment: <InputAdornment position="start">₡</InputAdornment> }}
          
          error={ !!errors.dealPrice }
          value={ values.dealPrice }
          onChange={ handleChange }
        />

        <TextField
          name="dealCompareAtPrice"
          label="Precio de Comparación"
          type="number"
          className='mh4 mv8 flex1'
          variant="outlined"
          InputProps={{ startAdornment: <InputAdornment position="start">₡</InputAdornment> }}

          error={ !!errors.dealCompareAtPrice }
          value={ values.dealCompareAtPrice }
          onChange={ handleChange }
          helperText="Se utilizara el precio original si se deja en blanco"
        />
      </Stack>

      <Stack direction='row' justifyContent='space-between'>
        <TextField
          name="quantity"
          label="Cantidad Disponible"
          type="number"
          className='mh4 mv8 flex1'
          variant="outlined"
          
          error={ !!errors.quantity }
          value={ values.quantity }
          onChange={ handleChange }
        />
        
        <TextField
          name="quantitySold"
          disabled
          type="number"
          label="Cantidad Vendida"
          className='mh4 mv8 flex1'
          variant="outlined"
          
          value={ values.quantitySold }
        />
      </Stack>

      <LocalizationProvider dateAdapter={ AdapterDayjs }>
        <Stack className='mt8' direction='row' justifyContent='space-between'>
          <DateTimePicker
            label="Fecha de Inicio"
            className='flex1 mh4'
            onChange={ (data) => handleDateChange("startsAt", data) }
            format={dateTimePickerFormat}
            defaultValue={values.startsAt}
            slotProps={{
              textField: {
                variant: 'outlined',
                error: !!errors.startsAt,
                helperText: errors.startsAt,
              },
            }}
          />

          <DateTimePicker 
            label="Fecha de Finalización" 
            className='flex1 mh4'
            onChange={ (data) => handleDateChange("endsAt", data) }
            format={dateTimePickerFormat}
            defaultValue={values.endsAt}
            slotProps={{
              textField: {
                variant: 'outlined',
                error: !!errors.endsAt,
                helperText: errors.endsAt,
              },
            }}
          />
        </Stack>

        <FormGroup>
          <FormControlLabel
            label="No mostrar en home page" 
            control={
              <Checkbox 
                name="skipHomePage"
                checked={values.skipHomePage} 
                onChange={handleChange}  
              />
            }
          />
        </FormGroup>
      </LocalizationProvider>
    </div> 
  )
};

ProductDealForm.propTypes = propTypes;
ProductDealForm.defaultProps = defaultProps;

export default ProductDealForm;