import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as shapeProps from 'utils/shape-props';

import { 
  Box, Grid, Typography,
  Card, CardContent,
} from "@mui/material";
import { useFormikContext } from 'formik';

import AppTextField from 'components/shared/form/AppTextField';
import DropdownSelect from 'components/shared/form/DropdownSelect';
import AppAutocomplete from 'components/shared/form/AppAutocompleteApi';

import apolloClient, { APP_PAGE_SHOPIFY_RESOURCE_SEARCH } from 'api/apollo-client';

const propTypes = {
  kinds: shapeProps.selectOptionsProp.isRequired,
}

const defaultProps = {

}

const AppPageBasicForm = (props) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleShopifyVariantSelection = (value, option) => {
    setFieldValue("shopifyResourceGid", value);
    setFieldValue("shopifyTitle", option.data.title);
    setFieldValue("shopifyHandle", option.data.handle);
  }

  // Customize autocomplete query to include kind along with the search value
  const autocompleteVariablesConstructor = useCallback((searchValue) => {
    return { kind: values.kind, query: searchValue };
  }, [values.kind]);

  const handleKindChange = (name, value) => {
    setFieldValue(name, value);
    // Clear shopify fields
    setFieldValue("shopifyResourceGid", "");
    setFieldValue("shopifyTitle", "");
    setFieldValue("shopifyHandle", "");
  };

  const autocompleteLabel = values.kind == "PAGE" ? "Page" : "Collection"; // Also reset options on AppAutocomplete due to prop change.

  return (
    <Card>
      <CardContent>
        <Grid 
          container 
          alignItems="center" justifyContent="space-between"
          rowSpacing={2}
          columnSpacing={2}
        >
          <Grid item xs={12}>
            <Typography color="black" fontWeight="500">
              Configuración básica
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {/* TODO Formikilization */}
            <DropdownSelect
              fullWidth
              autoWidth={false}
              name="kind"
              value={ values.kind }
              errorText={ errors.kind }
              errorTextProps={{ sx: { position: 'absolute', top: '40px', top: "44px" } }}
              
              containerClass='no-borders'
              options={ props.kinds } 
              onSelect={ handleKindChange }
            />
          </Grid>

          <Grid item xs={12}>
            <AppAutocomplete
              fullWidth
              label={ autocompleteLabel }
              placeholder='Título'
              graphqlQuery={ APP_PAGE_SHOPIFY_RESOURCE_SEARCH }
              onSelection={ handleShopifyVariantSelection }
              itemValueAccessor="id"
              itemLabelAccessor="title"
              variablesConstructor={ autocompleteVariablesConstructor}
            />
          </Grid>

          <Grid item xs={6}>
            <AppTextField
              label="Title"
              name="shopifyTitle"
              disabled
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <AppTextField
              label="Handle"
              name="shopifyHandle"
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};

AppPageBasicForm.propTypes = propTypes;
AppPageBasicForm.defaultProps = defaultProps;

export default AppPageBasicForm;