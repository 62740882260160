import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Card, CardContent, CardHeader,
  List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, ListSubheader,
  Divider,
} from '@mui/material';

import IngramValue from './IngramValue';

const propTypes = {
  title: PropTypes.object.isRequired,
}

const defaultProps = {

}

// Replica of information shown on Ingram's website for each title
const IngramItemDemo = (props) => {
  const title = props.title;

  // const bisaac_categories = [
    // `${title.metadata.bisac_subject_code_1_major} ${title.metadata.bisac_subject_code_1_minor}`
  // ]

  const featureDetails = title.feature_details && Object.values(title.feature_details);
  const physicalInfoFromTitles = [
    `${title.height} cms Height`,
    `${title.length} cms Length`,
    `${title.width} cms Width`,
    `(${title.weight} kgs) Weight`,
    `${title.pages} pages`
  ];

  const physicalInfoFromLogistics = [
    `${title?.logistics?.height} cms Height`,
    `${title?.logistics?.length} cms Length`,
    `${title?.logistics?.width} cms Width`,
    `(${title?.logistics?.weight} kgs) Weight`,
  ];

  const reviewCitations = title.citations?.map(citation => `${citation.review_source} ${citation.review_issue_date} pg. ${Number(citation.page_number)}`);

  const contributorNotesHTML = title.contributor_notes?.reduce((acc, cnote) => {
    return `${acc}${cnote.name}${cnote.narrative}`
  }, '');

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h5">
            { title.title }
          </Typography>
          <Typography variant='h6'>
            Contributor(s): { "WIP  " }
          </Typography>
          
          <div className="flex">
            <DemoItem title='ISBN' value={ title.isbnId } />
            <DemoItem title='EAN' value={ title.eanId } containerClass='ml12' />
          </div>
          <DemoItem title='Publisher' value={ title.publisherImprint } />
          <DemoItem title='US SRP' value={ "WIP" } />
          <DemoItem title='Binding' value={ title.metadata.bisacBinding_type } />
          <DemoItem title='Copyright Date' value={ title.citations?.reviewIssueDate } />
          <DemoItem title='Pub Date' value={ "INVESTIGATION" } />
          <DemoListItem title="Annotation" value={ title.annotations } />

          <DemoListItem title="Formats" value="Model Implementation" />
        </CardContent>

        <CardContent>
          <Typography variant='subtitle1' fontWeight='bold' color="#fff" bgcolor="#003366" className='ph8 pv4'>
            Additional Information
          </Typography>

          <Typography fontWeight="bold">BISAC Categories</Typography>
          <BisacItem bisac={ title.metadata.bisacSubjectCode_1 } />
          <BisacItem bisac={ title.metadata.bisacSubjectCode_2 } />
          <BisacItem bisac={ title.metadata.bisacSubjectCode_3 } />
          <Divider />

          {/* Themes */}
          <Typography fontWeight="bold">INGRAM Categories (themes):</Typography>
          { title.themes?.map((theme, index) => (
            <BisacItem key={ index } bisac={{ major: theme.groupValue, minors: [theme.descriptionValue] }} />
          )) }

          <DemoItem title="OCLC Number" value={ "X" } />  
          <DemoItem title="Dewey" value={ "X" } />
          <DemoItem title="Features" value={ <ValuesList values={featureDetails} /> } />
          <DemoItem title="Physical Info (titles)" value={ <ValuesList values={physicalInfoFromTitles} /> } />
          <DemoItem title="Physical Info (logistics)" value={ <ValuesList values={physicalInfoFromLogistics} /> } />
          <DemoItem title="Carton Quantity" value={ title.logistics?.carton_quantity } />
          <DemoItem title="Number of Units in Package" value={ title.logistics?.number_of_units } />
        </CardContent>

        <CardContent>
          <Typography variant='subtitle1' fontWeight='bold' color="#fff" bgcolor="#003366" className='ph8 pv4'>
            Descriptions, Reviews, Etc.	
          </Typography>

          <DemoListItem title="Brief Description:" value={ "WIP" } />
          <DemoListItem title={`Jacket Description/${title.jacket_description?.code_value || 'NA'}:`} value={ title.jacket_description?.content } />
          <DemoListItem title="Review Citations:" value={ <IngramValue value={reviewCitations} /> } />
          <DemoListHmlItem title="Biographical Note:" value={ title.contributor_bio } />

          {/* Called Contributor Bio on Ingram for some reason */}
          <DemoListHmlItem title="Contributor Notes:" value={ contributorNotesHTML } />
        </CardContent>
      </Card>
    </div>
  )
};

const DemoItem = ({ title, value, containerClass, ...props }) => (
  <div>
    <Typography className={containerClass}>
      <strong className='mr8'>{ title }:</strong>
      <span>{ value }</span>
    </Typography>
    <Divider />
  </div>
)

const DemoListItem = ({ title, value, ...props }) => (
  <ListItem disableGutters divider>
    <ListItemText 
      primary={ title } secondary={ value } 
      primaryTypographyProps={{ fontWeight: 'bold' }} 
      secondaryTypographyProps={{ color: 'black' }} 
      {...props} 
    />
  </ListItem>
);

const DemoListHmlItem = ({ title, value, ...props }) => (
  <ListItem disableGutters divider className='flex flex-column align-items-start'>
    <Typography fontWeight='bold'>
      [HTML] { title }
    </Typography>
    <div dangerouslySetInnerHTML={{ __html: value }} />
  </ListItem>
);

const BisacItem = ({ bisac }) => {
  if(!bisac || !bisac.major){
    return  null
  }

  const { major, minors } = bisac;

  return (
  <Typography>
    - <span>{ major }</span>
    { minors.map((minor, index) => (
      <span key={ index }>{ ` | ${minor}` }</span>
    ))}
  </Typography>
  )
};

const ValuesList = ({ values, delimiter = ", " }) => {
  if(!values){
    return null;
  }
  return(
    <span>{ values.join(delimiter) }</span>
  )
}

IngramItemDemo.propTypes = propTypes;
IngramItemDemo.defaultProps = defaultProps;

export default IngramItemDemo;