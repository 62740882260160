import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import AppIndex from 'shared/AppIndex';
import Page from 'shared/AppPage';

import { columns } from 'utils';

const propTypes = {
  newURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const PricingRuleIndex = (props) => {
  return (
    <Page 
      title="Consola de Precios"
      primaryAction={{ content: "Crear", url: props.newURL }}
    >
      <AppIndex
        items={[]}
        columnsData={ columns.pricingRuleColumns }
      />
    </Page>
  )
};

PricingRuleIndex.propTypes = propTypes;
PricingRuleIndex.defaultProps = defaultProps;

export default PricingRuleIndex;