import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification } from 'utils';
import AppNotification from 'components/shared/AppNotification';

import Page from 'shared/AppPage';
import AppModal from 'components/shared/AppModal';
import ProductDealItem from './components/ProductDealItem';
import ProductDealForm from './components/ProductDealForm';

import { 
  Card, CardContent, CircularProgress, Typography, 
  Button, Stack
} from '@mui/material';
import { useQuery, useMutation, ApolloProvider } from '@apollo/client';
import apolloClient, { userErrorsToFormik, PRODUCT_DEALS, PRODUCT_DEAL_CREATE, PRODUCT_DEAL_UPDATE, PRODUCT_DEAL_DELETE } from 'api/apollo-client';

import { useFormik } from 'formik';
import dayjs from 'dayjs';
import lodashPick from 'lodash/pick';

const propTypes = {
  createURL: PropTypes.string.isRequired,
}

const defaultProps = {

}


// Depending if new or edit
const modalTextOptions = {
  new: { title: "Agregar Oferta del día", submitButton: "Crear" },
  edit: { title: "Editar Oferta del día", submitButton: "Actualizar" }
};

const whitelistFields = ["shopifyVariantId", "shopifyVariantTitle", "shopifyProductId", "shopifyImageUrl", "sku", "startsAt", "endsAt", "quantity", "dealPrice", "dealCompareAtPrice", "skipHomePage"];

const ProductDealsIndex = (props) => {
  const formModalRef = useRef(null);
  const [sendNotification, notificationProps] = useMuiNotification();

  const { loading, error, refetch, data } = useQuery(PRODUCT_DEALS, { 
    variables: { filter: null },
    client: apolloClient,
  });

  const [createProductDeal] = useMutation(PRODUCT_DEAL_CREATE, { client: apolloClient });
  const [updateProductDeal] = useMutation(PRODUCT_DEAL_UPDATE, { client: apolloClient });
  const [deleteProductDeal] = useMutation(PRODUCT_DEAL_DELETE, { client: apolloClient });

  // Currently refetch to update UI but in the future to optimize the UI can update itself without refetch
  const handleFormSubmit = async (values, formikBag) => {
    
    try {
      let productDealResult; // Common result for create and update (productDeal and errors)
      const formValues = lodashPick(values, whitelistFields);
      
      if(!!values.id){ // Update
        const updateResult = await updateProductDeal({
          variables: { id: values.id, input: formValues }
        });
        productDealResult = updateResult?.data?.productDealUpdate;
      }
      else{ // Create
        const createResult = await createProductDeal({
          variables: { input: formValues }
        });
        productDealResult = createResult?.data?.productDealCreate;
      }
      
      if(productDealResult.productDeal?.id){ // Success
        sendNotification({ message: "Operación exitosa" });
        formModalRef.current.close();
        refetch();
      }
      else{
        sendNotification({ message: "Error en los datos del formulario", severity: "error" });
        formikBag.setErrors(userErrorsToFormik(productDealResult.errors));
      }
    } catch (error) {
      console.log("error", error);
      sendNotification({ message: "Ocurrio un error, revise los datos o notifique al administrador", severity: "error" });
    }
  };

  const modalFormik = useFormik({
    initialValues: {
      sku: "",
      shopifyProductId: "",
      shopifyVariantId: "",
      shopifyVariantTitle: "",
      shopifyImageUrl: "",
      dealPrice: "",
      dealCompareAtPrice: "",
      quantity: 0,
      skipHomePage: false,
    },
    enableReinitialize: true,
    onSubmit: handleFormSubmit
  });

  // Open modal with new form
  const handleNewItem = () => {
    modalFormik.resetForm();
    formModalRef.current.open();
  }

  // Setup form with item data and open it
  const handleEditItem = (item) => {
    modalFormik.setValues({
      ...item,
      startsAt: dayjs(item.startsAt),
      endsAt: dayjs(item.endsAt),
    });
    formModalRef.current.open();
  }

  const handleDelete = async (itemId) => {
    if(confirm("¿Estás seguro de eliminar este registro?")){
      const result = await deleteProductDeal({ variables: { id: itemId } });
      const deletedResourceId = result?.data?.productDealDelete?.deletedResourceId;
      if(!!deletedResourceId){
        sendNotification({ message: "Operación exitosa" });
        refetch();
      }
      else{
        console.log("result", result);
        const errors = result?.data?.productDealDelete?.errors.map(error => error.message).join(", ");
        sendNotification({ message: `Error al eliminar: ${errors}`, severity: "error" });
      }
    }
  };

  const items = data?.productDeals?.items || [];

  if(!!error){
    console.log("useQuery error", error);
  }

  const modalTexts = modalFormik.values.id ? modalTextOptions.edit : modalTextOptions.new;

  return(
    <Page 
      title="Oferta del día"
      primaryAction={{ content: "Crear", onClick: handleNewItem }}
    >
      <AppNotification {...notificationProps} />

      { loading && <div className="flex justify-center"><CircularProgress color="primary" /></div> }

      { !loading && (
        <div>
          { items.map((product, index) => (
            <ProductDealItem
              key={ product.id }
              item={ product }
              onEdit={ handleEditItem }
              onDelete={ handleDelete }
            />
          ))}

          { items.length === 0 && (
            <Typography color="GrayText" variant="h5" align="center">
              No hay productos
            </Typography>
          )}

          <AppModal
            ref={ formModalRef }
            title={ modalTexts.title }
            modalStyle={{ width: '50vw' }}
            footer={
              <Button variant='contained' onClick={ modalFormik.handleSubmit }>{ modalTexts.submitButton }</Button>
            }
          >
            <form onSubmit={ modalFormik.handleSubmit }>
              <ProductDealForm {...modalFormik} />
            </form>
          </AppModal>
        </div>
      )}
    </Page>
  )
};

ProductDealsIndex.propTypes = propTypes;
ProductDealsIndex.defaultProps = defaultProps;

export default ProductDealsIndex;