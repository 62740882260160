import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Tab, Tabs,
  List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, ListSubheader,
  Accordion, AccordionSummary, AccordionDetails, Chip
} from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import lodashGet from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

import TabPanel from 'shared/TabPanel';

import IngramValue from './IngramValue';
import IngramItemDemo from './IngramItemDemo';

import axios from 'utils/axios-rails';

const propTypes = {
  selectedTitleId: PropTypes.string,
  ingramLinkedDataFileAttributes: PropTypes.array.isRequired, // Attributes stored directly on IngramTitle
  filterUrl: PropTypes.string.isRequired,
}

const defaultProps = {

}

const IMAGE_PLACEHOLDER_URL = 'https://i0.wp.com/theperfectroundgolf.com/wp-content/uploads/2022/04/placeholder.png?fit=1200%2C800&ssl=1';

const IngramDetailModal = (props) => {
  const [selectedTitle, setSelectedTitle] = useState(null);

  console.log("SelectedTitle", selectedTitle);
  const colorInactive = 'gray';
  const colorUnimart = '#00b6e0';

  const [tabActive, setTabActive] = useState(0);
  const handleTabChange = useCallback ((event, value) => { setTabActive(value) }, []);

  const getItemData = async (ingramTitleId) => {
    const { data } = await axios.post(props.filterUrl, { 
      get: true, // Special param to change query
      filter: {
        id: ingramTitleId,
      },
      page: 1,
    });
    if(data.records){
      setSelectedTitle(data.records[0]);
    }
  }

  useEffect(() => {
    setSelectedTitle(null);
    if(!!props.selectedTitleId){
      getItemData(props.selectedTitleId);   
    }
  }, [props.selectedTitleId]);

  if(!selectedTitle){
    return null;
  }

  // Get value and color for field on selectedTitle
  const getValueForSimpleField = (key) => {
    let displayValue = lodashGet(selectedTitle, key);
    let color = !!displayValue ? 'green' : colorInactive;

    // If there is a metadata reference, change the color and display the metadata value
    const metadataReference = lodashGet(selectedTitle, `metadata.${key}`);
    if(!!metadataReference){
      color = colorUnimart;
      displayValue = `${displayValue} - ${metadataReference}`
    }
    if(isBoolean(displayValue)){
      displayValue = displayValue.toString();
    };

    return {
      color,
      displayValue,
    }
  }

  // Add attributes from linked records
  const dataFileAttributes = [...props.ingramLinkedDataFileAttributes]

  const basicFields = Object.keys(omit(selectedTitle, ['metadata', 'titleInventory', ...props.ingramLinkedDataFileAttributes])); // On Titles Tab do not display metadata or linked data file attributes

  return (
    <div style={{ backgroundColor: "#f3f6f9" }}>
      <Tabs value={ tabActive } onChange={ handleTabChange }>
        <Tab label="Titles" />
        <Tab label="Shopify"/>
        <Tab label="Inventory" />
        <Tab label="Demo" />
      </Tabs>
      
      <div className='flex align-items-start mt20'>
        <img src={ selectedTitle.imageUrl || IMAGE_PLACEHOLDER_URL } width='300' />

        <div className='ml12 pb20' style={{ minWidth: "700px" }}>
          {/* Titles Content */}
          <TabPanel value={tabActive} index={0}>
            <div className='flex align-items-start'>
              <List>
                <ListSubheader sx={{ backgroundColor: '#f3f6f9' }}>
                  Data
                </ListSubheader>
                { basicFields.map((key) => {
                  const { color, displayValue } = getValueForSimpleField(key);  
                  return(
                    <ListItem key={ key }>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: color }}>
                          <DescriptionIcon />
                        </Avatar>
                    </ListItemAvatar>
                      {/* Added "" to avoid warning when displayValue is null */}
                      <ListItemText primary={ key } secondary={ displayValue } />
                    </ListItem>
                  )
                })}
              </List>
          
              <div className='mh12'>
                <List>
                  <ListSubheader sx={{ backgroundColor: '#f3f6f9' }}>
                    Metadata
                  </ListSubheader>
                  { Object.keys(selectedTitle.metadata).map((key) => {
                    const value = selectedTitle.metadata[key];

                    // Too many levels (subject_code => minors => []) (Would have to change the structure outside of ListItem)
                    if(key.startsWith('bisac_subject_code_')){
                      return null;
                    }

                    return(
                      <ListItem key={ key }>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: !isEmpty(value) ? colorUnimart : colorInactive }}>
                            <DescriptionIcon />
                          </Avatar>
                      </ListItemAvatar>
                        <ListItemText primary={ key } secondary={ <IngramValue value={value} /> } />
                      </ListItem>
                    )
                  })}
                </List>
              </div>
            </div>
          </TabPanel>

          {/* Shopify */}
          <TabPanel value={tabActive} index={1}>
            <List>
              <ListSubheader sx={{ backgroundColor: '#f3f6f9' }}>
                Tags
              </ListSubheader>
              <ListItem>
                <div>
                  { selectedTitle.shopifyTags.map((tag, index) => (
                    <Chip key={ index } label={ tag } color='success' className='mr4 mb4' />
                  ))}
                </div>
              </ListItem>

              <ListSubheader sx={{ backgroundColor: '#f3f6f9' }}>
                Description
              </ListSubheader>
              <ListItem>
                <div dangerouslySetInnerHTML={{ __html: selectedTitle.shopifyDescription }} />
              </ListItem>
            </List>
          </TabPanel>
          
          {/* Inventory Content */}
          <TabPanel value={tabActive} index={2}>
            <List>
              <ListSubheader sx={{ backgroundColor: '#f3f6f9' }}>
                Data
              </ListSubheader>
              { Object.keys(selectedTitle.titleInventory).map((key) => {
                const { color, displayValue } = getValueForSimpleField(`titleInventory.${key}`);
                return(
                  <ListItem key={ key }>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: color }}>
                        <DescriptionIcon />
                      </Avatar>
                  </ListItemAvatar>
                    <ListItemText primary={ key } secondary={ displayValue } />
                  </ListItem>
                )
              })}
            </List>
          </TabPanel>

          {/* Demo */}
          <TabPanel value={tabActive} index={2}>
            <IngramItemDemo  title={selectedTitle} />
          </TabPanel>
        </div>
      </div>
    </div>
  )
};

IngramDetailModal.propTypes = propTypes;
IngramDetailModal.defaultProps = defaultProps;

export default IngramDetailModal;