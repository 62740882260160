import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup } from "@mui/material";

import { useEditor } from "@craftjs/core";
import { useFormikContext } from 'formik';
import pako from "pako"; // Compreser to avoid sending big JSON

const propTypes = {

}

const defaultProps = {

}

// Actions specific to AppPageBuilder.
const BuilderActions = (props) => {
  const { setFieldValue, handleSubmit, isSubmitting, values } = useFormikContext();
  const { actions, query } = useEditor();

  // Compress with pako to save space, then encode to base64 for easy transport.
  const compressContent = () => {
    const json = query.serialize();
    const compressedData = pako.deflate(json, { to: 'string' });
    const compressedBase64 = Buffer.from(compressedData).toString('base64');

    return compressedBase64;
  }


  // Default action, save the content draft.
  const handleSave = () => {
    setFieldValue('contentDraft', compressContent());
    handleSubmit();
  }

  // Alternative action, save draft content and publish. (Backend will handle the publish process)
  const handleSaveAndPublish = () => {
    setFieldValue('contentDraft', compressContent());
    setFieldValue('publishNow', true); // Input flag
    handleSubmit();
  }

  return (
    <ButtonGroup>
      <Button
        disabled={ isSubmitting || !values.publishable }
        onClick={ handleSaveAndPublish }
        variant="contained" 
        color="primary"
      >
        Publicar
      </Button>
      <Button
        disabled={ isSubmitting }
        onClick={ handleSave }
        variant="outlined" 
        color="primary"
      >
        Guardar
      </Button>
    </ButtonGroup>
  )
};

BuilderActions.propTypes = propTypes;
BuilderActions.defaultProps = defaultProps;

export default BuilderActions;