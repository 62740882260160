import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { shapeForBanner } from '../types';
import { useFormikContext } from 'formik';

const propTypes = {
  banner: PropTypes.shape(shapeForBanner).isRequired,
}

const defaultProps = {

}

// Used to display banner preview (image only with its resource details)
const PreviewBannerImage = (props) => {
  const { status } = useFormikContext();
  const banner = props.banner;

  // Link is only for preview purposes
  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  const viewportClassName = `viewport-${status.viewport.toLowerCase()}`; // i.e. viewport-desktop, viewport-mobile

  return (
    <div className={`asset-block-bg-placeholder ${viewportClassName}`}>
      <a
        href={banner.resourceUrl}
        onClick={handleLinkClick}
        data-resource-id={banner.resourceId}
      >
        <img
          key={banner.imageUrl}
          src={banner.imageUrl}
        />
      </a>
    </div>
  )
};

PreviewBannerImage.propTypes = propTypes;
PreviewBannerImage.defaultProps = defaultProps;

export default PreviewBannerImage;