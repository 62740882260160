import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';

const propTypes = {
  number: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired
}

const defaultProps = {

}

const InstructionLine = (props) => {
  const { number, content } = props;

  return (
    <div className="flex align-items-center mb20">
      <div className="mr12">
        <Badge style={{ height: 30, width: 30, }} variant="primary" className="rounded-circle flex justify-center align-items-center">
          { number }
        </Badge>
      </div>
      <div style={{ fontSize: 14 }}>
        { content }
      </div>
    </div>
  )
};

InstructionLine.propTypes = propTypes;
InstructionLine.defaultProps = defaultProps;

export default InstructionLine;