// TODO When TS gets added, replace this with interfaces types
import PropTypes from 'prop-types';

// For all basic banners (no title/description)
// imageUrl - Image URL for the Banner, from S3 uploaded resource
// resourceId - Resource ID for the Banner, used mostly on mobile clients. i.e. "gid://shopify/Product/1234567890"
// resourceUrl - Resource URL for the Banner, used mostly for web. i.e. "https://www.unimart.com/products/1234567890"
export const shapeForBanner = {
  imageUrl: PropTypes.string,
  resourceId: PropTypes.string,
  resourceUrl: PropTypes.string,
};

// For banners with title and description
export const shapeForBannerWithDescription = {
  ...shapeForBanner,
  title: PropTypes.string,
  description: PropTypes.string,
};

export const defaultPropsForBanner = {
  imageUrl: "",
  resourceId: "",
  resourceUrl: "",
};

export const defaultPropsForBannerWithDescription = {
  ...defaultPropsForBanner,
  title: "",
  description: "",
};