import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useFormik } from 'formik';
import {
  Tooltip, IconButton, Button, Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

const propTypes = {
  sellerSpecialCosts: PropTypes.shape({
    costs_file: PropTypes.Object,
    start_date: PropTypes.Object,
    end_date: PropTypes.Object,
    updated_at: PropTypes.Object,
  }),
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
}

const defaultProps = {
  disabledSave: true,
  disabled: false,
}

const SellerSpecialCostsItem = (props) => {
  const formik = useFormik({
    initialValues: {
      ...props.sellerSpecialCosts,
      disabledSave: props.disabledSave,
    },
    onSubmit: props.onSave,
    enableReinitialize: true,
  });

  const handleDelete = () => {
    if (confirm("¿Esta acción es irreversible, continuar?")) {
      props.onDelete(formik.values.id);
    }
  }

  const changeValue = (key, value) => {
    formik.setFieldValue(key, value)
    formik.setFieldValue('disabledSave', false)
  }

  const handleUpload = (event) => {
    const file = event.target.files[0]
    props.validateFile(file,
      (success) => {
        if (success) {
          changeValue('costs_file', file)
        }
      })
  }

  const itemBody = () => {
    return (
      <div className="container-fluid">
        <Grid container spacing={2}>
          <Grid xs={4} display="flex" className="flex-wrap">
            <Button color='inherit'
              variant="text"
              size="medium"
              disabled={props.disabled || formik.isSubmitting}
              component='label' sx={{ textTransform: 'none', justifyContent: 'left' }}
              className="flex-full justify-start"
            >
              {formik.values.costs_file.name}<UploadIcon className="ml8" />
              <input hidden accept={'.xlsx,.xls'} onChange={handleUpload} type="file" />
            </Button>
            <Typography variant="subtitle1" className="flex flex-full pl8"> <small>{props.sellerSpecialCosts.updated_at} </small></Typography>
          </Grid>
          <Grid xs={3} display="flex" align-items="center">
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker className="mr4"
                label="Fecha de Inicio"
                value={dayjs(props.sellerSpecialCosts.start_date)}
                onChange={(newValue) => changeValue('start_date', newValue)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={3} display="flex" align-items="center">
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker className="mr4"
                label="Fecha de Finalizacion"
                value={dayjs(props.sellerSpecialCosts.end_date)}
                onChange={(newValue) => changeValue('end_date', newValue)}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <ListItem className='mb12'
      secondaryAction={
        <div className="flex">
          <Tooltip title="Guardar">
            <span>
              <IconButton color="primary" disabled={formik.values.disabledSave || formik.isSubmitting} onClick={formik.handleSubmit}>
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton color="error" disabled={props.disabled || formik.isSubmitting} onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Descargar">
            <IconButton color="primary" disabled={props.disabled || formik.isSubmitting} href={formik.values.costs_file.url} target="_blank">
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
    >
      <ListItemText primary={itemBody()} />
    </ListItem>
  );
}

SellerSpecialCostsItem.propTypes = propTypes;
SellerSpecialCostsItem.defaultProps = defaultProps;
export default SellerSpecialCostsItem;