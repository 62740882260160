import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const propTypes = {
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,

  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, ]),
  onChange: PropTypes.func.isRequired,
  // any other input prop
}

const defaultProps = {
  labelClassName: null
}

// Temporal while we use bootstrap, the idea is to change to MUI
const MuiInput = (props) => {
  const { label, labelClassName, ...inputProps } = props;

  return (
    <div className={ `mui-input ${!!inputProps.value ? " filled" : ""}` }>
      <Form.Label className={ labelClassName }>
        { label }
      </Form.Label>
      <Form.Control placeholder={ label } { ...inputProps } />
    </div>
  )
};

MuiInput.propTypes = propTypes;
MuiInput.defaultProps = defaultProps;

export default MuiInput;