import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import ZunifyLogo from 'images/gateway/zunify-logo.png';
import axios from 'axios'; 

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  shop: PropTypes.string.isRequired,
}

const defaultProps = {

}

const ShopifyAppInstallSuccess = (props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    console.log("Submitting");
    setLoading(true);
    try {
      const response = await axios.post(props.submitURL, {
        state: props.state,
        shop: props.shop,
      });
      const data = response.data;
      console.log("D", data)
      if(data.success){
        window.location.href = data.redirect_url;
      }
    } catch (error) {
      console.error("Error!?", error);
    }
    setLoading(false);
  }

  return (
    <div style={{ minHeight: "400px" }} className='flex flex-column align-items-center justify-center'>
      <h3>Installation successful!</h3>
      <h5>You can go back to your store now</h5>
      <Button onClick={ handleSubmit } disabled={ loading } variant="link">
        Finish
      </Button>
    </div>
  );
};

ShopifyAppInstallSuccess.propTypes = propTypes;
ShopifyAppInstallSuccess.defaultProps = defaultProps;

export default ShopifyAppInstallSuccess;