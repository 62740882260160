import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ContentEditable from 'react-contenteditable'

import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';

import { useNode } from '@craftjs/core';

const propTypes = {
  values: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]),
  editable: PropTypes.bool,

  titleKey: PropTypes.string.isRequired,
  titleTag: PropTypes.string, // optional
  descriptionKey: PropTypes.string, // optional
}

const defaultProps = {
  titleTag: 'h3', // Most sections/banners with title use h3
  editable: false,
}

const PreviewEditableTitleAndDescription = (props) => {
  const { values, editable } = props;
  
  const { actions: { setProp } } = useNode();

  const titleValue = lodashGet(values, props.titleKey);
  const descriptionValue = lodashGet(values, props.descriptionKey);

  // Only display content if any of the values is present. If not there is no text
  return(
    <div className='editable-title-and-description'>
      {/* ContentEditable look pretty bad if starting with empty text. And for this case where text is optional... It is likely to happen */}
      { (!!titleValue || !!descriptionValue) &&
        <>
          <ContentEditable
            html={titleValue}
            disabled={!editable && !titleValue}
            onChange={e =>
              setProp(componentProps =>
                lodashSet(componentProps, props.titleKey, e.target.value.replace(/<\/?[^>]+(>|$)/g, ""))
              )
            }
            className='editable-title'
            tagName={props.titleTag}
          />

          {/* Description is optional. Some items only have one text value (like categories) */}
          { !!props.descriptionKey &&
            <ContentEditable
              html={descriptionValue}
              disabled={!editable && !descriptionValue}
              onChange={e =>
                setProp(componentProps =>
                  lodashSet(componentProps, props.descriptionKey, e.target.value.replace(/<\/?[^>]+(>|$)/g, ""))
                )
              }
              tagName="p"
            />
          }
        </>
      }
    </div>
  )
};

PreviewEditableTitleAndDescription.propTypes = propTypes;
PreviewEditableTitleAndDescription.defaultProps = defaultProps;

export default PreviewEditableTitleAndDescription;