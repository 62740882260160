import React, { useEffect, useMemo } from 'react'
import { createConsumer } from '@rails/actioncable'

// https://www.aersoftware.nl/blogs/using-action-cable-in-react

export default function useActionCable(url) {
  const actionCable = useMemo(() => createConsumer(url), [])

  useEffect(() => {
    return () => {
      // console.log('Disconnect Action Cable')
      actionCable.disconnect()
    }
  }, [])

  return { actionCable }
}