import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, FormHelperText } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const propTypes = {
  onUpload: PropTypes.func.isRequired,
  accept: PropTypes.string,

  helpText: PropTypes.string,
}

const defaultProps = {
  accept: '.xlsx,.xls',
}

const UploadButton = (props) => {
  const [file, setFile] = useState(null);

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    props.onUpload(file);
  }


  return (
    <div className='upload-button'>
      <Button fullWidth variant="text" component="label" endIcon={ <UploadIcon/> }>
        { file?.name || 'Seleccionar archivo' }
        <input hidden accept={ props.accept } onChange={ handleUpload } type="file" />
      </Button>
      { !!props.helpText && <FormHelperText className="text-center">{ props.helpText }</FormHelperText> }
    </div>
  )
};

UploadButton.propTypes = propTypes;
UploadButton.defaultProps = defaultProps;

export default UploadButton;