import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ToggleButton } from '@mui/material';

const propTypes = {
  value: PropTypes.string.isRequired, // "format"
  icon: PropTypes.element.isRequired, // Icon component
  onMouseDown: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}

const defaultProps = {
}

// UI component for a toggle button in the editor toolbar, used for both block and mark buttons
const EditorToggleButton = (props) => {
  return (
    <ToggleButton
      size='small'
      value={props.value}
      component="span"
      className='MuiToggleButtonGroup-grouped'
      selected={props.selected}
      onMouseDown={props.onMouseDown}
    >
      { props.icon }
    </ToggleButton>
  )
};

EditorToggleButton.propTypes = propTypes;
EditorToggleButton.defaultProps = defaultProps;

export default EditorToggleButton;