import React from 'react'
import PropTypes from 'prop-types'

import $ from 'jquery';
import "bootstrap";

class NotificationMessage extends React.Component {

	// in this style of function declaration, "this" is bind into the function automatically
	setAlertClass = () => {
		return `alert fixed-top alert-${this.props.style}`;
	}

	render() {
		return (
			<div className={this.setAlertClass()} role="alert">
				{this.props.message}
			</div>
		);
	}
}

NotificationMessage.propTypes = {
	style: PropTypes.string,
	message: PropTypes.string,
}

export default NotificationMessage;
