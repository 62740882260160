import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  Typography, Stack,
  Tooltip, IconButton,
  TextField, FormGroup, FormControlLabel,
  ListItem, ListItemAvatar, ListItemText, Avatar,
  Card, CardContent, Chip
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DealIcon from '@mui/icons-material/LocalOffer';

const propTypes = {
  item: PropTypes.object.isRequired,

  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const defaultProps = {

}

const ProductDealItem = (props) => {
  const { item } = props;

  const handleDelete = () => {
    props.onDelete(item.id);
  }

  const handleEdit = () => {
    props.onEdit(item)
  }

  const statusToColor = { active: 'success', inactive: 'default', finished: 'primary' }

  return (
    <Card className='mb8'>
      <CardContent>
        <ListItem
          id={ item.id}
          secondaryAction={
            <div className='flex align-items-center'>
              <Tooltip title="Editar">
                <IconButton color="primary" disabled={ props.disabled } onClick={ handleEdit }>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton  color="error" disabled={ props.disabled } onClick={ handleDelete }>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          }
        >
          <ListItemAvatar className="mr12">
            <Avatar src={ item.shopifyImageUrl } sx={{ width: 128, height: 128 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <span className='display-block'>{ item.sku }</span>
                <ItemValue label='Precio' value={ item.dealPriceMoney } />
                { item.dealCompareAtPriceMoney && <ItemValue label='Precio de Comparación' value={ item.dealCompareAtPriceMoney } /> }
                <ItemValue label='Cantidad' value={ item.quantity } />
                <ItemValue label='Vendidos' value={ item.quantitySold } />
                <ItemValue label='Fecha' value={ item.humanDateRange } />
                <Chip label={ item.humanStatus } color={ statusToColor[item.status] } size='small' />
                { item.skipHomePage && <Chip className="ml4" label='No mostrar en home page' color='warning' size='small' /> }
              </>
            }
          />
        </ListItem>
      </CardContent>
    </Card>
  )
};

const ItemValue = ({ label, value }) => (
  <>
    <Stack 
      direction={{ sm: 'column', md: 'row' }}
      spacing={ 1 }
    >
      <Typography fontWeight='bold'>{ label }: </Typography>
      <Typography>{ value }</Typography>
    </Stack>
  </>
)

ProductDealItem.propTypes = propTypes;
ProductDealItem.defaultProps = defaultProps;

export default ProductDealItem;