import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import AppInput from 'shared/AppInput';

import axios from 'utils/axios-rails';

// import filter from 'lodash/filter';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

// For now just receives different qty/amount pairs to generate a sole file with them
// Virtual union - Not saved to DB
const GiftCardOrderUnionNew = (props) => {
  const [message, setMessage] = useState(null);
  const [formIdCounter, setFormIdCounter] = useState(2); // Should start with the number of elements on formik initial orders[]

  const closeToast = () => setMessage(null); 

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await axios.post(props.submitURL, {
        gift_card_order: values
      });
      formik.resetForm();
      setMessage(response.data.message);
    } catch (error) {
      const ex = error;
      console.log("MAX", error);
      if(error.response && error.response.data){
        formik.setErrors(error.response.data);
      } // errors
    }
    formik.setSubmitting(false);
  };

  const removeItem = (itemToRemoveFormId) => {
    const newOrders = formik.values.orders.filter( (item, index) => item.formId != itemToRemoveFormId ); // Maybe there is a better way for this
    formik.setFieldValue("orders", newOrders);
  }

  const addNewItem = () => {
    const newOrders = [ ...formik.values.orders, { quantity: 0, amount: "", formId: formIdCounter } ]
    formik.setFieldValue("orders", newOrders);
    setFormIdCounter(formIdCounter + 1);
  }

  const formik = useFormik({
    initialValues: {
      notes: "",
      orders: [ // GC Orders in Union
        { quantity: 0, amount: "", formId: 0, displayAsMoney: true },
      ]
    },
    onSubmit: handleSubmit
  });

  const formatPrice = (value) => {
    // Convert to a number first
    const number = parseFloat(value);
    if (isNaN(number)) {
      return '';
    }
  
    // Format the number with commas as thousands separators
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const { values, errors } = formik;

  console.log("E", errors)
  console.log("V", values.orders)

  return (
    <Container className="mt20">
      <Card>
        <Card.Body>
          <Card.Title>Generar Manualmente</Card.Title>
          <Form onSubmit={ formik.handleSubmit } >
            { !!message &&
              <Toast style={{ position: "absolute", right: 0, top: 0, zIndex: 90 }} delay={3500} autohide show onClose={ closeToast }>
                <Toast.Header>
                  <strong className="mr-auto">{ message }</strong>
                </Toast.Header>
              </Toast>
            }

            <ListGroup variant="flush">
              <ListGroup.Item >
                <AppInput
                  label="Notas"
                  name="notes" value={ values.notes } onChange={ formik.handleChange } placeholder=""
                  error={ errors.notes } />
              </ListGroup.Item>
              { values.orders.map( (gc_order, index) => {
                const formattedPrice = gc_order.displayAsMoney ? formatPrice(gc_order.amount) : gc_order.amount;
                return(
                  <ListGroup.Item key={index}>
                    <Form.Row>
                      <Form.Group as={Col} sm={5} xs={12}>
                        <AppInput
                          label="Cantidad"
                          name={`orders[${index}].quantity`}
                          value={ gc_order.quantity } onChange={ formik.handleChange } placeholder=""
                          error={ errors.quantity }
                          type="number"
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={5} xs={12}>
                        <AppInput
                          label="Monto (Colones)"
                          attachedText="₡"
                          name={`orders[${index}].amount`} 
                          value={ formattedPrice } 
                          onChange={ formik.handleChange } 
                          placeholder=""
                          // Use onFocus and onBlur to toggle between raw and formatted values (when losing focus set Money format, when focus set number for easier input)
                          onFocus={ () => formik.setFieldValue(`orders[${index}].displayAsMoney`, false)}
                          onBlur={ () => formik.setFieldValue(`orders[${index}].displayAsMoney`, true) }
                          error={ errors.amount } />
                      </Form.Group>
                      <Form.Group className='flex align-items-end' as={Col} sm={2} xs={12}>
                        <Button onClick={ () => removeItem(gc_order.formId) } variant="danger">Eliminar</Button>
                      </Form.Group>
                    </Form.Row>
                  </ListGroup.Item>

                )
              })}
              <ListGroup.Item>
                <Button block onClick={ addNewItem } variant="primary">Agregar</Button>
              </ListGroup.Item>

            </ListGroup>

            <div className="flex justify-center">
              <Button className="border-0 pv8 ph16 font-weight-bold" variant="dark" type="submit" disabled={ formik.isSubmitting }>
                { formik.isSubmitting ? "ENVIANDO..." : "CREAR" }
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
};

GiftCardOrderUnionNew.propTypes = propTypes;
GiftCardOrderUnionNew.defaultProps = defaultProps;

export default GiftCardOrderUnionNew;