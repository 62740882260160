import React from 'react';
import { 
  IconButton, Chip, Avatar,
  Switch, FormGroup, FormControlLabel,
  ButtonGroup
} from '@mui/material';
import { Edit, Delete, ArrowUpward, ArrowDownward } from '@mui/icons-material';

// Chip colors - available are: "default", "primary", "secondary", "success", "error", "warning", "info"
const statusToColor = {
  "DRAFT": "warning",
  "PUBLISHED": "success",
  "PUBLISHED_AND_DRAFT": "info",
}

export const pricingRuleColumns = [
  { header: "ID", accessor: "id" },
];

export const appPagesColumns = [
  { header: "ID", accessor: "id" },
  { header: "Tipo", accessor: "kindHuman" },
  { header: "Title", accessor: "shopifyTitle" },
  { header: "Estado", accessor: ({ status, statusHuman }) => <Chip color={statusToColor[status]} label={statusHuman} /> },
  { header: "Handle", accessor: "shopifyHandle" },
  { header: "Actualizado por", accessor: (item) => item.lastUpdatedBy.name },
  { header: "Fecha actualización", accessor: "updatedAtHuman" },
  { header: "Fecha publicación", accessor: "publishedAtHuman" },
  { header: "Acciones", accessor: (item, columnUtils) => {
    const editURL = columnUtils.builderURLBase.replace(":ID", item.id); // should come from columnUtils
    return (
      <div className="flex align-items-center">
        <IconButton href={editURL} color='info'>
          <Edit />
        </IconButton>

        <IconButton onClick={() => columnUtils.handleDelete(item.id)} color='error'>
          <Delete />
        </IconButton>
      </div>
    )
  }},
];

export const upsellOffersColumns = [
  { header: "", accessor: ({ shopifyImageUrl }) => <Avatar src={ shopifyImageUrl } sx={{ width: 128, height: 128 }} /> },
  { header: "Producto", accessor: "shopifyVariantTitle" },
  { header: "SKU", accessor: "sku" },
  { header: "Habilitado", accessor: ({ id, enabled }, columnUtils) => {
    const onToggle = () => columnUtils.handleEnableToggle(id, !enabled);
    return(
      <FormGroup>
        <FormControlLabel
          control={
            <Switch 
              name="enabled"
              checked={enabled} 
              onChange={onToggle}
            />
          }
        />
      </FormGroup>
    )
  }},
  { header: "Actualizado por", accessor: (item) => item.lastUpdatedBy?.name },
  { header: "Acciones", accessor: (item, columnUtils) => {
    return (
      <div className="flex align-items-center">
        <ButtonGroup>
          { columnUtils.positionTopId != item.id &&
            <IconButton onClick={() => columnUtils.handleChangePositionTrigger(item.id, 'UP')}>
              <ArrowUpward />
            </IconButton>
          }

          { columnUtils.positionBottomId != item.id &&
            <IconButton onClick={() => columnUtils.handleChangePositionTrigger(item.id, 'DOWN')}>
              <ArrowDownward />
            </IconButton>
          }

          <IconButton onClick={() => columnUtils.handleDelete(item.id)} color='error'>
            <Delete />
          </IconButton>
        </ButtonGroup>
      </div>
    )
  }},
]