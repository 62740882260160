import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ToggleButtonGroup, ToggleButton, Button } from "@mui/material";
import { PersonalVideo, PhoneAndroid, AddCircle } from '@mui/icons-material';

import { useFormikContext } from 'formik';

const propTypes = {

}

const defaultProps = {

}

const ViewportSettings = (props) => {
  const { isSubmitting, status, setStatus } = useFormikContext();

  const handleViewportChange = (event, newViewport) => {
    if(!!newViewport){ // Do not allow unselecting
      setStatus({ ...status, viewport: newViewport });
    }
  };

  const handleOpenToolbox = () => {
    setStatus({ ...status, toolboxOpen: true });
  }

  return (
    <div id="viewport-settings">
      <ToggleButtonGroup
        exclusive
        fullWidth
        value={status.viewport}
        onChange={ handleViewportChange }
      >
        <ToggleButton color='primary' value="DESKTOP">
          <PersonalVideo />
        </ToggleButton>
        <ToggleButton color='primary' value="MOBILE">
          <PhoneAndroid />
        </ToggleButton>
      </ToggleButtonGroup>
      <Button 
        fullWidth 
        variant='contained' color='primary' 
        className='button-add-element'
        startIcon={<AddCircle />}
        onClick={ handleOpenToolbox }
      >
        Agregar
      </Button>
    </div>
  )
};

ViewportSettings.propTypes = propTypes;
ViewportSettings.defaultProps = defaultProps;

export default ViewportSettings;