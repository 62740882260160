import React from 'react';
import PropTypes from 'prop-types';
import { ELEMENT_TYPES } from './functions/actions';

const propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
}

// HTML renderers aka "blocks"
const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align }; // Extra "align" property for alignment
  switch (element.type) {
    case ELEMENT_TYPES.BLOCK_QUOTE:
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case ELEMENT_TYPES.BULLET_LIST:
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case ELEMENT_TYPES.HEADING_ONE:
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case ELEMENT_TYPES.HEADING_TWO:
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case ELEMENT_TYPES.LIST_ITEM:
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case ELEMENT_TYPES.NUMBERED_LIST:
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

export default Element;