import React, { useState, useEffect,useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Table, Button, Alert } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import axios from 'utils/axios-rails'

import LogoBusiness from 'images/logo-business-black.png';

const propTypes = {
  data: PropTypes.object.isRequired,
  ids: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  email: PropTypes.string,
  emailSubmitURL: PropTypes.string.isRequired,
}

//const unimartWishlistURL = "https://www.unimart.com/apps/swymWishlist/wishlist/index.php";

const PublicRequestQuote = (props) => {
  const today = new Date();
  const elements = props.data.table;
  const discount = props.discount;

  const qt = {};

  const parseProducts = (products) => {
    let result = {};
    let index = 0;
    for(let product of products){
      result[index] = { q: 1, price: Number(product.data.price - (product.data.price * props.discount)) }
      index++;
    }
    return result;
  }

  const [quantity, setQuantity] = useState(parseProducts(elements.products));
  const [show, setShow] = useState(false); // This useState is help to show alert
  const [alertMessage, setAlertMessage]= useState(""); // This useState is help to set Message of Alert
  const [quoteValue, setQuoteValue] = useState("");
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [email, setEmail]= useState(props.email ? props.email : "")

  const isEmailValid= /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; // regular expression to test is email is valid
  const [errorEmail, setErrorEmail]= useState(false);

  const encodeToBase64 = (data) => btoa(unescape(encodeURIComponent(JSON.stringify(data)))); // Escape weird chars to work with UTF stuff

  useEffect( () => {
    if(!!quoteValue){
      formRef.current.submit();
    }
  }, [quoteValue])

  const changeQuantity= (newQuantity, index, price)=>{
    const newPrice = Math.round( (newQuantity * (price - (price * props.discount))) * 100)/100;
    setQuantity({
      ...quantity,
      [index]: {
        q: newQuantity,
        price: newPrice
      }
    })
  }

  const grandTotal= Object.values(quantity).reduce((r, { price }) => r + price, 0)

  const data ={
    quantity_prices: quantity,
    total: grandTotal,
    data:{
      customerName: elements.customer.table.name,
      ids: props.ids,
      token:"balbla",
    },
  }

  // useCallback will submit
  const sendData = () => {
    setQuoteValue(encodeToBase64(data));
  }

  const sendDataEmail = async () =>{
    if (isEmailValid.test(email)) {
      setLoading(true);
      const emailData = { ...data, email: email };
      try{
        const response = await axios.post(props.emailSubmitURL, {
          quote_email: encodeToBase64(emailData)
        });
        // Artificial "cooldown" to avoid duplicate emails
        setTimeout(() => {
          setAlertMessage(response.data.message);
          setShow(true);
          setEmail("")
          setErrorEmail(false)
          setLoading(false);
        }, 1000);
      } catch (error){
        setAlertMessage("Ocurrio un error de conexión, por favor intente más tarde.")
        setShow(true);
        setLoading(false);
      }
    }else {
      setErrorEmail(true)
    }

  }

return (
    <Container className="app-container">
      <Alert show={show} variant="success" onClose={() => setShow(false)} dismissible>
        <p className="mb0">{alertMessage}</p>
      </Alert>
      <div className="mb20">
        <SubmitActions
          email={ email }
          errorEmail={ errorEmail }
          onChangeEmail={ setEmail }
          onEmailSubmit={ sendDataEmail }
          onDownload={ sendData }
          loading={ loading }
        />
      </div>
      <Row>
        <Col sm={8}>
          <img height={25} src={ LogoBusiness } alt="Unimar Logo"/>
        </Col>
        <Col sm={4}>
          <h2 className="mb0 lead text-right">COTIZACION</h2>
          <p className="mr10 text-right"><strong>Fecha: </strong> {today.getDay()}/{today.getDate()}/{today.getFullYear()}</p>
        </Col>
        <Col sm={12}>
          Para:
        </Col>
        <Col sm={12}>
          Contacto: {elements.customer.table.name}
        </Col>
      </Row>
        <Table responsive className="pdf-invoice">
          <thead>
            <tr>
              <th colSpan="2">ARTÍCULOS</th>
              <th>PRECIO</th>
              <th>CANT.</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            { elements.products.map((element, index) => (
              <tr key={index}>
                <td>
                  <img className="mr10" height="60" src={element.data.product.images.edges[0].node.transformedSrc} alt=""/>
                </td>
                <td>
                  {element.data.displayName}
                </td>
                <td>
                  ₡{Math.round((element.data.price - (element.data.price * discount))*100)/100}
                </td>
                <td>
                  <input
                    className="form-control"
                    type="number"
                    min={1}
                    value={quantity[index].q}
                    onChange={e=>{changeQuantity(e.target.value, index, element.data.price )}}
                  />
                </td>
                <td className="text-right" style={{ minWidth: "120px" }}>
                  ₡{quantity[index].price}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td >
                <b>Total</b>
                <p>(Incluye IVA)</p>
              </td>
              <td className="text-right">
                ₡{grandTotal}
              </td>
            </tr>
          </tbody>
        </Table>

      <Row >
        <Col sm={12}>
          <p>
            El pago debe ser realizado en la misma moneda de la cotización.
          </p>
          <p>
            Esta cotización no es una reserva de inventario, por lo cual le recomendamos verificar la existencia de los
            productos con su ejecutivo(a) antes de
            realizar su pago.
          </p>
        </Col>
        <Col sm={12} className="text-center mt40 footer-unimart-pdf">
          <p><strong>Unimart Alfa S.A.</strong></p>
          <p>Céd. Jurídica: 3-101-713520</p>
          <p>1.5KM Oeste de Embajada de EEUU, San José, , San José, Costa Rica</p>
          <p>business@unimart.com</p>
          <p>www.unimart.com</p>
        </Col>
      </Row>
      <Alert show={show} variant="success" onClose={() => setShow(false)} dismissible>
        <p className="mb0">{alertMessage}</p>
      </Alert>
      <div className="mt20">
        <SubmitActions
          email={ email }
          errorEmail={ errorEmail }
          onChangeEmail={ setEmail }
          onEmailSubmit={ sendDataEmail }
          onDownload={ sendData }
          loading={ loading }
        />
      </div>
      <div className="hidden_i">
        <form ref={formRef} action={ props.url } method="POST" onSubmit={ sendData }>
          <input name="quote" type="hidden" value={ quoteValue } />
        </form>
      </div>
    </Container>
  )
}

const SubmitActions = ({ email, errorEmail, onChangeEmail, onDownload, onEmailSubmit, loading  }) => {
  return(
    <div>
      <Form.Group controlId="formBasicEmail">
        <Row>
          <Col>
            <Form.Control type = "email"
              placeholder = "Enter email"
              value = {email}
              onChange = { e => onChangeEmail(e.target.value)}
              isInvalid={errorEmail}
            />
            <Form.Control.Feedback type="invalid">Email no valido</Form.Control.Feedback>
          </Col>
          <Col>
            <Button disabled={!errorEmail && !email} className="font-weight-bold" variant="dark" block onClick={onEmailSubmit}>
              { loading ? "PROCESANDO..." : "Enviar al correo"  }
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <Button disabled={loading} className="gray_black_btn font-weight-bold" variant="light" size="lg" block onClick={onDownload}>
        { loading ? "PROCESANDO..." : "DESCARGAR COTIZACIÓN"  }
      </Button>
    </div>
  )
}

PublicRequestQuote.propTypes= propTypes


export default PublicRequestQuote;