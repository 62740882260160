import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Page from 'shared/AppPage';
import AppModal from 'components/shared/AppModal';
import UploadButton from 'components/shared/form/UploadButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Card, Button, CardActions, Stack, Alert, Dialog, DialogContent, DialogTitle } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useMuiNotification } from 'utils';
import axios from 'utils/axios-rails';
import AppNotification from 'components/shared/AppNotification';
import SellerSpecialCostsItem from "./SellerSpecialCostsItem";

const propTypes = {
  records: PropTypes.array.isRequired,
  createURL: PropTypes.string.isRequired,
  itemActionURL: PropTypes.string.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  validateImportFileURL: PropTypes.string.isRequired,
}

const defaultProps = {
  startDate: dayjs(new Date()),
  endDate: dayjs(new Date()).add(1, 'day'),
}

const SellerSpecialCostsIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState(props.records);
  const [importFile, setImportFile] = useState(null);
  const importModalRef = useRef(null);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [errorMessages, setErrorMessages] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const openImportModal = useCallback(() => {
    importModalRef.current.open()
    setErrorMessages([])
    setImportFile(null)
  }, [importModalRef]);
  const closeImportModal = useCallback(() => importModalRef.current.close(), [importModalRef]);
  const [sendNotification, notificationProps] = useMuiNotification();

  const handleValidationImport = async (value, callbackResult) => {
    setLoading(true);
    let success = false;
    try {
      const formData = new FormData;
      formData.append("file", value);
      const { data } = await axios.post(props.validateImportFileURL, formData);

      if (data.success) {
        success = true;
        sendNotification({ message: "Carga exitosa", severity: "success" });
        closeImportModal();
      }
      else {
        setErrorMessages(data.errors)
        setOpenDialog(true)
      }
    } catch (error) {
      console.log("EEE", error);
      sendNotification({ message: "Error de conexión", severity: "error" });
    }
    setLoading(false);
    console.log("doit first")
    callbackResult(success)
    return success;
  }

  const handleSave = async (values, formikBag) => {
    setLoading(true);
    let success = false;
    let formData = buildFormDataSellerCost(
      !formikBag ? [importFile, startDate, endDate] : [values.costs_file, values.start_date, values.end_date]
    )
    try {
      const { data } = !formikBag ? await axios.post(props.createURL, formData) : await axios.patch(`${props.createURL}/${values.id}`, formData);
      if (data.success) {
        success = true;
        sendNotification({ message: "Carga exitosa", severity: "success" });
        closeImportModal();
        if (!data.costs_file) {
          !formikBag ? setRecords(data.records) : formikBag.setFieldValue('disabledSave', true)
        } else {
          !formikBag ? setRecords(data.records) : formikBag.setFieldValue('disabledSave', true), formikBag.setFieldValue('costs_file', data.costs_file)
        }
      }
      else {
        console.log("EEE", data.errors);
        setErrorMessages(data.errors)
        if (!!formikBag) {
          setOpenDialog(true)
        }
      }
    } catch (error) {
      console.log("EEE", error);
      sendNotification({ message: "Error de conexión", severity: "error" });
    }
    setLoading(false);
    return success;
  }

  const buildFormDataSellerCost = (values) => {
    const formData = new FormData;
    formData.append("seller_special_cost[costs_file]", values[0]);
    formData.append("seller_special_cost[start_date]", values[1]);
    formData.append("seller_special_cost[end_date]", values[2]);
    return formData;
  }


  const handleDelete = async (recordId) => {
    const newRecords = records.filter(element => element.id != recordId)
    setLoading(true)
    let success = false;
    try {
      const { data } = await axios.delete(props.itemActionURL.replace("ID", recordId));
      if (data.success) {
        sendNotification({ message: "Eliminado exitosamente", severity: "success" });
        setRecords(newRecords)
      }
      else {
        sendNotification({ message: "Error al eliminar", severity: "error" });
      }

    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    success = false
    return success;
  }

  const itemProps = {
    onSave: handleSave,
    onDelete: handleDelete,
    loading: loading,
    validateFile: handleValidationImport,
  }

  const buildErrors = (errors) => {
    console.log(errors)
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        {errors.map((value, index) =>
          <Alert severity="error" key={index}>{value}</Alert>
        )}
      </Stack>)
  }

  return (
    <Page
      title={"Consola de Costos Especiales"} >
      <AppNotification
        {...notificationProps}
      />

      <Card>
        <div className="Container">
          {records.map((value, index) =>
            <SellerSpecialCostsItem
              key={index}
              sellerSpecialCosts={value}
              {...itemProps}
            />
          )}
        </div>

        <CardActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={openImportModal}
            startIcon={<ControlPointIcon />}
            variant="text">
            Agregar Archivo
          </Button>
          <Button
            href='/samples/seller-special-costs-import-sample.xlsx'
            color='info'
          >
            Descargar archivo de muestra
          </Button>
        </CardActions>
      </Card>

      <AppModal
        ref={importModalRef}
        title="Importar Archivo de Costos Especiales"
      >
        <div>
          <p>El archivo debe contener los headers 'id', 'sku', 'cost' Y 'currency'. Currency debe ser valores fijo en 'crc' O 'usd'.</p>
          <UploadButton
            onUpload={setImportFile}
            helpText="Solo se aceptan archivos .xlsx y .xls"
          />
          {errorMessages.length > 0 &&
            buildErrors(errorMessages)
          }
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <div className="flex justify-center mt20">
              <DatePicker className="flex1 mr4 ml4"
                label="Fecha de Inicio"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <DatePicker className="flex1 mr4 ml4"
                label="Fecha de Finalizacion"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </div>
          </LocalizationProvider>
          <Button
            className='mt12'
            fullWidth variant="contained"
            color='primary'
            onClick={handleSave}
            disabled={loading || !importFile}>
            Cargar
          </Button>
        </div>
      </AppModal>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {"Error al actualizar"}
        </DialogTitle>
        <DialogContent>
          {buildErrors(errorMessages)}
        </DialogContent>
      </Dialog>
    </Page>
  );
};

SellerSpecialCostsIndex.propTypes = propTypes;
SellerSpecialCostsIndex.defaultProps = defaultProps;

export default SellerSpecialCostsIndex;

