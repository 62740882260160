import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, Card, CardContent, TextField, Skeleton, Pagination, CircularProgress } from '@mui/material';
import Page from 'shared/AppPage';
import AppNotification from 'components/shared/AppNotification';
import AppModal from 'components/shared/AppModal';
import UploadButton from 'components/shared/form/UploadButton';
import SkeletonCard from 'components/shared/skeleton/SkeletonCard';

import MembershipProductItem from './components/MembershipProductItem';

import { useMuiNotification, useFilterPagination } from 'utils';
import axios from 'utils/axios-rails'

import debounce from 'lodash/debounce';

const propTypes = {
  records: PropTypes.array.isRequired,
  createURL: PropTypes.string.isRequired,
  uploadProductsURL: PropTypes.string.isRequired,

  itemActionURL: PropTypes.string.isRequired, // update/delete
  filterURL: PropTypes.string.isRequired,
  sampleFileURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const MembershipProductsIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [formId, setFormId] = useState(0);
  const [sendNotification, notificationProps] = useMuiNotification();
  const { records: filteredRecords, filters, handleFilterChange, loading: filterLoading, pagination } = useFilterPagination( props.filterURL, { 
    initialRecords: products, 
    initialFilters: { modified: true } // Flag to auto fetch on first load
  });
  const [searchQuery, setSearchQuery] = useState('');

  const importModalRef = useRef(null);
  const [importFile, setImportFile] = useState(null);

  const submitFilterQuery = useCallback(debounce( (query) => {
    handleFilterChange('query', query);
  }, 800), []);

  const handleSearchQueryChange = useCallback( (e) => {
    setSearchQuery(e.target.value);
    submitFilterQuery(e.target.value);
  }, []);

  const findItemIndex = (item, collection) => {
    const recordIndex = collection.findIndex(citem => citem.formId === item.formId);

    return recordIndex;
  }

  const addNewProduct = useCallback(() => {
    const newItem = {};
    setProducts( (currentProducts) => [...currentProducts, newItem]);
    setFormId( (fid) => fid + 1);
  }, []);

  const handleSave = async (values, formikBag) => {
    let remoteRecord = null;
    try {
      const { data } = !!values.id ? await axios.patch(`${props.createURL}/${values.id}`, values) : await axios.post(props.createURL, values); // if id exists update
      if(data.success){
        remoteRecord = data.record;
        sendNotification({ message: "Guardado exitosamente", severity: "success" })
      }
      else{
        console.log("errors", data.errors);
        formikBag.setErrors(data.errors);
        sendNotification({ message: "Error al guardar", severity: "error" })
      }
    } catch (error) {
      console.warn("Error saving record", error);   
    }

    // Is this needed?
    // if(!!remoteRecord){ // local save into state
    //   const itemIndex = findItemIndex(values, products);

    //   const newItems = [ 
    //     ...groupCollection.slice(0, itemIndex), 
    //     values,
    //     ...groupCollection.slice(itemIndex + 1) 
    //   ];

    //   setProducts(newItems);
    // }
  };

  const handleDelete = async (record) => {
    const currentProducts = products;
    const itemIndex = findItemIndex(record, currentProducts);
    
    let successfulDelete = true; // optimistic
    const deletedRecord = { ...record, deleted: true }; // filtering out is a bit bugged since UI still preserve positional values, just flag deleted ones and hide.

    const newRecords = [ 
      ...currentProducts.slice(0, itemIndex), 
      deletedRecord,
      ...currentProducts.slice(itemIndex + 1),
    ]; // keep elements before and after index

    setProducts(newRecords);

    if(record.id){ // exists on backend
      try{
        const { data } = await axios.delete(props.itemActionURL.replace("ID", record.id));
        if(data.success){
          sendNotification({ message: "Eliminado exitosamente", severity: "success" });
        }
        else{
          successfulDelete = false;
          sendNotification({ message: "Error al eliminar", severity: "error" });
        }
      }
      catch(error){
      }
    } // else just remove from local

    if(!successfulDelete){ // rollback UI update
      setProducts(currentProducts);
    }
  };

  // Add formId to backend records (TODO think on pagination)
  const initializeRecords = useCallback((records) => {
    let recordsWithFormId= [];
    records.forEach((item, index) => {
      recordsWithFormId.push({ ...item, formId: index });
    });

    setFormId(records.length + 1);
    setProducts(recordsWithFormId);
  }, []);

  // Add formId to fetched records to be able to edit them easily
  // They are re-set if any filter is applied
  useEffect(() => {
    initializeRecords(filteredRecords);
  }, [filteredRecords]);

  const submitImportFile = async () => {
    setLoading(true);
    let success = false;
    try {
      const formData = new FormData;
      formData.append("file", importFile); // from state
      const { data} = await axios.post(props.uploadProductsURL, formData);

      if(data.success){
        success = true;
        sendNotification({ message: "Carga exitosa", severity: "success" });
        closeImportModal();
      }
      else{
        sendNotification({ message: data.errors, severity: "error" });
      }
    } catch (error) {
      console.log("EEE", error);
      sendNotification({ message: "Error de conexión", severity: "error" });
    }
    
    setLoading(false);
    return success;
  };

  const openImportModal = useCallback(() => importModalRef.current.open(), [importModalRef]);
  const closeImportModal = useCallback(() => importModalRef.current.close(), [importModalRef]);

  return (
    <Page 
      title="Consola de Descuentos de Membresias"
      primaryAction={{
        content: "Importar Productos",
        onClick: openImportModal,

        secondaryActions: [{
          url: props.sampleFileURL,
          content: "Descargar archivo de muestra",
        }]
      }}
    >
      <AppNotification
        {...notificationProps}
      />
      <div className="mb12">
        <TextField
          fullWidth
          label="Buscar por SKU"
          placeholder='AX-001...'
          className='mh4'
          variant="outlined"
          
          value={ searchQuery }
          onChange={ handleSearchQueryChange }
        />
      </div>

      {/* For now loading is too fast so skeleton looks bad */}
      {/* filterLoading && <SkeletonCard /> */}

      <Card>
        <CardContent>

          { filterLoading && <div className="flex justify-center"><CircularProgress color="primary" /></div> }

          { !filterLoading && products.map((product, index) => (
            <MembershipProductItem
              key={ product.formId }
              product={ product }
              onSave={ handleSave }
              onDelete={ handleDelete }
            />
          ))}

          { !filterLoading && products.length === 0 && (
            <Typography color="GrayText" variant="h5" align="center">
              No hay productos
            </Typography>
          )}
        </CardContent>
      </Card>

      <Pagination 
        className='mv20 flex justify-center'
        page={ pagination.page }
        onChange={ (event, value) => pagination.handlePageChange(value) }
        count={ pagination.totalPages } 
      />

      <AppModal
        ref={ importModalRef }
        title="Importar Productos"
      >
        <div>
          <p>El archivo debe contener los headers 'sku' y 'price', opcionalmente puede incluir el header 'active' para establecer si el descuento debe estar activado o no.</p>
          <p>Cada import es acumulativo, es decir los productos se agregan sobre los existentes, si el producto ya existía entonces se actualizan sus valores.</p>
          <p>Máximo 200 productos por archivo</p>
          <UploadButton 
            onUpload={ setImportFile }
            helpText="Solo se aceptan archivos .xlsx y .xls"
          />
          <Button 
            className='mt12'
            fullWidth variant="contained" 
            color='primary' 
            onClick={ submitImportFile } 
            disabled={ loading || !importFile }>
            Cargar
          </Button>
        </div> 
      </AppModal>
    </Page>
  )
};

MembershipProductsIndex.propTypes = propTypes;
MembershipProductsIndex.defaultProps = defaultProps;

export default MembershipProductsIndex;