import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextField, InputAdornment, CircularProgress } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import * as QUERIES from 'api/apollo-client';

import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';

const propTypes = {
  resourceUrl: PropTypes.string, // URL Value
  resourceId: PropTypes.string, // ID value
  loading: PropTypes.bool,

  onResourceUrlChange: PropTypes.func.isRequired,
  onResourceIdChange: PropTypes.func.isRequired,
}

const defaultProps = {
  loading: false,
}

// Input that query shopify resource information from a URL - Return URL and ID
const ResourceUrlInput = (props) => {
  const [resourceUrlError, setResourceUrlError] = useState(null); // Currently local state only, if invalid urls are being used more frequently, consider moving to block properties (saved)

  const [resourceSearchByUrl, { loading: searchLoading }] = useLazyQuery(QUERIES.APP_PAGE_SHOPIFY_RESOURCE_BY_URL, {
    onCompleted: (data) => {
      const resource = lodashGet(data, 'shopifyResourceByUrl.resource');
      console.log("Data", data, resource)
      if(!!resource) {
        props.onResourceIdChange(resource.id);
        // TODO toast success
      }
      else{
        props.onResourceIdChange(""); // Clear the resource ID
        setResourceUrlError(data.shopifyResourceByUrl.error);
      }
    },
    onError: (error) => {
      alert("Error with server");
    }
  });

  const debouncedResourceSearchByUrl = useCallback(lodashDebounce(resourceSearchByUrl, 1000), []);
  
  const handleResourceUrlChange = (e) => {
    if(resourceUrlError){ // Clear error if present
      setResourceUrlError(null);
    }
    const value = e.target.value;
    console.log("OnChange", value);
    props.onResourceUrlChange(value); // Update the URL text value
    debouncedResourceSearchByUrl({
      variables: { url: value }
    });
  }

  return (
    <TextField
      fullWidth
      label="URL"
      placeholder='https://unimart.com/resource/xyz'
      onChange={handleResourceUrlChange}
      disabled={ props.loading || searchLoading }
      className='mv4'
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            { searchLoading && <CircularProgress size={18} /> }
          </InputAdornment>
        )
      }}
      value={props.resourceUrl}
      error={!!resourceUrlError}
      helperText={resourceUrlError}
      color={ !!props.resourceId ? "success" : undefined }
      focused={ !!props.resourceId }
      multiline
    />
  )
}

export default ResourceUrlInput