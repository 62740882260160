import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, TextField } from '@mui/material';

const propTypes = {
  initialValue: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), // to initialize TextField display value
  options: PropTypes.array.isRequired,
}

const defaultProps = {

}

// Autocomplete with previously loaded options, for Autocomplete with dynamic API queries check AppAutocompleteApi
// DEPRECATED. TODO For new projects use AppAutocompleteApi (which support preloaded options too). Replace this component with AppAutocompleteApi someday
const AppAutocomplete = (props) => {
  const { value, ...autocompleteProps } = props;
  const [selectedOption, setSelectedOption] = useState(props.options[0]);

  // Find option related to value and set TextField display value
  const setInitialOption = useCallback((value, options) => {
    const option = options.find(option => option.value === value);
    setSelectedOption(option ? option : options[0]);
  }, []);

  useEffect(() => {
    setInitialOption(props.value, props.options);
  }, []);

  // Handle local option selected value while sending back callback to parent, this way a simple value can be used on Form without the need to consider Option and Value management
  const handleChange = useCallback( (event, option) => {
    setSelectedOption(option); // locally set whole option as value as required by Autocomplete
    props.onChange(event, option); // for parent
  }, [props.onChange]);

  // console.group("AppAutocomplete");
  // console.log("Option",selectedOption);
  // console.log("Value", props.value);
  // console.groupEnd("AppAutocomplete");

  return (
    <Autocomplete
      { ...autocompleteProps }
      onChange={ handleChange }
      value={ selectedOption }
      renderInput={(params) => <TextField {...params} size='small' />}
    />
  )
};

AppAutocomplete.propTypes = propTypes;
AppAutocomplete.defaultProps = defaultProps;

export default AppAutocomplete;