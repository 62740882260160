import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useNode } from '@craftjs/core';
import { useFormikContext } from 'formik';

import { FormControl, FormLabel } from '@mui/material';
import SettingsBannerWithDescription from './components/SettingsBannerWithDescription';
import PreviewBannerImage from './components/PreviewBannerImage';

import useImageAppPageAsset from './components/useImageAppPageAsset';

import { shapeForBanner, defaultPropsForBanner } from './types';

const propTypes = {
  desktop: PropTypes.shape(shapeForBanner),
  mobile: PropTypes.shape(shapeForBanner),
}

const defaultProps = {
  desktop: defaultPropsForBanner,
  mobile: defaultPropsForBanner,
}

const BannerSecondary = (props) => {
  const { status } = useFormikContext();
  const { desktop, mobile, ...builderProps } = props;
  const { connectors: { connect, drag }, } = useNode();

  const bannerItem = status.viewport === "MOBILE" ? mobile : desktop;

  return (
    <div
      className="user-block user-banner-secondary"
      ref={ref => connect(drag(ref))}
      {...builderProps}
    >
      <PreviewBannerImage 
        banner={{
          ...bannerItem,
          resourceUrl: desktop.resourceUrl
        }} 
      />
    </div>
  )
};

// Settings panel for block
const BannerSecondarySettings = () => {
  return (
    <>
      <SettingsBannerWithDescription
        imageUrlKey={"desktop.imageUrl"}
        resourceIdKey={"desktop.resourceId"}
        resourceUrlKey={"desktop.resourceUrl"}
        imageSettings={{
          display_type: "DESKTOP"
        }}
        imageWidth={1200}
        imageHeight={200}
      />
      
      <SettingsBannerWithDescription
        imageUrlKey={"mobile.imageUrl"}
        resourceIdKey={"mobile.resourceId"}
        resourceUrlKey={"mobile.resourceUrl"}
        imageSettings={{
          display_type: "MOBILE"
        }}
        imageWidth={360}
        imageHeight={240}
        withUrl={false}
      />
    </>
  )
}

BannerSecondary.craft = {
  props: defaultProps,
  related: {
    name: () => "Banner Medio Full",
    settings: BannerSecondarySettings
  }  
}

BannerSecondary.propTypes = propTypes;
BannerSecondary.defaultProps = defaultProps;

export default BannerSecondary;