import React, { useState, useEffect } from 'react';

import { useNode } from '@craftjs/core';

import { useFormikContext } from 'formik';

import { useMutation } from '@apollo/client';
import * as QUERIES from 'api/apollo-client';

import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';

// Hook to upsert page assets on an AppPage for a specific image resource.
// Requires builder, apollo and formik contexts to be available
// propName is the prop to manipulate when handling the asset. i.e. "desktopUrl". Should match that used on the component displaying the asset
/**
 * Custom React hook for managing an image asset on the app page.
 * @param {string} propName - The name of the prop to update with the image URL.
 * @returns {[{ value: any, loading: boolean, errors: any[] }, { handleImageChange: Function, handleDeleteImage: Function }]} A tuple containing data and utility functions.
 */
export default function useImageAppPageAsset(propName, options = { sequence: 1, display_type: "GENERAL" }) {
  const [errors, setErrors] = useState([]);
  const { values, setSubmitting, isSubmitting } = useFormikContext();
  
  const { id: nodeId, actions: { setProp }, urlValue } = useNode((node) => ({
    urlValue: lodashGet(node.data.props, propName),
  }));

  // console.log("Node props", props);

  /**
   * Mutation function for creating or updating page assets.
   */
  const [assetUpsert, _stuff] = useMutation(QUERIES.PAGE_ASSET_UPSERT, {
    onCompleted: (data) => {
      try {
        setSubmitting(false);
        console.log("Request successful", data);
        const pageAsset = data.appPageAssetUpsert.appPageAsset;
        if(!!pageAsset?.id){ // Success
          setProp(props => (
            lodashSet(props, propName, pageAsset.mediaUrl)
          )); // Set the prop with the new URL
        }
        else{
          setErrors(data.appPageAssetUpsert.errors)
        }
      } catch (error) {
        console.error("Error on assetUpsert", error);
        alert("Error con servidor, por favor intente de nuevo.");
      }
    },
    onError: (error) => {
      setSubmitting(false);
      console.log("Error", error);
    }
  });

  /**
   * Handler for changing the image file.
   * @param {File} file - The image file to be uploaded.
   */
  const handleImageChange = (file) => {
    setErrors([]);
    const filename = file.name;
    const reader = new FileReader();
    reader.onload = (e) => { // Get the base64 string and send it to the API
      setSubmitting(true);
      const readerResult = reader.result; // Format is: data:<media_type>;base64,<base64_string>. i.e. "data:image/jpeg;base64,/9jxxx..."
      const parts = readerResult.split(";base64,");
      const mimeType = parts[0].split(":")[1]; // i.e. "image/jpeg"
      const base64 = parts[1]; // Base64 string

      assetUpsert({ variables: { 
        input: { 
          appPageId: values.id,
          nodeId: nodeId,
          sequence: options.sequence,
          displayType: options.display_type,
          mediaFile: {
            filename: filename,
            mimeType: mimeType,
            base64: base64
          }
        } 
      }});
    }
    reader.readAsDataURL(file); // This will trigger the onload event
  }

  /**
   * Handler for deleting the image.
   */
  const handleDeleteImage = () => {
    console.log("Delete image");
    // Reset prop setting!
  }

  const data = {
    value: urlValue,
    loading: isSubmitting,
    errors,
  }

  const utils = {
    handleImageChange,
    handleDeleteImage,
  }

  return [data, utils];
};