import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Snackbar, Alert } from '@mui/material';

const propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']).isRequired,
    horizontal: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  }),
}

const defaultProps = {
  position: { vertical: 'bottom', horizontal: 'center' },
}

const AppNotification = (props) => {
  return (
    <Snackbar
      anchorOrigin={props.position} 
      autoHideDuration={6000} 
      open={ props.open } 
      onClose={ props.handleClose }
    >
      <Alert onClose={ props.handleClose } severity={ props.severity }>
        { props.message }
      </Alert>
    </Snackbar>
  )
};

AppNotification.propTypes = propTypes;
AppNotification.defaultProps = defaultProps;

export default AppNotification;