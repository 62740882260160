import $ from "jquery";
import AlertSupport from './alert-support';
import StyleConstants from './style-constants';

document.addEventListener("turbolinks:load", () => {
    $('[data-invoice="retry"]').on('click', function() {
        // on arrow functions, in declaration time
        // jquery cannot bind "this" to the object in which this function is called
        // "this" would be an empty object {}

        $.ajax({
            url: '/invoices/retry',
            type: 'POST',
            dataType: 'JSON',
            data: {
              id: this.dataset['id'], // on arrow functions, it produces an error
            },
            success: (data) => {
              AlertSupport.render(data.message, StyleConstants.bootstrapSuccessClassName);
            },
            error: (jqXHR, textStatus, httpErrorDesc) => {
              AlertSupport.render(`ERROR EN EL SERVIDOR (${textStatus}): ${httpErrorDesc}`, StyleConstants.bootstrapFailureClassName);
            },
        });
    })
  }
)