import React from 'react';
import PropTypes from 'prop-types';

import { useNode } from '@craftjs/core';
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, TextField } from '@mui/material';

const BUTTON_VARIANTS = [
  {
     label: "Primary!",
     value: "primary", // css class modifier
   },
   {
     label: "Secondary!",
     value: "secondary",
   },
];

const BUTTON_SIZES = [
  {
    label: "Small",
    value: "small",
  },
  {
    label: "Medium",
    value: "medium",
  },
];

const propTypes = { // TODO change to the actual props
  variant: PropTypes.oneOf(BUTTON_VARIANTS.map((variant) => variant.value)),
  size: PropTypes.oneOf(BUTTON_SIZES.map((size) => size.value)),
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

const defaultProps = {
  variant: "primary",
  size: "medium",
  title: "Click me",
  url: "",
}

const Button = (props) => {
  const { variant, size, title, url, ...builderProps } = props;
  const { connectors: { connect, drag } } = useNode();

  const handleButtonClick = (e) => event.preventDefault();

  const classNames = [
    "user-button",
    `user-button-variant-${variant}`,
    `user-button-size-${size}`,
  ];

  return (
    <div
      className='user-block user-button-wrapper'
      ref={(ref) => connect(drag(ref))}
      {...builderProps}
    >
      <a
        href={url}
        className={classNames.join(" ")}
        onClick={handleButtonClick}
      >
        {title}
      </a>
    </div>
  );
};

const ButtonSettings = () => {
  const { actions: {setProp}, editableProps } = useNode((node) => ({
    editableProps: {
      variant: node.data.props.variant,
      size: node.data.props.size,
      title: node.data.props.title,
      url: node.data.props.url,
    }
  }));

  return (
    <div>
      <TextField
        fullWidth
        label="Title"
        value={editableProps.title}
        onChange={e => setProp(props => props.title = e.target.value)}
        variant='outlined'
      />

      <TextField
        fullWidth
        label="URL"
        placeholder='https://example.com'
        value={editableProps.url}
        onChange={e => setProp(props => props.url = e.target.value)}
        variant='outlined'
      />

      <FormControl component="fieldset">
        <FormLabel component="legend">Variant</FormLabel>
          <RadioGroup
            value={editableProps.variant}
            onChange={(e) => setProp((props) => (props.variant = e.target.value))}
          >
          { BUTTON_VARIANTS.map((variantType) => (
            <FormControlLabel
              key={variantType.value}
              label={variantType.label}
              value={variantType.value}
              control={<Radio size="small" color="primary" />}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl size="small" component="fieldset">
        <FormLabel component="legend">Size</FormLabel>
        <RadioGroup
          value={editableProps.size}
          onChange={(e) => setProp((props) => (props.size = e.target.value))}
        >
          { BUTTON_SIZES.map((sizeType) => (
            <FormControlLabel
              key={sizeType.value}
              label={sizeType.label}
              value={sizeType.value}
              control={<Radio size="small" color="primary" />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

Button.craft = {
  props: defaultProps,
  related: {
    name: () => "Botón",
    settings: ButtonSettings
  }  
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;