import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useNode } from '@craftjs/core';

import SlateRichTextEditor from 'components/shared/slate/SlateRichTextEditor';
import { serializeToHtml } from 'components/shared/slate/functions/serializerHtml';

const propTypes = {
  slateJson: PropTypes.string.isRequired, // JSON serialized slate content. i.e. [{"type":"paragraph","children":[{"text":"Hello world"},{"bold":true,"text":"we are back!"}]}] 
}

const defaultProps = {
  slateJson: "",
}

const RichTextDescription = (props) => {
  const { slateJson, ...builderProps  } = props;
  const [previewHtml, setPreviewHtml] = useState("");
  
  const { connectors: { connect, drag } } = useNode();

  useEffect(() => {
    if(slateJson){
      const content = JSON.parse(slateJson);
      setPreviewHtml(serializeToHtml(content));
    }
  }, [slateJson]);

  return (
    <div
      className={`user-block user-rich-text-description ${!previewHtml ? 'no-content-placeholder' : ''}`}
      ref={ref => connect(drag(ref))}
      {...builderProps}
    >
      <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
    </div>
  )
};

const defaultInitialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];

const RichTextDescriptionSettings = () => {
  const { actions: {setProp}, slateJson } = useNode((node) => ({
    slateJson: node.data.props.slateJson,
  }));

  // Save content as json to be easily saved in the database. Saving the HTML string instead would be a pain and would need reverse parsing to give it back to the editor.
  const handleSlateChange = (value) => {
    setProp(props => props.slateJson = JSON.stringify(value));
  };

  const initialValue = useMemo(() => { return !!slateJson ? JSON.parse(slateJson) : defaultInitialValue }, []); // Only load the initial value once

  return (
    <>
      <SlateRichTextEditor
        initialValue={initialValue}
        onChange={handleSlateChange}
      />
    </>
  )
}

RichTextDescription.craft = {
  props: defaultProps,
  related: {
    name: () => "Texto",
    settings: RichTextDescriptionSettings
  }  
}

RichTextDescription.propTypes = propTypes;
RichTextDescription.defaultProps = defaultProps;

export default RichTextDescription;