import React, { useState, useEffect, useCallback } from 'react';
import axios from 'utils/axios-rails';


// Based on axios for rails controller actions

/**
 * Allows to keep a filter state and easily paginate and query throught filtered endpoint
 * @param { String } filterURL - URL to query for filtered records
 * @param { Object } initialFilters - Initial filter state if any is required
 * @param { Array<Object> } initialRecords - Initial set of records
 */
export default function useFilterPagination(filterURL, { initialFilters = {}, initialRecords = [], initialTotalPages = 1}) {
  const [filters, setFilters] = useState({
    modified: false, // TODO move modified flag to a separate state
    ...initialFilters,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(initialTotalPages);
  const [records, setRecords] = useState(initialRecords);
  const [loading, setLoading] = useState(false);

  const handleFilterChange = useCallback( (name, value) => {
    // setRecords([]);
    setFilters( (currentValues) => ({
      ...currentValues,
      modified: true,
      [name]: value
    }));
    setPage(1); // Set page to 1 when filter changes
  }, []);

  const handlePageChange = useCallback( (newPage) => {
    setPage(newPage);
    if(!filters.modified){
      setFilters( (currentValues) => ({ ...currentValues, modified: true }));
    }
  }, [filters.modified]);

  const nextPage = useCallback( () => handlePageChange(page + 1), [page]);
  const prevPage = useCallback( () => handlePageChange(page - 1), [page]);

  useEffect(() => {
    if(!filters.modified){ // Don't fetch on first load!
      return;
    }
    const fetchRecords = async () => {
      console.log("Filter!");
      setLoading(true);
      const { data } = await axios.post(filterURL, { 
        filter: filters, // General "filter" params for strong params
        page,
      });
      console.log("Data", data);
      if(data.records){
        setRecords(data.records);
        setTotalPages(data.total_pages);
      }
      setLoading(false);
    }
    fetchRecords();
  }, [filters, page]);

  return {
    filters,
    records,
    loading,
    handleFilterChange,
    handlePageChange,

    pagination: {
      page,
      totalPages,
      handlePageChange, // i don't think this is the proper place to put this
      nextPage,
      prevPage,
    }
  }
}