import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  TextField, Stack, 
  Switch, FormGroup, FormControlLabel
} from '@mui/material';

import AppAutocomplete from 'components/shared/form/AppAutocompleteApi';
import AppTextField from 'components/shared/form/AppTextField';
import { SHOPIFY_VARIANTS_SEARCH } from 'api/apollo-client';

import { useFormikContext } from 'formik';


const propTypes = {
}

const defaultProps = {

}

const ProductDealForm = (props) => {
  const formik = useFormikContext();

  console.log("Formik is", formik)

  const handleShopifyVariantSelection = (value, option) => {
    console.log("handleShopifyVariantSelection", value, option);
    formik.setValues({
      ...formik.values,
      shopifyVariantId: value,
      sku: option.data.sku,
      shopifyVariantTitle: option.data.displayName,
      shopifyImageUrl: option.data.imageUrl,
      shopifyProductId: option.data.productId,
      shopifyProductHandle: option.data.productHandle,
    })
  }

  return (
    <div>
      <div className="p4">
        <AppAutocomplete
          fullWidth
          label='Producto en Shopify'
          placeholder='Título, SKU, etc...'
          graphqlQuery={ SHOPIFY_VARIANTS_SEARCH }
          onSelection={ handleShopifyVariantSelection }
          itemValueAccessor="id"
          itemLabelAccessor="titleWithSku"
        />
      </div>

      <Stack direction='row' justifyContent='space-between'>
        {/* Readonly - Display from search*/}
        <AppTextField
          name="sku"
          label="SKU"
          disabled
          className='mh4 mv8 flex1'
          variant="outlined"
        />
        
        <AppTextField
          name="shopifyVariantId"
          label="ID de Variante"
          disabled
          className='mh4 mv8 flex1'
          variant="outlined"
        />

        {/* Not really required to be here but for visibility */}
        <AppTextField hidden name="shopifyVariantTitle" />
        <AppTextField hidden name="shopifyImageUrl" />
        <AppTextField hidden name="shopifyProductId" />
      </Stack>    

      <FormGroup>
        <FormControlLabel
          label="Habilitado"
          control={
            <Switch 
              name="enabled"
              checked={formik.values.enabled} 
              onChange={formik.handleChange}  
              onBlur={formik.handleBlur}
            />
          }
        />
      </FormGroup>
    </div> 
  )
};

ProductDealForm.propTypes = propTypes;
ProductDealForm.defaultProps = defaultProps;

export default ProductDealForm;