import React from "react";
import PropTypes from 'prop-types';
import { 
  Typography, Grid, 
  Button as MaterialButton, Divider, CircularProgress,
  Drawer, Card, CardContent, IconButton
} from "@mui/material";
import { ChevronLeft } from '@mui/icons-material';

import { useFormikContext } from 'formik';
import { Element, useEditor } from "@craftjs/core";

// User Blocks - These are the components that the user can drag and drop into the builder
import * as Blocks from 'components/craftjs/userBlocks';

const propTypes = {
  disabled: PropTypes.bool,
}

const defaultProps = {

}

export const Toolbox = (props) => {
  const drawerWidth = 250;
  const { isSubmitting, status, setStatus } = useFormikContext();

  const handleCloseToolbox = () => {
    setStatus({ ...status, toolboxOpen: false });
  }

  console.log("Toolbox", status);

  return (
    <Drawer
      id="toolbox-drawer"
      open={ status.toolboxOpen }
      anchor='left'
      variant='persistent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <div className="drawer-content">
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <div className="flex justify-end ph8 pv8">
              <IconButton onClick={ handleCloseToolbox }>
                <ChevronLeft />
              </IconButton>
            </div>

            <Divider/>
            
            <div className="toolbox-title-section pv16">
              <Typography>Arrastrar a la página</Typography>
              <div className="loader-container">
                { isSubmitting && <CircularProgress className="loader" size={24} /> }
              </div>
            </div>
          </Grid>

          <Grid item xs={12} className="mt8">
            <div className="wrapper">
              { props.disabled ? (
                <Typography color='GrayText'>
                  Save basic data first
                </Typography>
              ) : (
                <>
                  {/* Drag and drop references for UserBlocks */}
                  <UserBlock UserComponent={Blocks.UserHero} />
                  <UserBlock UserComponent={Blocks.UserRichTextDescription} />
                  <UserBlock UserComponent={Blocks.UserButton} />

                  <Divider className="mv12"/>

                  <UserBlock UserComponent={Blocks.UserBannerPrimary} />
                  <UserBlock UserComponent={Blocks.UserBannerSecondary} />
                  <UserBlock UserComponent={Blocks.UserBannerDuo} />
                  <UserBlock UserComponent={Blocks.UserBannerTrio} />
                  <UserBlock UserComponent={Blocks.UserBannerQuad} />
                  <UserBlock UserComponent={Blocks.UserBannerWithDescription} />

                  <Divider className="mv12"/>

                  <UserBlock UserComponent={Blocks.UserBoxesSection} title="Boxes" initialProps={{ itemsCount: 6 }} />
                  <UserBlock UserComponent={Blocks.UserCollectionWithItems} />
                  <UserBlock UserComponent={Blocks.UserCategoriesSection} title="Categorias x4" initialProps={{ itemsCount: 4 }} />
                  <UserBlock UserComponent={Blocks.UserCategoriesSection} title="Categorias x6" initialProps={{ itemsCount: 6 }} />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  )
};

const UserBlock = ({ UserComponent, title, initialProps = {} }) => {
  const { connectors } = useEditor();

  const displayName = title || UserComponent.craft.related.name();

  return(
    <MaterialButton fullWidth variant="contained" ref={ref => connectors.create(ref, <UserComponent {...initialProps} />)} className="mb8">
      { displayName }
    </MaterialButton>
  )
}

Toolbox.propTypes = propTypes;
Toolbox.defaultProps = defaultProps;

export default Toolbox;