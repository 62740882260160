import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import ImageDropZone from 'components/shared/form/ImageDropzone';
import useImageAppPageAsset from './useImageAppPageAsset';

import { useFormikContext } from 'formik';
import { useNode } from "@craftjs/core";

import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';

import ResourceUrlInput from './ResourceUrlInput';

const propTypes = {
  // Prop names to write and read
  imageUrlKey: PropTypes.string.isRequired, // Banner IMAGE url. i.e. desktopBanner.imageUrl
  resourceUrlKey: PropTypes.string, // Banner resource url. i.e. desktopBanner.resourceUrl => The URL to link to when clicking the banner
  resourceIdKey: PropTypes.string, // Banner resource ID. i.e. desktopBanner.resourceId => The ID of the resource to link to when clicking the banner (Shopify URL), used mostly on mobile clients

  imageSettings: PropTypes.shape({
    sequence: PropTypes.number,
    display_type: PropTypes.string,
  }),

  // title/description keys are optional. If not present, the respective fields will not be displayed
  titleKey: PropTypes.string,
  descriptionKey: PropTypes.string,
  // Do display dimensions for banner. NOT yet validated. Default to most common size
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  withUrl: PropTypes.bool, // Most banners will have a URL, certain cases like desktop and mobile banners may not have 2 URLs and just use the same URL
}

const defaultProps = {
  imageWidth: 100,
  imageHeight: 100,
  imageSettings: {
    display_type: "GENERAL",
  },
  withUrl: true,
}

const SettingsBannerWithDescription = (props) => {
  const { imageUrlKey, imageSettings, titleKey, descriptionKey } = props;

  const { actions: {setProp}, editableProps } = useNode((node) => ({
    editableProps: {
      // image is handled by useImageAppPageAsset
      bannerTitle: lodashGet(node.data.props, titleKey),
      bannerDescription: lodashGet(node.data.props, descriptionKey),
      resourceUrl: lodashGet(node.data.props, props.resourceUrlKey),
      resourceId: lodashGet(node.data.props, props.resourceIdKey),
    }
  }));

  const [{ value: bannerUrl, loading }, { handleImageChange, handleImageDelete }] = useImageAppPageAsset(imageUrlKey, imageSettings);

  const handleResourceUrlChange = (value) => {
    setProp(itemProps => lodashSet(itemProps, props.resourceUrlKey, value));
  }

  const handleResourceIdChange = (value) => {
    setProp(itemProps => lodashSet(itemProps, props.resourceIdKey, value));
  }

  return (
    <div className="settings-banner-with-description">
      <ImageDropZone
        label={ `${props.imageWidth}x${props.imageHeight}` }
        onChange={handleImageChange}
        onDelete={handleImageDelete}
        imageUrl={bannerUrl}
        loading={loading}
      />

      { props.withUrl &&
        <ResourceUrlInput
          loading={loading}
          resourceUrl={editableProps.resourceUrl}
          resourceId={editableProps.resourceId}
          onResourceUrlChange={handleResourceUrlChange}
          onResourceIdChange={handleResourceIdChange}
        />
      }

      { titleKey &&
        <TextField
          fullWidth
          label="Title"
          disabled={ loading }
          value={editableProps.bannerTitle}
          onChange={e => setProp(itemProps => lodashSet(itemProps, titleKey, e.target.value))}
          variant='outlined'
        />
      }

      {/* Description is optional. Item can have only one text value (title) */}
      { !!descriptionKey &&
        <TextField
          fullWidth
          disabled={ loading }
          multiline
          label="Description"
          value={editableProps.bannerDescription}
          onChange={e => setProp(itemProps => lodashSet(itemProps, descriptionKey, e.target.value))}
          variant='outlined'
        />
      }
    </div>
  )
};

SettingsBannerWithDescription.propTypes = propTypes;
SettingsBannerWithDescription.defaultProps = defaultProps;

export default SettingsBannerWithDescription;