import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNode } from '@craftjs/core';
import { useFormikContext } from 'formik';

import { Grid, FormLabel, Divider, TextField } from '@mui/material';

import PreviewEditableTitleAndDescription from './components/PreviewEditableTitleAndDescription';
import ResourceUrlInput from './components/ResourceUrlInput';

import * as SHAPES from './types';
import lodashSet from 'lodash/set';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      resourceId: PropTypes.string.isRequired,
      resourceUrl: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  itemsCount: PropTypes.number.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

const defaultProps = {
  items: [],
  itemsCount: 6, // Currently un-editable, byt could be in the future
  textColor: "#fff",
  backgroundColor: "#000",
}

const defaultItem = {
  title: "Title",
  resourceId: "",
  resourceUrl: "",
}

const BoxesSection = (props) => {
  const [editable, setEditable] = useState(false);
  const { status } = useFormikContext();

  const { items, itemsCount, textColor, backgroundColor, ...builderProps } = props;
  
  const { connectors: { connect, drag }, selected, actions: { setProp } } = useNode((state) => ({
    selected: state.events.selected,
  }));

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  useEffect(() => {
    if(items.length != itemsCount){
      setProp(props => {
        props.items = Array.from({ length: itemsCount }, (_, index) => defaultItem);
      });
    }
  }, [itemsCount]);

  const gridItemSize = status.viewport === "DESKTOP" ? 2 : 6;

  const handleLinkClick = (e) => event.preventDefault();

  return (
    <div
      className="user-block user-boxes-section"
      ref={ref => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
      {...builderProps}
    >
      <Grid container spacing={3} justifyContent="space-between">
        { items.map((item, index) => {
          const valuesPrefix = `items.${index}`; // Collection of items. i.e. items.0.title,
          return(
            <Grid item xs={gridItemSize} key={index} style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <a 
                  href={ item.resourceUrl } 
                  className='item-container box-item' 
                  style={{ color: textColor, backgroundColor: backgroundColor }}
                  onClick={handleLinkClick}
                >
                  <PreviewEditableTitleAndDescription
                    values={{
                      items: items
                    }}
                    titleKey={`${valuesPrefix}.title`}
                    editable={editable}
                  />
                </a>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
};

const BoxesSectionSettings = () => {
  const { id: nodeId, actions: { setProp }, editableProps } = useNode((node) => ({
    editableProps: node.data.props,
  }));

  return(
    <>
      <TextField 
        fullWidth
        label="Color de Texto"
        value={editableProps.textColor}
        onChange={e => setProp(props => props.textColor = e.target.value)}
        variant="outlined"
      />

      <TextField
        fullWidth
        label="Color de Fondo"
        value={editableProps.backgroundColor}
        onChange={e => setProp(props => props.backgroundColor = e.target.value)}
        variant="outlined"
      />

      <Divider />
      { editableProps.items.map((item, index) => {
        const itemPrefix = `items.${index}`; // items[].title, items[].imageUrl
        const itemValues = editableProps.items[index];
        return(
          <React.Fragment key={index}>
            <FormLabel>
              Item {index + 1}
            </FormLabel>
            <TextField
              fullWidth
              label="Title"
              value={ itemValues.title }
              onChange={e => setProp(itemProps => lodashSet(itemProps, `${itemPrefix}.title`, e.target.value))}
              variant='outlined'
            />

            <ResourceUrlInput 
              resourceUrl={itemValues.resourceUrl}
              resourceId={itemValues.resourceId}
              onResourceUrlChange={(value) => setProp(itemProps => lodashSet(itemProps, `${itemPrefix}.resourceUrl`, value))}
              onResourceIdChange={(value) => setProp(itemProps => lodashSet(itemProps, `${itemPrefix}.resourceId`, value))}
            />
            <Divider />
          </React.Fragment>
        )
      })}
    </>
  )
};

BoxesSection.craft = {
  props: defaultProps,
  related: {
    name: () => "Categorías",
    settings: BoxesSectionSettings
  }  
}

BoxesSection.propTypes = propTypes;
BoxesSection.defaultProps = defaultProps;

export default BoxesSection;