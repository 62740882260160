import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextField, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'utils/axios-rails';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const PricingRuleProductLookup = (props) => {
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState("");
  const [error, setError] = useState("");

  const [result, setResult] = useState(null);

  const handleChange = useCallback( (event) => setProductId(event.target.value), [setProductId] );

  const handleLookup = async () => {
    setLoading(true);
    setError("");
    setResult(null);

    try {
      const { data } = await axios.get(props.submitURL, { params: { product_id: productId } });
      console.log("D", data);
      if(!data.error){
        setResult(data);
      }
      else{
        setError(data.error);
      }
    } catch (error) {
      console.error("E", error);
      alert("Ocurrio un error de conexión, revise los datos e intente nuevamente")
    }
    setLoading(false);
  }

  const canSubmit = !!productId && !loading;
  console.log("R", result)

  return (
    <div>
      <div className="flex justify-between">
        <Typography color="GrayText" className='mb12'>
          Busqueda de productos
        </Typography>

        <CircularProgress size={24} hidden={ !loading } />
      </div>

      <TextField
        fullWidth
        error={!!error}
        helperText={ error }

        label="ID del producto"
        name="markup_value"
        className='mh4'
        variant="outlined"
        type="number"
        
        value={ productId }
        onChange={ handleChange }
      />

      <Button disabled={ !canSubmit } className='mt8' variant="contained" fullWidth onClick={ handleLookup }>
        Buscar
      </Button>

      { !!result && (
        <div className='mt8'>
          <Typography color="GrayText">
            ID regla: { result?.pricing_rule?.id }
          </Typography>
          <Typography color="GrayText">
            Tipo regla: { result?.pricing_rule?.kind }
          </Typography>
        </div>
      )}
    </div>
  )
};

PricingRuleProductLookup.propTypes = propTypes;
PricingRuleProductLookup.defaultProps = defaultProps;

export default PricingRuleProductLookup;