import React from 'react';
import ReactDOM from "react-dom";
import NotificationMessage from '../components/notification-message';
// importing directly is basically the same as
// const NotificationMessage = ReactRailsUJS.getConstructor("NotificationMessage");

const AlertSupport = {
	render(message, style) {
		const $domElem = $(`<div></div>`);
		$domElem.appendTo("body");
		ReactDOM.render(<NotificationMessage style={style} message={message} />, $domElem[0]);

		setTimeout(function(){
			ReactDOM.unmountComponentAtNode($domElem[0]);
		}, 5000);
	}
}

export default AlertSupport;
