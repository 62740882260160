import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextField, FormControl, Button, Modal } from '@mui/material';

import AppModalContent from 'components/shared/AppModalContent';
import { isMarkActive } from '../functions/actions';

import { Editor } from 'slate'
import { useSlate } from 'slate-react'

const propTypes = {

}

const defaultProps = {

}

const markName = "link";

/**
 * @type React.ForwardRefRenderFunction<React.FunctionComponent, propTypes>
 */
const LinkEditModal = forwardRef( (props, ref) => {
  const editor = useSlate();
  const [url, setUrl] = useState('');

  const [open, setOpen] = useState(false);
  
  const openModal = useCallback( () => setOpen(true), []);
  const closeModal = useCallback( () => setOpen(false), []);

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
    // isOpen: open, Doesn't send value inmediately after updates and cause issues
  }), []);

  // Add or update mark on selection
  const handleSave = () => {
    Editor.addMark(editor, markName, url); // Add mark on selection
    closeModal();
  };

  const handleDelete = () => {
    Editor.removeMark(editor, markName); // Remove mark on selection
    closeModal();
  }

  useEffect(() => {
    if(open){
      const marks = Editor.marks(editor);
      const linkMarkValue = marks[markName];
      if(!!linkMarkValue){
        setUrl(linkMarkValue);
      }
    }
    else{
      setUrl('');
    }
  }, [open]);

  const deletable = open && isMarkActive(editor, markName);

  return (
    <Modal
      open={ open }
      onClose={ closeModal }
    >
      <div>
        <AppModalContent
          title={ "Manage Link" }
          onClose={ closeModal }
          footer={
            <div className='flex justify-between width-full'>
              { deletable &&
                <Button
                  color='error'
                  variant='contained'
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              }
              <div className='flex justify-end flex1'>
                <Button
                  variant='text'
                  className='mr8'
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleSave}
                  disabled={!url}
                >
                  Save
                </Button>
              </div>
            </div>
          }
          modalStyle={{
            minWidth: 620,
          }}
        >
          <div>
            <TextField
              fullWidth
              className='mb8'
              placeholder='https://example.com'
              label="Link to"
              variant="outlined"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
        </AppModalContent>
      </div>
    </Modal>
  )
});

LinkEditModal.propTypes = propTypes;
LinkEditModal.defaultProps = defaultProps;

export default LinkEditModal;