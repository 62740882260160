import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification } from 'utils';
import AppNotification from 'components/shared/AppNotification';

import { useMutation, gql } from '@apollo/client';
import adminClient, { userErrorsToFormik } from 'api/apollo-client';

import Page from 'shared/AppPage';

import { 
  Card, CardContent, Container,
  Button, Typography, LinearProgress,
} from '@mui/material';


import TagsInput from 'react-tagsinput';
import { useFormik } from 'formik';

import isEmpty from 'lodash/isEmpty';

const propTypes = {

}

const defaultProps = {

}

const SyncMetaobjects = (props) => {
  const [sendNotification, notificationProps] = useMuiNotification();

  const [submitSync, { loading }] = useMutation(SYNC_METAOBJECTS, {
    client: adminClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const success = data.toolsSyncMetaobjects.success;
      if (success) {
        sendNotification({ message: 'Los valores se sincronizaran en breve', severity: 'success' });
      } 
      else {
        formik.setErrors(userErrorsToFormik(data.toolsSyncMetaobjects.errors));
        sendNotification({ message: 'Ocurrio un error', severity: 'error' });
      }
    },
    onError: (error) => {
      sendNotification({ message: 'Error de conexión', severity: 'error' });
    }
  });

  const formik = useFormik({
    initialValues: {
      identifiers: [],
      metaobjectType: 'SUPPLIER', // Only type supported for now on UI
    },
    onSubmit: (values) => {
      submitSync({ 
        variables: {
          input: {
            identifiers: values.identifiers, 
            metaobjectType: values.metaobjectType 
          }
        } 
      });
    }
  });

  return (
    <Page>
      <AppNotification {...notificationProps} />

      <Container>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Sync Metaobjects
            </Typography>

            <Typography color='GrayText' fontSize={14}>
              Ingrese los IDs de Brightpearl que desea sincronizar como Metaobjects de Shopify (crear/actualizar).
            </Typography>

            <form className='mt12' onSubmit={ formik.handleSubmit }>
              <TagsInput
                value={ formik.values.identifiers }
                onlyUnique
                addKeys={[188, 9]} // Comma, Tab
                inputProps={{
                  placeholder: "Agregar ID",
                }}
                addOnPaste
                pasteSplit={
                  (data) => {
                    return data.split(',').map(d => d.trim())
                }}
                onChange={ (tagsList) => formik.setFieldValue("identifiers", tagsList)}
                className='react-tagsinput'
              />
              <Typography color='GrayText' fontSize={12}>
                Ingrese los IDs de Brightpearl Supplier separados por coma
              </Typography>

              { !isEmpty(formik.errors) &&
                <ul className='mt12 text-error'>
                  { Object.values(formik.errors).map((error, index) => (
                    <li key={index}>
                      <Typography fontSize={12}>{error}</Typography>
                    </li>
                  ))}
                </ul>
              }

              <Button 
                type='submit' 
                variant='contained' 
                color='primary' 
                disabled={loading || isEmpty(formik.values.identifiers)}
                className='mt12'
              >
                Sincronizar
              </Button>
            </form>
          </CardContent>
          
        </Card>
        { loading && <LinearProgress color='primary' /> }
      </Container>
    </Page>
  )
};

SyncMetaobjects.propTypes = propTypes;
SyncMetaobjects.defaultProps = defaultProps;

const SYNC_METAOBJECTS = gql`
  mutation($input: SyncMetaobjectsInput!) {
    toolsSyncMetaobjects(input: $input) {
      success
      errors{
        field
        message
      }
    }
  }
`;

export default SyncMetaobjects;