import axios from 'axios';

const csrfToken = document.querySelector("meta[name=csrf-token]").content;

const axiosRails = axios.create({});

const setCsrfToken = (csrfToken) => {
  axiosRails.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  axios.defaults.headers.common['Accept'] = 'application/json';
}

setCsrfToken(csrfToken);

export default axiosRails;