import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { shapeProps } from 'utils';
import { Select, FormControl, MenuItem, InputLabel, ListItemText, Checkbox, FormHelperText } from '@mui/material';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value:  PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]), // Provide value when this is a controlled component, if there is no need to set value outside of this component then just use onSelect callback
  placeholder: PropTypes.string, // for multiple

  options: shapeProps.selectOptionsProp.isRequired,

  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array,]),
  onSelect: PropTypes.func.isRequired,
  containerClass: PropTypes.string,

  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  errorTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
}

const defaultProps = {
  containerClass: null,
  label: null,
  placeholder: "Seleccione...",
  errorTextProps: {},
}

const DropdownSelect = (props) => {
  const { name, label, value, options, initialValue, onSelect, containerClass,
          errorText, errorTextProps,
          fullWidth, // Goes into FormControl instead of Select
          ...selectProps } = props;
  const [selected, setSelected] = useState(initialValue);

  const handleSelectChange = useCallback((event) => {
    const newValue = event.target.value;
    const option = props.options.find(option => option.value === newValue);
    setSelected(newValue);
    props.onSelect(props.name, newValue, option);
  }, [props.options]);


  // When using multiple with checkbox display labels on Select
  const multipleRenderLabels = useCallback((values) => {
    const selectedItems = props.options.filter(option => values.includes(option.value));
    if(selectedItems.length > 0){
      return selectedItems.map( (opt) => opt.label ).join(', ');
    }
    else{
      return <em>{ props.placeholder }</em>
    }
  }, [props.options]);

  const selectedOptions = value || selected;

  return (
    <FormControl className={ containerClass } size="small" fullWidth={ fullWidth }>
      { !!label &&
        <InputLabel>{ label }</InputLabel>
      }
      <Select
        name={ name }
        value={ selectedOptions }
        onChange={ handleSelectChange }
        inputProps={{ 'aria-label': 'Without label' }}
        label={ label }
        error={ !!errorText }

        autoWidth
        renderValue={ selectProps.multiple ? multipleRenderLabels : null }
        { ...selectProps }
      >
        { props.multiple &&
          <MenuItem value="" disabled><em>{ props.placeholder }</em></MenuItem>
        }
        { options.map(option => 
          <MenuItem key={ option.value } value={ option.value }>
            { selectProps.multiple && 
              <Checkbox checked={ selectedOptions.includes(option.value) } />
            }
            <ListItemText primary={ option.label } />
          </MenuItem>
        )}
      </Select>
      { errorText && <FormHelperText {...errorTextProps} error>{ errorText }</FormHelperText> }
    </FormControl>
  );
};

DropdownSelect.propTypes = propTypes;
DropdownSelect.defaultProps = defaultProps;

export default DropdownSelect;