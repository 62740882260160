import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.number.isRequired, // Tabs active value
  index: PropTypes.number.isRequired, // THIS Tab active value/number

}

const defaultProps = {

}

const TabPanel = (props) => {
  return (
    <div
      role="tabpanel"
      hidden={ props.value !== props.index}
    >
      { props.value === props.index && ( 
        <div className='mt12'>
          { props.children } 
        </div>
      )}
    </div>
  )
};

TabPanel.propTypes = propTypes;
TabPanel.defaultProps = defaultProps;

export default TabPanel;