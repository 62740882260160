import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types';

import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'

import { Button, ToggleButtonGroup, ToggleButton, Icon, Toolbar } from '@mui/material';
import { 
  FormatBold, FormatItalic, FormatUnderlined, Code, FormatQuote,
  LooksOne, LooksTwo, 
  FormatListBulleted, FormatListNumbered,
  FormatAlignLeft, FormatAlignCenter, FormatAlignRight, FormatAlignJustify,
  InsertLink,
} from '@mui/icons-material';

import { 
  toggleBlock, toggleMark, isMarkActive, isBlockActive, 
  TEXT_ALIGN_TYPES, ELEMENT_TYPES, LEAF_TYPES
} from './functions/actions';

import { serializeToHtml } from './functions/serializerHtml';

import EditorToggleButton from './editorComponents/EditorToggleButton';
import LinkEditModal from './editorComponents/LinkEditModal';
import ElementItem from './Element';
import LeafItem from './Leaf';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  // 'mod+`': 'code',
};

// const initialValue: Descendant[] = []
const DEMO_INITIAL_VALUE = [
  {
    type: 'paragraph',
    children: [
      { text: 'This is editable ' },
      { text: 'rich', bold: true },
      { text: ' text, ' },
      { text: 'much', italic: true },
      { text: ' better than a ck' },
      // { text: '<textarea>', code: true },
      // { text: '!' },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: "Since it's rich text, you can do things like turn a selection of text ",
      },
      { text: 'bold', bold: true },
      {
        text: ', or add a semantically rendered block quote in the middle of the page, like this:',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [{ text: 'Try it out for yourself!' }],
  },
];

const propTypes = {
  initialValue: PropTypes.arrayOf( // Example of basic initialValue
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(ELEMENT_TYPES)),
      align: PropTypes.oneOf(TEXT_ALIGN_TYPES),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          bold: PropTypes.bool,
          italic: PropTypes.bool,
          underline: PropTypes.bool,
          code: PropTypes.bool,
          link: PropTypes.string,
        })
      ),
    })
  ),
  onChange: PropTypes.func.isRequired,
  devPreview: PropTypes.bool,
}

const defaultProps = {
  devPreview: false,
  initialValue: DEMO_INITIAL_VALUE,
}

const SlateRichTextEditor = (props) => {
  const linkModalRef = useRef(null);
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const [preview, setPreview] = React.useState("");

  const handleLinkMarkMouseDown = (event, editor) => {
    event.preventDefault();
    if(!editor.selection){
      return false;
    }
    linkModalRef?.current?.open(); // Modal will handle transformation
  };

  const handleChange = (value) => {
    const isAstChange = editor.operations.some( // Check if fired by any operation other than selection (i.e. "insert_text", "remove_text", "set_node", etc.)
      op => 'set_selection' !== op.type
    );
    if (isAstChange) {
      props.onChange(value);
      if(props.devPreview){
        const htmlString = serializeToHtml(value);
        setPreview(htmlString);
      }
    }
  }

  return (
    <Slate 
      editor={editor} 
      onChange={handleChange}
      initialValue={props.initialValue}
    >
      <ToggleButtonGroup className='MuiToggleButtonGroup-root flex flex-wrap'>
        <MarkButton format="bold" icon={<FormatBold />} />
        <MarkButton format="italic" icon={<FormatItalic />} />
        <MarkButton format="underline" icon={<FormatUnderlined />} />
        <MarkButton format="link" icon={<InsertLink />} onAction={handleLinkMarkMouseDown} />
        {/* <MarkButton format="code" icon={<Code />} /> */}
        {/* <BlockButton format="heading-one" icon={<LooksOne />} /> */}
        {/* <BlockButton format="heading-two" icon={<LooksTwo />} /> */}
        {/* <BlockButton format="block-quote" icon={<FormatQuote />} /> */}
        <BlockButton format="numbered-list" icon={<FormatListNumbered />} />
        <BlockButton format="bulleted-list" icon={<FormatListBulleted />} />
        <BlockButton format="left" icon={<FormatAlignLeft />} />
        <BlockButton format="center" icon={<FormatAlignCenter />} />
        <BlockButton format="right" icon={<FormatAlignRight />} />
        {/* <BlockButton format="justify" icon={<FormatAlignJustify />} /> */}
      </ToggleButtonGroup>
      <div className='slate-editor-container'>
        <Editable
          renderElement={ElementItem}
          renderLeaf={LeafItem}
          placeholder="Enter some rich text…"
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </div>

      { props.devPreview && (
        <div dangerouslySetInnerHTML={{ __html: preview }} />
      )}
      
      <LinkEditModal ref={linkModalRef} />
    </Slate>
  )
}

// Button to add HTML elements
const BlockButton = ({ format, icon, onAction }) => {
  const editor = useSlate()

  const defaultHandleAction = (event) => {
    event.preventDefault()
    toggleBlock(editor, format)
  };

  const handleOnMouseDown = !!onAction ? (event) => onAction(event, editor) : defaultHandleAction;

  return (
    <EditorToggleButton
      value={format}
      icon={icon}
      selected={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={handleOnMouseDown}
    />
  )
};

// Button to add text formatting aka "marks/leafs"
const MarkButton = ({ format, icon, onAction }) => {
  const editor = useSlate();

  const defaultHandleAction = (event) => {
    event.preventDefault()
    toggleMark(editor, format)
  };

  const handleOnMouseDown = !!onAction ? (event) => onAction(event, editor) : defaultHandleAction;

  return (
    <EditorToggleButton
      value={format}
      icon={icon}
      selected={isMarkActive(editor, format)}
      onMouseDown={handleOnMouseDown}
    />
  )
}

SlateRichTextEditor.propTypes = propTypes;
SlateRichTextEditor.defaultProps = defaultProps;

export default SlateRichTextEditor;