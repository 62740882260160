import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import AppInput from 'shared/AppInput';

const propTypes = {
  values: PropTypes.shape({
    national_id: PropTypes.string,
    id_type: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    national_id: PropTypes.array,
    id_type: PropTypes.array,
    amount: PropTypes.array,
  }),
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

const defaultProps = {

}

const idTypes = [
  { value: "ordinary", label: "Cédula Física" },
  { value: "company", label: "Cédula Jurídica" },
  { value: "dimex", label: "DIMEX" },
]

const SinpeForm = (props) => {
  // Remove hyphens
  const customHandleChange = (event) => {
    const parsedValue = event.target.value && event.target.value.replace("-", "");
    props.setFieldValue("national_id", parsedValue);
  }

  const currentIdType = idTypes.find(( item ) => item.value == props.values.id_type);

  return (
    <div>
      <Form.Group>
        <Form.Label className="font-weight-bold">Tipo de Cédula</Form.Label>
        <Form.Control onChange={ props.handleChange } name="id_type"  className="gateway-dropdown" as="select" custom>
          { idTypes.map( (option, index) => 
            <option key={ option.value } value={ option.value }>{ option.label }</option>
          )}
        </Form.Control>
      </Form.Group>
  
      <AppInput 
        label="Número de Cédula" name="national_id" 
        labelClassName="font-weight-bold"
        value={ props.values.national_id } onChange={ customHandleChange }
        placeholder="123456789" error={ props.errors && props.errors.national_id }
      />
      <Form.Text muted>
        { currentIdType.instructions || "Debe ser la cédula del titular de la cuenta." }
      </Form.Text>
    </div>
  )
};

SinpeForm.propTypes = propTypes;
SinpeForm.defaultProps = defaultProps;

export default SinpeForm;