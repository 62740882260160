import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as shapeProps from 'utils/shape-props';

// Builder components
import Toolbox from 'components/craftjs/builder/Toolbox'; // Available drag/drop components to build
import SettingsPanel from 'components/craftjs/builder/SettingsPanel'; // Selected component settings
import ViewportSettings from 'components/craftjs/builder/ViewportSettings'; // Viewport settings

// User blocks. 
// IMPORTANT: The import names are saved as "resolvedName" in the JSON content.
import * as Blocks from 'components/craftjs/userBlocks';

import {Editor, Frame, Element} from "@craftjs/core";
import { useFormik, FormikProvider } from 'formik';

// Notifications
import { useMuiNotification } from 'utils';
import AppNotification from 'components/shared/AppNotification';

// Apollo
import { useLazyQuery, useMutation, ApolloProvider } from '@apollo/client';
import apolloClient, { userErrorsToFormik, APP_PAGE_GET, APP_PAGE_CREATE, APP_PAGE_UPDATE } from 'api/apollo-client';

// Utils
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import lodashGet from 'lodash/get';
import pako from "pako";

import { Typography, Grid, Button, Card, CardContent, Container, Skeleton, Alert, LinearProgress, Paper } from '@mui/material';
import PageContainer from 'components/shared/AppPage';
import BuilderActions from './components/BuilderActions';
import AppPageBasicForm from './components/AppPageBasicForm';

const propTypes = {
  id: PropTypes.string, // If present, it will fetch the record
  builderURLBase: PropTypes.string.isRequired, // To redirect to edit after creation
  breadcrumbs: PropTypes.array.isRequired,
  kinds: shapeProps.selectOptionsProp.isRequired,
}

const defaultProps = {

}

const createInitialValues = {
  contentDraft: "", kind: "PAGE",
  shopifyTitle: "", shopifyHandle: "", shopifyResourceGid: "",
  publishNow: false, // Flag to publish the record after saving
  publishable: false, // Flag to enable/disable publish button
  // kindResourceType: "PAGE", Unused
}

const inputWhitelistFields = Object.keys(omit(createInitialValues, ["publishable"])); // Fields send on input when saving. List of field keys

const AppPageBuilder = (props) => {
  const [sendNotification, notificationProps] = useMuiNotification();
  const [initialValues, setInitialValues] = useState(null);
  const [initialEditorJSON, setInitialEditorJSON] = useState(undefined);

  // Decode base64 and deflate the JSON with pako. API can handle the decompression but let's do it here
  // Compression logic is inside BuilderActions.js
  const pakoDecompress = (compressedBase64) => {
    const compressedData = Buffer.from(compressedBase64, 'base64');
    const decompressedData = pako.inflate(compressedData, { to: 'string' }); // The `decompressedData` variable now contains the original JSON string before compression and encoding
    console.log("Decompressed", decompressedData);
    return decompressedData;
  }

  // Apollo stuff
  const [getAppPage, { loading: getLoading }] = useLazyQuery(APP_PAGE_GET, { // Only if ID is present. - Used onLoad to edit and after update
    onCompleted: (data) => {
      console.log("getAppPage completed", data);
      setInitialValues(data.appPage);
      setInitialEditorJSON(pakoDecompress(data.appPage.contentLatest)); // Get latest content as initial value, on save always save to draft by default.
    },
    fetchPolicy: 'network-only',
  });

  const [createRecord, { error }] = useMutation(APP_PAGE_CREATE);

  const [updateRecord, { error: updateError }] = useMutation(APP_PAGE_UPDATE);

  const handleSave = async (values, formikBag) => {
    try {
      const operationInfo = {
        create: { successPath: ["appPageCreate", "appPage", "id"], errorsPath: ["appPageCreate", "errors"]},
        update: { successPath: ["appPageUpdate", "appPage", "id"], errorsPath: ["appPageUpdate", "errors"]},
      }
  
      const inputValues = pick(values, inputWhitelistFields);
      console.log("Saving", values, inputValues, inputWhitelistFields);
  
      let result, operation;
  
      if(!!values.id){ // Update
        console.log("Updating");
        result = await updateRecord({ variables: { id: values.id, input: inputValues } });
        operation = operationInfo.update;
      }
      else{
        console.log("Creation");
        result = await createRecord({ variables: { input: inputValues } });
        operation = operationInfo.create;
      }
  
      // Shared logic
      console.log("Result", result.data);
      const recordId = lodashGet(result.data, operation.successPath); // Check if the operation was successful (checking ID)
      if(!!recordId){
        sendNotification({ message: "Operación exitosa", severity: "success" });
        if(props.id){
          getAppPage({ variables: { id: recordId } }); // Update the form and keep editing
        }
        else{
          const editURL = props.builderURLBase.replace(":ID", recordId);
          window.location.href = editURL; // Redirect to edit to be able to reload the Page with the right URL
        }
      }
      else{
        const errors = lodashGet(result.data, operation.errorsPath);
        formikBag.setErrors(userErrorsToFormik(errors));
        sendNotification({ message: "Ocurrio un error, revise los datos.", severity: "error" });
      }
    } 
    catch (error) {
      console.error("Catched error saving", error);
      sendNotification({ message: "Ocurrio un error con el servidor", severity: "error" });  
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSave,
    initialStatus: {
      viewport: "DESKTOP", // Valid values are "DESKTOP" and "MOBILE"
      toolboxOpen: false,
    }
  });

  // Initial Fetch for Edit
  useEffect(() => {
    if(!!props.id){
      getAppPage({ variables: { id: props.id } }); // onCompleted will set the initial values. ui
    }
    else{
      setInitialValues(createInitialValues);
    }
  }, []);

  if(!formik.values){
    return (
      <Skeleton variant="rectangular" width="100%" height={400} />
    )
  }

  // console.log("Values", formik.values);

  const builderDisabled = !formik.values.id;
  // const pageHeader = props.id ? "Editar página" : "Crear página";

  const mobilePreview = formik.status.viewport == "MOBILE";

  return (
    <FormikProvider value={formik}>
      <AppNotification {...notificationProps} />
      <Editor 
        resolver={{
          ...Blocks,
        }}
      > 
        <PageContainer 
          title={ "Builder" } 
          container={false} 
          breadcrumbs={props.breadcrumbs}
          primaryAction={ <BuilderActions /> }
        >
          <Grid container spacing={3}>
            {/* Page basic information */}
            <Grid item xs={12}>
              { formik.isSubmitting && <LinearProgress />}
              <AppPageBasicForm kinds={props.kinds} />
            </Grid>

            {/* Alert to show requirements to use builder (a saved record with ID) */}
            { builderDisabled &&
              <Grid item xs={12}>
                <Alert severity="info">
                  Save basic data to be able to use the builder.
                </Alert>
              </Grid>
            }

            {/* BUILDER */}
            {/* Builder tools (left) */}
            <Grid item xs={12} md={ mobilePreview ? 4 : 3}>
              <Toolbox disabled={builderDisabled} />
              <div id="toolbox-and-settings">
                <ViewportSettings />
                <SettingsPanel />
              </div>
            </Grid>

            {/* Builder Preview/Canvas (right)*/}
            <Grid item xs={12} md={ mobilePreview ? 8 : 9 }>
              <Paper id="builderPreview" className={ mobilePreview ? "mobile-preview" : "desktop-preview" }>
                <div className='builder-wrapper'>
                  <Frame data={initialEditorJSON}>
                    {/* UserContainer is the "Canvas", parent element of all blocks. It provides the "draggable reception" */}
                    <Element is={Blocks.UserContainer} padding={16} background="#fff" canvas />
                  </Frame>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </PageContainer>
      </Editor>
    </FormikProvider>
  );
};

// As apollo must be at the top level, we need to wrap the component.
const AppPageBuilderWithApollo = (props) => {
  return (
    <ApolloProvider client={apolloClient}>
      <AppPageBuilder {...props} />
    </ApolloProvider>
  )
}

AppPageBuilder.propTypes = propTypes;
AppPageBuilder.defaultProps = defaultProps;

export default AppPageBuilderWithApollo;