// New way from 2024 onward to manage UI queries inside the App
import { ApolloClient, InMemoryCache, gql, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
export * from './queries/index';
export * from './queries/contentManagerQueries';
export {
  gql
}
// Function to get the dynamic admin token
const getAdminToken = () => {
  const element = document.querySelector('[data-admin-token]');
  return element ? element.getAttribute('data-admin-token') : null;
};

// Error link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

// Auth link to set the admin token on each request
const authLink = setContext(() => {
  const adminToken = getAdminToken();
  return {
    headers: {
      Authentication: adminToken ? `Bearer ${adminToken}` : ''
    }
  };
});

const httpLink = new HttpLink({ 
  uri: '/admin/graphql',
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

// UTILS FUNCTIONS EXPORTED

// Parse errors from GraphQL userError to formik errors
export const userErrorsToFormik = (errors) => {
  const fieldErrors = {};
  for (const error of errors) {
    fieldErrors[error.field] = error.message;
  }

  return fieldErrors;
};

export default client;