import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import { useFormik } from 'formik';

import AppInput from 'shared/AppInput';
import AppToast from 'shared/AppToast';
import SinpeForm from 'components/views/orders/components/SinpeForm';

import axios from 'utils/axios-rails';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const SinpeManualLink = (props) => {
  const [toastMessage, setToastMessage] = useState(null);
  
  const closeToast = () => setToastMessage(null);

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(props.submitURL, {
        national_id: values.id_type == "ordinary" ? `0${values.national_id}` : values.national_id, // On CR it is WIDELY common for people with Cedula Física to omit writing the 0 at the beginning, so it that's the case AUTO add it there, for other kinds ID should be send as written
        amount: values.amount,
        shopify_id: values.shopify_id
      });
      const { data } = response;
      formik.resetForm();
      setToastMessage(data.message);
    } catch (error) {
      if(error.response.data){
        formik.setErrors(error.response.data.errors);
        setToastMessage(error.response.data.message);
      }
      else{
        setToastMessage("Ocurrio un error de conexión con el servicio.");
      }
    }
  }

  const formik = useFormik({ 
    initialValues: { national_id: "", id_type: "ordinary", amount: 0, shopify_id: "" },
    onSubmit: handleSubmit,
  });
  
  return (
    <Container className="pv40">
      <Form onSubmit={ formik.handleSubmit } style={{ position: "relative" }}>
        <AppToast
          title="Información"
          message={ toastMessage }
          handleClose={ closeToast }
        />
  
        <SinpeForm
          values={ formik.values }
          errors={ formik.errors }
          handleChange={ formik.handleChange }
          setFieldValue={ formik.setFieldValue }
        />

        <AppInput 
          label="Monto" name="amount" 
          labelClassName="font-weight-bold" type="number"
          value={ formik.values.amount } onChange={ formik.handleChange }
          placeholder="1000" error={ formik.errors && formik.errors.amount }
        />

        <AppInput 
          label="ID Shopify" name="shopify_id" 
          labelClassName="font-weight-bold"
          value={ formik.values.shopify_id } onChange={ formik.handleChange }
          placeholder="20000000" error={ formik.errors && formik.errors.shopify_id }
        />

        <Button block className="mt28 pv16 ph12 fs14 border-radius-2" variant="dark" type="submit" disabled={ formik.isSubmitting }>
          { formik.isSubmitting ? "PROCESANDO..." : "ENLAZAR" }
        </Button>
      </Form>
    </Container>
  )
};

SinpeManualLink.propTypes = propTypes;
SinpeManualLink.defaultProps = defaultProps;

export default SinpeManualLink;