import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,

  labelClassName: PropTypes.string,
  attachedText: PropTypes.string,
}

const defaultProps = {
  labelClassName: null,
  attachedText: null,
  type: "text",
}

const AppInput = ({ label, error, attachedText, labelClassName, ...inputProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const getInputType = (visible) => {
    if(inputProps.type === "password") {
      return visible ? "text" : "password";
    }
    else{
      return inputProps.type;
    }
  };

  const inputType = useMemo( () => getInputType(showPassword), [showPassword, inputProps.type] );

  let errorMessage = null;
  if(error){
    errorMessage = error.join(", ")
  }
  return (
    <React.Fragment>
      <Form.Label className={ labelClassName }>{ label }</Form.Label>
      <InputGroup>
        { attachedText && <InputGroup.Text style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} >{ attachedText }</InputGroup.Text> }
        <Form.Control 
          {...inputProps} 
          isInvalid={ !!error } 
          type={ inputType }
        />
        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
        { inputProps.type == "password" && 
          <Button variant="outline-secondary" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
            { showPassword ? <EyeFill onClick={ togglePasswordVisibility } /> : <EyeSlashFill onClick={ togglePasswordVisibility } /> }
          </Button>
        }
      </InputGroup>
    </React.Fragment>
  )
};

AppInput.propTypes = propTypes;
AppInput.defaultProps = defaultProps;

export default AppInput;