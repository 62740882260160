import PropTypes from 'prop-types';

export const formikProps = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,

  values: PropTypes.object.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,

  isValid: PropTypes.bool,
  loading: PropTypes.bool,
}

// export const formikFormProps = PropTypes.shape({
//   formikProps
// });

export const selectOptionsProp = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, ]), // can be null
    label: PropTypes.string.isRequired,
  })
);

const actionProperties = {
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  url: PropTypes.string,
};

export const actionShapeProp = PropTypes.shape(actionProperties);
export const primaryActionProp = PropTypes.shape({
  ...actionProperties,
  secondaryActions: PropTypes.arrayOf(actionShapeProp),
});