import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, TextField, InputAdornment, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';

import apolloClient from 'api/apollo-client';

const propTypes = {
  graphqlQuery: PropTypes.object, // Query to fetch options. Right now works only with ApolloClient to local admin GraphQL API (if not provided, filterOptions must be provided)

  onSelection: PropTypes.func.isRequired,
  onClear: PropTypes.func, // Callback when clearing search

  label: PropTypes.string, // unused?
  placeholder: PropTypes.string,

  itemLabelAccessor: PropTypes.string, // if different from 'label', i.e. "title", "name", etc
  itemValueAccessor: PropTypes.string, // if different from 'value', i.e. "id", "name", etc.
  filterOptions: PropTypes.array, // If gonna initialize options. For fixed options use this without graphqlQuery
  searchDelay: PropTypes.number, // Miliseconds
  variablesConstructor: PropTypes.func, // Function to construct variables for the query. Receives the search value and returns the variables object
}

const defaultProps = {
  itemLabelAccessor: "label",
  itemValueAccessor: "value",
  placeholder: "Buscar...",
  filterOptions: [],
  searchDelay: 1000,
}

// IMPORTANT: GQL data must return collection called "items" (can be an alias on the gql query)
const AutocompleteSearch = (props) => {
  const [selectedOption, setSelectedOption] = useState(null); // Selected options
  const [options, setOptions] = useState(props.filterOptions); // Dropdown options
  const [queryValue, setQueryValue] = useState('');
  const [loading, setLoading] = useState(false);

  // Clear options when changing placeholder or label
  useEffect(() => {
    setQueryValue('');
    setOptions(props.filterOptions);
  }, [props.placeholder, props.label]);

  const fetchItems = async (value) => {
    if(!loading){
      setLoading(true);
    }

    if(value == ""){ // Clear query
      !!props.graphqlQuery ? setOptions([]) : setOptions(props.filterOptions); // Back to initial options if using fixed preloaded options. If API, just clear options
      setLoading(false);
      return;
    }

    if (props.graphqlQuery){
      setSelectedOption(null); // Clear selection (if new query results doesn't match current selection we will get a warning)
      try {
        let variables;
        if(!!props.variablesConstructor){ // Custom variables constructor
          variables = props.variablesConstructor(value);
        }
        else{
          variables = { query: value };
        }
        const result = await apolloClient.query({ query: props.graphqlQuery, variables: variables });
        const { data } = result;
        if(data){ // Parse data to label/value items for autocomplete options.
          const parsedApiItems = data.items.map( (item) => ({ label: item[props.itemLabelAccessor], value: item[props.itemValueAccessor], data: item }) )
          setOptions(parsedApiItems);
        }
      } catch (error) {
        console.log(error);
        alert("Ocurrio un error de conexión.")
      }
    }else { // if no API just search among options
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = options.filter((option) =>
          option.label.match(filterRegex),
      );
      setOptions(resultOptions);
    }

    setLoading(false);
  }

  const fetchItemsDebounced = useCallback( debounce(fetchItems, props.searchDelay), [props.variablesConstructor]);

  // Update TextField value and debounce fetch API
  const handleQueryChange = (event) => {
    const value = event.target.value;
    setQueryValue(value);
    fetchItemsDebounced(value); // Debounce fetch API
  }

  const handleOptionChange = (event, selectedItem) => {
    console.log("Selected value", selectedItem)
    if(!selectedItem){ // If no selection, clear search
      clearSearch();
      return;
    }
    setSelectedOption(selectedItem);
    setQueryValue(selectedItem.label);

    props.onSelection(selectedItem.value, selectedItem); // Some options got extra data, else it will be just null
  }

  const clearSearch = () => {
    setQueryValue("");
    fetchItemsDebounced("");
    if(!!props.onClear){
      props.onClear();
    }
  }

  // value must be passed to inputProps, otherwise when updating the options the search value will be cleared by the Autocomplete as well (but it should remain)
  return (
    <Autocomplete
      value={ selectedOption }
      onChange={ handleOptionChange }
      loading={ loading }
      options={ options }
      renderInput={(params) => (
        <TextField 
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: loading ? <CircularProgress className='mh4' size={14} /> : params.InputProps.endAdornment,
          }}
          inputProps={{
            ...params.inputProps,
            value: queryValue,
          }}
          label={ props.label }
          placeholder={ props.placeholder }
          value={ queryValue } 
          onChange={ handleQueryChange } 
        />
      )}
    />
  );
};

AutocompleteSearch.propTypes = propTypes;
AutocompleteSearch.defaultProps = defaultProps;

export default AutocompleteSearch;