import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField, Button, Typography, CircularProgress, Card, CardActions } from '@mui/material';
import axios from 'utils/axios-rails';

import UploadButton from 'components/shared/form/UploadButton';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  onSendNotification: PropTypes.func.isRequired,
}

const defaultProps = {

}

const ManualPricingBatch = (props) => {
  const [importFile, setImportFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);
    setErrors([]);

    try {
      const formData = new FormData;
      formData.append("file", importFile);
      const { data } = await axios.post(props.submitURL, formData);
      console.log("D", data);
      if(data.success){
        props.onSendNotification({ message: "Corriendo flujo sobre productos", severity: "success" })
        setImportFile(null);
      }
      else{
        props.onSendNotification({ message: "Revise los errores e intente nuevamente", severity: "error" })
        setErrors(data.errors);
      }
    } catch (error) {
      console.error("E", error);
      props.onSendNotification({ message: "Ocurrio un error de conexión, revise los datos e intente nuevamente", severity: "error" })
    }
    setLoading(false);
  }

  return (
    <div>
      <div className="flex justify-between">
        <Typography color="GrayText" className='mb12'>
          Al subir el archivo se procesaran las reglas de precios para los productos que se encuentren en el mismo. 
          <br/>
          Este proceso es el mismo que se aplica en los intervalos establecidos cada día para todo el catálogo de productos.
          <br/>
          <strong>El archivo debe contener el header 'id' el cual corresponde al ID de los productos en Brightpearl</strong>
        </Typography>

        <CircularProgress size={24} hidden={ !loading } />
      </div>

      <div>
        <UploadButton 
          onUpload={ setImportFile }
          helpText="Solo se aceptan archivos .xlsx y .xls"
        />

        <Button 
          className='mt12'
          fullWidth variant="contained" 
          color='primary' 
          onClick={ handleSubmit } 
          disabled={ loading || !importFile }>
          Cargar
        </Button>
      </div>

      <CardActions>
        <Button
          href='/samples/manual-pricing-batch-sample.xlsx'
          color='info'
        >
          Descargar archivo de muestra
        </Button>
      </CardActions>

      { errors.map((error, index) => (
        <Typography key={ index } color="error">
          { error }
        </Typography>
      ))}
    </div>
  )
};

ManualPricingBatch.propTypes = propTypes;
ManualPricingBatch.defaultProps = defaultProps;

export default ManualPricingBatch;