import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  List, ListItem, ListItemText, ListItemAvatar, Avatar,
  FormControlLabel, 
  Button, IconButton, TextField, InputAdornment, Checkbox,
  Typography,
  Card, CardContent, CardHeader, 
  ToggleButtonGroup, ToggleButton,
  Tooltip,
  Box, Modal, FormHelperText
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import SaveIcon from '@mui/icons-material/Save';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useFormik } from 'formik';
// import { I18n } from 'i18n-js';


import DropdownSelect from 'shared/form/DropdownSelect';
import Autocomplete from 'components/shared/form/AppAutocomplete';

const propTypes = {
  pricingRule: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string.isRequired,
    // ...
  }).isRequired,

  onDelete: PropTypes.func.isRequired,
  onProductsExport: PropTypes.func.isRequired,

  productCategories: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

const defaultProps = {
  disabled: false,
  loading: false
}

const manualKinds = { brand: "by-brand", file: "by-sku-file" };

const PricingRuleItem = (props) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportEmail, setExportEmail] = useState('');
  const [manualKind, setUseManualFile] = useState( !!props.pricingRule?.manual_file_url ? manualKinds.file : manualKinds.brand ); 

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [brandUpdateFile, setBrandUpdateFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      ...props.pricingRule,
    },
    onSubmit: props.onSave,
    enableReinitialize: true,
  });

  const handleDelete = () => {
    if(confirm("¿Esta acción es irreversible, continuar?")){
      props.onDelete(formik.values);
    }
  }

  const handleSelect = (name, value) => {
    console.log('select');
  }

  const handleManualTypeChange = (event) => {
    const checked = event.target.checked;
    if(checked){
      formik.setFieldValue("kind", "manual_flex");
    }
    else{
      formik.setFieldValue("kind", "manual_only");
    }
  }

  const handleBrandChange = (ev, option) => {
    formik.setFieldValue("brightpearl_brand_id", option?.value);
    formik.setFieldValue("brightpearl_brand_option", option); // only for formik
  }

  const handleManualKindChange = (event, newValue) => {
    if(!!newValue){ // does not update when null is passed
      setUseManualFile(newValue);
    }
  }

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    formik.setFieldValue("manual_file_blob", event.target.files[0]);
    formik.setFieldValue("manual_file_filename", event.target.files[0]?.name);
  }

  const handleUploadFileChange = (event) => {
    setBrandUpdateFile(event.target.files[0]);
  }

  const openExportModal = useCallback( () => setShowExportModal(true), []);
  const closeExportModal = useCallback( () => setShowExportModal(false), []);
  const openUploadFileModal = useCallback( () => setShowUploadFileModal(true), []);
  const closeUploadFileModal = useCallback( () => setShowUploadFileModal(false), []);

  const handleExportSubmit = async () => {
    const success = await props.onProductsExport(formik.values, exportEmail);
    if(success){
      closeExportModal();
    }
  }

  const handleExportEmailChange = (event) => {
    setExportEmail(event.target.value);
  }

  const handleBrandFileSubmit = async () => {
    const success = await props.onBrandFileUpload(formik.values, brandUpdateFile);
    if(success){
      closeUploadFileModal();
    }
  }

  const errorAbsoluteStyles = { position: 'absolute', top: '40px', };
  // smart price only (Transform ids from backend(string) into Numbers to match)
  const numberIds = useMemo( () => formik.values.brightpearl_category_ids.map( (id) => Number(id) ), [formik.values.brightpearl_category_ids]);

  // console.log("Formik", formik);

  // Markup
  const ruleMarkup = (
    <Grid container spacing={2}>
      <Grid xs={12} md="auto" display="flex" align-items="center">
        <Typography>Si la categoria es</Typography> 
      </Grid>

      <Grid xs={12} md="auto" display="flex" align-items="center">
        {/* Markup DropdownSelect just handle ONE value but ids is an array on backend, a bit of parsing is required for this rule */}
        <DropdownSelect
          name="brightpearl_category_ids"
          value={ formik.values.brightpearl_category_ids[0] }
          errorText={ formik.errors.brightpearl_category_ids }
          errorTextProps={{ sx: { ...errorAbsoluteStyles, top: "44px" } }}
          
          containerClass='mh8 no-borders'
          sx={{ minWidth: "120px" }}

          options={ props.productCategories } 
          onSelect={ (name, value) => formik.setFieldValue(name, [value]) }
          initialValue=""
        />
      </Grid>

      <Grid xs={12} md="auto" display="flex" align-items="center">
        <Typography>entonces agregar</Typography> 
      </Grid>

      <Grid xs={12} md="auto" display="flex" align-items="center">
        <TextField 
          name="markup_value"
          value={ formik.values.markup_value }
          error={ !!formik.errors.markup_value }
          helperText={ formik.errors.markup_value }
          FormHelperTextProps={{ sx: errorAbsoluteStyles }}

          sx={{ maxWidth: "120px" }} 
          className='mh4 no-borders' 
          size='small' 
          variant="outlined" 
          type="number" 
          onChange={ formik.handleChange }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
      </Grid>

      <Grid xs={12} md="auto" display="flex" align-items="center">
        <Typography>de markup</Typography>
      </Grid >
      <Grid xs={12} display="flex" md={6} mdOffset={0}>
        <Typography><small>{props.pricingRule.updated_at_human}</small></Typography>
      </Grid>

    </Grid>
  );
  // SmartPrice
  const ruleSmartPrice = (
    <Grid container spacing={2}>
      <Grid xs={12} md="auto" display="flex" align-items="center">
        <Typography>Si la categoria es</Typography> 
      </Grid>

      <Grid xs={12} md="auto" display="flex" align-items="center">
        <DropdownSelect 
          name="brightpearl_category_ids"
          value={ numberIds }
          errorText={ formik.errors.brightpearl_category_ids }
          errorTextProps={{ sx: { ...errorAbsoluteStyles, top: "44px" } }}

          containerClass='mh4 max-width-400 no-borders'
          options={ props.productCategories } 
          onSelect={ formik.setFieldValue }
          initialValue={[]}

          multiple
          displayEmpty
          placeholder='-'
        />
      </Grid>

      <Grid xs={12} md="auto" display="flex" align-items="center">
        <Typography>entonces agregar a IN.</Typography> 
      </Grid>
    </Grid>
  )

  // Manual
  const ruleManual = (
    <div>
      <ToggleButtonGroup
        className='mb8'
        color="primary"
        value={ manualKind }
        exclusive
        onChange={ handleManualKindChange }
      >
        <ToggleButton value={ manualKinds.brand }>Marca</ToggleButton>
        <ToggleButton value={ manualKinds.file }>Archivo SKU</ToggleButton>
      </ToggleButtonGroup>
      <div className='flex align-items-center mv4'>
        { manualKind === manualKinds.brand && (
          <div className='flex align-items-center'>
            <Typography>Si la marca es</Typography>
            <Autocomplete
              size='small'
              className='mh4 no-borders'
              value={ formik.values.brightpearl_brand_id }
              options={ props.productBrands } 
              
              sx={{ width: 300 }}
              onChange={ handleBrandChange }
            />
          </div>
        )}

        { manualKind === manualKinds.file && (
          <div className='flex align-items-center'>
            <Typography>Si Archivo SKUs es</Typography>
            <Button variant="text" component="label" endIcon={ <UploadIcon/> }>
              { formik.values.manual_file_filename || 'Seleccionar archivo' }
              <input hidden accept=".xlsx,.xls" onChange={ handleFileChange } type="file" />
            </Button>
          </div>
        )}

        <Typography>entonces actualizar manualmente</Typography>
      </div>

      <FormControlLabel
        control={
          <Checkbox 
            checked={ formik.values.kind == "manual_flex" }
            onChange={ handleManualTypeChange }
          />
        }
        label="Seguir actualizando con IN las coincidencias que si tengan matches"
      />
      <Grid xs={12} display="flex" md={6} mdOffset={0}>
        <Typography><small>{props.pricingRule.updated_at_human}</small></Typography>
      </Grid>

      { formik.errors.manual_file && (
        <FormHelperText error>
          { formik.errors.manual_file }
        </FormHelperText>
      )}
    </div>
  )

  const itemBody = () => {
    switch(formik.values.kind){
      case "markup": return ruleMarkup;
      case "smart_price": return ruleSmartPrice;
      case "manual_only": case "manual_flex": return ruleManual;
    }
  }

  if(props.pricingRule.deleted) {
    return null;
  }
  return (
    <div>
      <ListItem
        className='mb12'
        secondaryAction={
          // Actions - Right side
          <div className='flex'>
            <Tooltip title="Guardar">
              <IconButton color="primary" disabled={ props.disabled || formik.isSubmitting } onClick={ formik.handleSubmit }>
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton  color="error" disabled={ props.disabled || formik.isSubmitting } onClick={ handleDelete }>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
      
            {/* Manual "File" - Simple manual file download */}
            { !!formik.values.manual_file_url &&
              <Tooltip title="Descargar archivo SKU">
                <IconButton color="info" disabled={ props.disabled } href={ formik.values.manual_file_url }>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            }
      
            {/* Manual "Brand" */}
            {/* Send to email xlsx with products */}
            { !!formik.values.id && !formik.values.manual_file_url &&
              <Tooltip title="Exportar productos">
                <IconButton color='info' disabled={ props.disabled || !formik.values.export_allowed } onClick={ openExportModal }>
                  <SendAndArchiveIcon />
                </IconButton>
              </Tooltip>
            }

            {/* Manual "Brand" */}
            {/* Update prices with file (no save on DB) */}
            { !!formik.values.id && formik.values.brightpearl_brand_id &&
              <Tooltip title="Actualizar precios con archivo">
                <IconButton color='info' disabled={ props.disabled } onClick={ openUploadFileModal }>
                  <UploadFileIcon/>
                </IconButton>
              </Tooltip>
            }
          </div>
        }
      >
        <ListItemAvatar>
          <Avatar>
            { formik.values.id ? <span>{ formik.values.id }</span> : <BuildIcon /> }
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={ itemBody() }
          secondary=""
        />
      </ListItem>

      
      <Modal
        open={ showExportModal }
        onClose={ closeExportModal }
      >
        <Card style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
        }}>
          <CardHeader
            title="Exportar productos"
          />
          <CardContent>
            <TextField
              label="Email"
              fullWidth
              onChange={ handleExportEmailChange }
            />
            <FormHelperText>
              Se enviará un archivo excel con los productos que coinciden con la regla. Asegurese que el email sea correcto
            </FormHelperText>
            <Button fullWidth variant="contained" color='primary' onClick={ handleExportSubmit }>
              Exportar
            </Button>
          </CardContent>
        </Card>
      </Modal>

      {/* TODO DRY Modals */}
      {/* Upload SKU file for brands */}
      { !!formik.values.id && formik.values.brightpearl_brand_id && (
        <Modal
          open={ showUploadFileModal }
          onClose={ closeUploadFileModal }
        >
          <Card style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
          }}>
            <CardHeader
              title="Actualizar con archivo"
            />
            <CardContent>
              <Button fullWidth variant="text" component="label" endIcon={ <UploadIcon/> }>
                { brandUpdateFile?.name || 'Seleccionar archivo' }
                <input hidden accept=".xlsx,.xls" onChange={ handleUploadFileChange } type="file" />
              </Button>
              <FormHelperText>
                Se creara un task para actualizar el precio de los productos introducidos por su ID inmediatamente.
              </FormHelperText>
              <Button fullWidth variant="contained" color='primary' onClick={ handleBrandFileSubmit } disabled={ props.loading }>
                Cargar
              </Button>
            </CardContent>
          </Card>
        </Modal>
      )}
    </div>
  )
};

PricingRuleItem.propTypes = propTypes;
PricingRuleItem.defaultProps = defaultProps;

export default PricingRuleItem;