import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import { Paper } from "@mui/material";
import { useNode } from '@craftjs/core';

const propTypes = {
  background: PropTypes.string,
  padding: PropTypes.number,
}

const defaultProps = {
  background: '#ffffff',
  padding: 8,
}

// Currently used simply as "canvas block" to drop other blocks
const BlockContainer = ({ background, padding, children, ...props }) => {
  const { connectors: { connect, drag } } = useNode();
  return (
    <div 
      {...props}
      className="block-container"
      style={{ minHeight: 300, }}
      ref={(ref) => connect(drag(ref))}
    >
      {children}
    </div>
  )
};

BlockContainer.craft = {
  props: defaultProps,
  related: {
    name: () => "Canvas",
  }
}

BlockContainer.propTypes = propTypes;
BlockContainer.defaultProps = defaultProps;

export default BlockContainer;