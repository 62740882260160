import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Chip, Grid, Typography, Button as MaterialButton, FormControl, FormLabel, Slider } from "@mui/material";
import { HighlightAlt } from '@mui/icons-material';

import { useEditor } from "@craftjs/core";
import { useFormikContext } from 'formik';

const propTypes = {

}

const defaultProps = {

}

const ComponentSettings = (props) => {
  const { isSubmitting } = useFormikContext();

  // From editor context, get the selected node element and its properties (from Component.craft={...})
  const { actions, selected, isEnabled } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').last();
    let selected;

    if (currentNodeId) {
      const nodeInfo = state.nodes[currentNodeId];
      // console.log("Node Info", nodeInfo);
      selected = {
        id: currentNodeId,
        name: nodeInfo.related?.name() || nodeInfo.data.name,
        settings: nodeInfo.related && nodeInfo.related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
      isEnabled: state.options.enabled,
    };
  });

  const panelPlaceholder = (
    <Grid item>
      <div style={{ textAlign: "center" }}>
        <HighlightAlt />
        <p>
          Seleccione un elemento de la página para editarlo aqui
        </p>
      </div>
    </Grid>
  )

  // console.log("Selected", selected);

  const panelActive = !!selected && !!isEnabled;

  return  (    
    <Box bgcolor="rgba(0, 0, 0, 0.06)" mt={2} px={2} py={2} className='settings-box'>
      <Grid container direction="column" spacing={0}>

        { !panelActive && panelPlaceholder }

        { panelActive && 
          <>
            <Grid item>
              <Box pb={2}>
                <Grid container alignItems="center">
                  <Grid item xs><Typography variant="subtitle1">{ selected.name || "Namae" }</Typography></Grid>
                  <Grid item><Chip size="small" color="primary" label="Selected" /></Grid>
                </Grid>
              </Box>
            </Grid>
    
            {/* If selected element has special component to manipulate settings render it */}
            { selected.settings && 
              <Box
                className="pv12"
                sx={{
                  '& .MuiTextField-root': { marginTop: "4px", marginBottom: "4px" },
                }}
              >
                { React.createElement(selected.settings) }
              </Box>
            }
    
            { selected.isDeletable &&
              <MaterialButton
                variant="contained"
                color="error"
                onClick={() => {
                  actions.delete(selected.id);
                }}
                disabled={isSubmitting}
              >
                Delete
              </MaterialButton>
            }
          </>
        }
      </Grid>
    </Box>
  ) 
};

ComponentSettings.propTypes = propTypes;
ComponentSettings.defaultProps = defaultProps;

export default ComponentSettings;