import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import Page from 'shared/AppPage';

import { 
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Chip,
  Pagination, TextField, CircularProgress,
  Avatar
} from '@mui/material';

const propTypes = {
  records: PropTypes.array.isRequired,
}

const defaultProps = {

}

const IngramImageRegistryIndex = (props) => {

  // Ordered
  console.log("PROPS", props.records)
  const fields = ["id","fileSource", "zipName", "itemsCount", "fileDate", "s3DirectoryPath"]

  return (
    <Page title="IngramImageRegistry">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              { fields.map((key) =>(
                <TableCell key={key}>{ key }</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { props.records.map((registry) => (
              <TableRow key={registry.id}>
                { fields.map((key) => (
                  <TableCell key={key}>{ registry[key] }</TableCell>
                ))}
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  )
};

IngramImageRegistryIndex.propTypes = propTypes;
IngramImageRegistryIndex.defaultProps = defaultProps;

export default IngramImageRegistryIndex;