import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FormLabel, Divider, Grid } from '@mui/material';

import { useNode } from '@craftjs/core';
import { useFormikContext } from 'formik';

import PreviewEditableTitleAndDescription from './components/PreviewEditableTitleAndDescription';
import SettingsBannerWithDescription from './components/SettingsBannerWithDescription';
import PreviewBannerImage from './components/PreviewBannerImage';

import * as SHAPES from './types';

const propTypes = {
  item1: PropTypes.shape(SHAPES.shapeForBannerWithDescription),
  item2: PropTypes.shape(SHAPES.shapeForBannerWithDescription),
}

const defaultProps = {
  item1: SHAPES.defaultPropsForBannerWithDescription,
  item2: SHAPES.defaultPropsForBannerWithDescription,
}

const BannerDuo = (props) => {
  const { status } = useFormikContext();

  const { 
    item1, item2,
    ...builderProps 
  } = props;
  
  const { connectors: { connect, drag }, selected, actions: { setProp } } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  const gridItemSize = status.viewport === "DESKTOP" ? 6 : 12;

  return (
    <div
      className="user-block user-banner-duo"
      ref={ref => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
      {...builderProps}
    >
      <Grid container spacing={3}>
        <BannerDuoItem item={item1} gridItemSize={gridItemSize} editable={editable} sequence={1} />
        <BannerDuoItem item={item2} gridItemSize={gridItemSize} editable={editable} sequence={2} />
      </Grid>
    </div>
  )
};
// Extract this into a preview component, is the exact same for Trio and Quad!
const BannerDuoItem = ({ item, gridItemSize, editable, sequence }) => {
  const valuesPrefix = `item${sequence}`; // Keys need to have the full path to be able to write on the parent component PROPS.
  return(
    <Grid item xs={gridItemSize}>
      <div className='item-container'>
        <PreviewBannerImage banner={item}/>
  
        <PreviewEditableTitleAndDescription
          values={{ [valuesPrefix]: item}}
          titleKey={`${valuesPrefix}.title`}
          descriptionKey={`${valuesPrefix}.description`}
          editable={editable} 
        />
      </div>
    </Grid>
  )
};

// Settings panel for block
const BannerDuoSettings = () => {
  return (
    <>
      <FormLabel>Banner 1</FormLabel>
      <SettingsBannerWithDescription
        imageUrlKey={"item1.imageUrl"}
        resourceIdKey={"item1.resourceId"}
        resourceUrlKey={"item1.resourceUrl"}
        imageSettings={{
          sequence: 1,
          display_type: "GENERAL"
        }}
        titleKey={"item1.title"}
        descriptionKey={"item1.description"}
        imageWidth={580}
        imageHeight={386}
      />

      <Divider className='mv8' />
      
      <FormLabel>Banner 2</FormLabel>
      <SettingsBannerWithDescription
        imageUrlKey={"item2.imageUrl"}
        resourceIdKey={"item2.resourceId"}
        resourceUrlKey={"item2.resourceUrl"}
        imageSettings={{
          sequence: 2,
          display_type: "GENERAL"
        }}
        titleKey={"item2.title"}
        descriptionKey={"item2.description"}
        imageWidth={580}
        imageHeight={386}
      />
    </>
  )
}

BannerDuo.craft = {
  props: defaultProps,
  related: {
    name: () => "Banner Duo",
    settings: BannerDuoSettings
  }  
}

BannerDuo.propTypes = propTypes;
BannerDuo.defaultProps = defaultProps;

export default BannerDuo;