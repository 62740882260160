import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNode } from '@craftjs/core';

import { FormLabel, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AlignHorizontalLeft, AlignHorizontalRight } from '@mui/icons-material';

import { useFormikContext } from 'formik';

import PreviewEditableTitleAndDescription from './components/PreviewEditableTitleAndDescription';
import SettingsBannerWithDescription from './components/SettingsBannerWithDescription';
import PreviewBannerImage from './components/PreviewBannerImage';

import * as SHAPES from './types';

const propTypes = {
  banner: PropTypes.shape({
    ...SHAPES.shapeForBannerWithDescription,
    inverse: PropTypes.bool,
    titleTag: PropTypes.string,
  }),
}

const defaultProps = {
  banner: {
    ...SHAPES.defaultPropsForBannerWithDescription,
    inverse: false,
    titleTag: "h2",
  },
}

// Rename to BannerWithDescriptionHorizontal to avoid confusion with Banners with description (on bottom of banner)
const BannerWithDescription = (props) => {
  const { status } = useFormikContext();

  const { banner, ...builderProps } = props;
  
  const { connectors: { connect, drag }, selected, actions: { setProp } } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  const gridItemSize = status.viewport === "DESKTOP" ? 6 : 12;

  return (
    <div
      className={`user-block user-banner-with-description ${banner.inverse ? 'inverse' : ''}`}
      ref={ref => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
      {...builderProps}
    >
      <Grid container spacing={3} direction={ banner.inverse ? "row-reverse" : "row" }>
        <Grid item xs={gridItemSize}>
          <div className='item-container'>
            <PreviewBannerImage  banner={banner} />
          </div>
        </Grid>

        <Grid item xs={gridItemSize}>
          <div className='item-container'>
            <PreviewEditableTitleAndDescription
              setProp={setProp} 
              editable={editable} 
              values={{ banner }}
              titleKey="banner.title"
              titleTag={banner.titleTag || "h2"}
              descriptionKey="banner.description"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
};

// Optional text content for banner


// Settings panel for block
const BannerWithDescriptionSettings = () => {
  const { actions: {setProp}, banner } = useNode((node) => ({
    banner: node.data.props.banner,
  }));

  const handleAlignmentChange = (e, value) => {
    if(!!value){
      setProp(props => props.banner.inverse = (value === 'right'))
    }
  }

  return (
    <>
      <FormLabel>
        Alineamiento
      </FormLabel>
      <ToggleButtonGroup
        exclusive
        fullWidth
        size='small'
        value={banner.inverse ? 'right' : 'left'}
        onChange={ handleAlignmentChange }
        aria-label="text alignment"
      >
        <ToggleButton value="left" aria-label="left aligned">
          <AlignHorizontalLeft />
        </ToggleButton>
        <ToggleButton value="right" aria-label="right aligned">
          <AlignHorizontalRight />
        </ToggleButton>
      </ToggleButtonGroup>

      <FormLabel>
        Banner
      </FormLabel>
      <SettingsBannerWithDescription
        imageUrlKey="banner.imageUrl"
        resourceIdKey="banner.resourceId"
        resourceUrlKey="banner.resourceUrl"
        titleKey="banner.title"
        descriptionKey="banner.description"
        imageWidth={580}
        imageHeight={386}
      />
    </>
  )
}

BannerWithDescription.craft = {
  props: defaultProps,
  related: {
    name: () => "Banner con Descripción",
    settings: BannerWithDescriptionSettings
  }  
}

BannerWithDescription.propTypes = propTypes;
BannerWithDescription.defaultProps = defaultProps;

export default BannerWithDescription;