import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';

import ExampleCardFront from 'images/gateway/cvv-tarjeta-1.png';
import ExampleCardBack from 'images/gateway/cvv-tarjeta-2.png';
import QuestionIcon from 'images/gateway/question.png'; // Temporal, should use real icon

import MuiInput from './MuiInput'; // (temporal) sus inputs ඞ

import { formatCreditCardNumber, formatExpirationDate, formatCVC } from 'utils';

const propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  values: PropTypes.shape({
    ccnumber: PropTypes.string,
    cname: PropTypes.string,
    clastname: PropTypes.string,
    ccexp: PropTypes.string,
    cvv: PropTypes.string
  }),

  handleChange: PropTypes.func.isRequired,
}

const defaultProps = {
  labelClassName: null,
}

const CardForm = (props) => {
  const handleInputChange = ({ target }) => {
    let value = target.value;
    if (target.name === "ccnumber") {
      value = formatCreditCardNumber(target.value);
    } else if (target.name === "ccexp") {
      value = formatExpirationDate(target.value);
    } else if (target.name === "cvv") {
      value = formatCVC(target.value);
    }
    else{ // Validate cname/clastname
      const validCharactersRegex = /^[a-zA-Z\s]*$/;
      value = value.replace(/[^a-zA-Z\s]/g, '');
    }

    props.handleChange(target.name, value);
  };

  const { values } = props;

  return (
    <div className='card-form'>
      <MuiInput
        name="ccnumber"
        value={ values.ccnumber }
        label="Número de tarjeta"
        onChange={ handleInputChange }
      />

      {/* Name */}
      <Row>
        <Col sm="12" lg="6">
          <MuiInput
            name="cname"
            value={ values.cname }
            label="Nombre del titular"
            pattern="[a-zA-Z ]+"
            onChange={ handleInputChange }
          />
        </Col>

        <Col sm="12" lg="6">
          <MuiInput
            name="clastname"
            value={ values.clastname }
            label="Apellido del titular"
            onChange={ handleInputChange }
          />
        </Col>
      </Row>
      
      <Row>
        <Col sm="12" lg="6">
          <MuiInput
            name="ccexp"
            value={ values.ccexp }
            label="Fecha de vencimiento (MM / AA)"
            pattern="\d\d/\d\d"
            onChange={ handleInputChange }
          />
        </Col>

        {/* Shown as password to comply with Davivienda/Evertec requirements */}
        <Col sm="12" lg="6">
          <MuiInput
            name="cvv"
            value={ values.cvv }
            label="Código de seguridad"
            type="password"
            pattern="\d{3,4}"
            onChange={ handleInputChange }
          />
          <CVVPopover/>
        </Col>
      </Row>
    </div>
  )
};

const CVVPopover = () => (
  <OverlayTrigger trigger="focus" placement="right" overlay={
    <Popover id="cvv-popover">
      <Popover.Content>
        <h5>CVV</h5>
        <p>
          Es un código de seguridad de 3 o 4 dígitos que se encuentra al <br />
          reverso de su tarjeta, a excepción de las American Express que se <br />
          encuentra al frente.
                      </p>
        <div className='flex'>
          <img src={ ExampleCardFront } alt='tarjeta1' />
          <img src={ ExampleCardBack } alt='tarjeta2' />
        </div>
      </Popover.Content>
    </Popover>
  }>
    <Button id='cvv-popover-trigger' variant="link">
      <img src={ QuestionIcon } alt="about cvv" />
    </Button>
  </OverlayTrigger>
);

CardForm.propTypes = propTypes;
CardForm.defaultProps = defaultProps;

export default CardForm;