import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  Tooltip, IconButton, Typography,
  TextField, Switch, FormGroup, FormControlLabel,
  ListItem, ListItemAvatar, ListItemText, Avatar,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import { useFormik } from 'formik';

const propTypes = {
  product: PropTypes.object.isRequired,

  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const defaultProps = {

}

const MembershipProductItem = (props) => {
  const formik = useFormik({
    initialValues: props.product,
    onSubmit: props.onSave,
    enableReinitialize: true,
  });


  const handleDelete = () => {
    props.onDelete(formik.values);
  }

  const handleActiveChange = (event) => {
    formik.handleChange(event);
    formik.handleSubmit();
  }

  const { values } = formik;

  if(props.product.deleted) {
    return null;
  }

  return (
    <ListItem
      secondaryAction={
        <div className='flex align-items-center'>
          <FormGroup>
            <FormControlLabel 
              label="Activo" 
              className='mb0' 
              control={<Switch name="active" onChange={ handleActiveChange } checked={ values.active } />} 
            />
          </FormGroup>

          <Tooltip title="Guardar">
            <IconButton color="primary" disabled={ props.disabled || formik.isSubmitting } onClick={ formik.handleSubmit }>
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton  color="error" disabled={ props.disabled || formik.isSubmitting } onClick={ handleDelete }>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
    >
      <ListItemAvatar>
        <Avatar src={ values.shopify_image } />
      </ListItemAvatar>  
      <ListItemText
        primary={
          <form onSubmit={ formik.handleSubmit }>
            <TextField
              name="price"
              label={ "Precio" }
              variant="outlined"
              type="number"
              size="small"
              
              value={ values.price }
              onChange={ formik.handleChange }
            />
          </form>
        }
        secondary={
          <>
            <span className='display-block'>{ values.sku }</span>
            <span className='display-block'>Precio actual: { values.shospanify_price || "0.00" }</span>
          </>
        }
      />
    </ListItem>
  )
};

MembershipProductItem.propTypes = propTypes;
MembershipProductItem.defaultProps = defaultProps;

export default MembershipProductItem;