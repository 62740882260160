import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useNode } from '@craftjs/core';
import { useFormikContext } from 'formik';

import { FormControl, FormLabel } from '@mui/material';
import SettingsBannerWithDescription from './components/SettingsBannerWithDescription';
import PreviewBannerImage from './components/PreviewBannerImage';

import useImageAppPageAsset from './components/useImageAppPageAsset';

import { shapeForBanner, defaultPropsForBanner } from './types';

const propTypes = {
  desktop: PropTypes.shape(shapeForBanner),
  mobile: PropTypes.shape(shapeForBanner),
}

const defaultProps = {
  desktop: defaultPropsForBanner,
  mobile: defaultPropsForBanner, // no resourceUrl, use the same as desktop
}

const BannerPrimary = (props) => {
  const { status } = useFormikContext();
  const { desktop, mobile, ...builderProps } = props;
  const { connectors: { connect, drag }, } = useNode();

  const bannerItem = status.viewport === "MOBILE" ? mobile : desktop;

  return (
    <div
      className={`user-block user-banner-primary`}
      ref={ref => connect(drag(ref))}
      {...builderProps}
    >
      <PreviewBannerImage 
        banner={{
          ...bannerItem,
          resourceUrl: desktop.resourceUrl
        }} 
      />
    </div>
  )
};

// Settings panel for block
const BannerPrimarySettings = () => {
  return (
    <>
      <SettingsBannerWithDescription
        imageUrlKey={"desktop.imageUrl"}
        resourceIdKey={"desktop.resourceId"}
        resourceUrlKey={"desktop.resourceUrl"}
        imageSettings={{
          display_type: "DESKTOP"
        }}
        imageWidth={1200}
        imageHeight={400}
      />
      
      <SettingsBannerWithDescription
        imageUrlKey={"mobile.imageUrl"}
        resourceIdKey={"mobile.resourceId"}
        resourceUrlKey={"mobile.resourceUrl"}
        imageSettings={{
          display_type: "MOBILE"
        }}
        imageWidth={360}
        imageHeight={360}
        withUrl={false}
      />
    </>
  )
}

BannerPrimary.craft = {
  props: defaultProps,
  related: {
    name: () => "Banner Full",
    settings: BannerPrimarySettings
  }  
}

BannerPrimary.propTypes = propTypes;
BannerPrimary.defaultProps = defaultProps;

export default BannerPrimary;