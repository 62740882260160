import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PreviewEditableTitleAndDescription from './components/PreviewEditableTitleAndDescription';
import SettingsBannerWithDescription from './components/SettingsBannerWithDescription';
import PreviewBannerImage from './components/PreviewBannerImage';

import { useNode } from '@craftjs/core';
import { useFormikContext } from 'formik';

import { Grid, FormLabel, Divider } from '@mui/material';

import * as SHAPES from './types';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...SHAPES.shapeForBanner,
      title: PropTypes.string,
      // no description
    })
  ).isRequired,
  itemsCount: PropTypes.number.isRequired,
}

const defaultProps = {
  items: [],
  itemsCount: 6, // Currently un-editable, byt could be in the future
}

const CategoriesSection = (props) => {
  const { status } = useFormikContext();

  const { items, itemsCount, ...builderProps } = props;

  const { connectors: { connect, drag }, selected, actions: { setProp } } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  useEffect(() => {
    if(items.length != itemsCount){
      setProp(props => {
        props.items = Array.from({ length: itemsCount }, (_, index) => ({
          imageUrl: '',
          title: '',
        }));
      });
    }
  }, [itemsCount]);

  const gridItemSize = status.viewport === "DESKTOP" ? 2 : 6;

  return (
    <div
      className="user-block user-categories-section"
      ref={ref => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
      {...builderProps}
    >
      <Grid container spacing={3} justifyContent="space-between">
        { items.map((item, index) => (
          <CategoriesSectionItem 
            index={index}
            key={index}
            item={item}
            items={items}
            gridItemSize={gridItemSize} 
            editable={editable} 
          />
        ))}
      </Grid>
    </div>
  )
};

// BannerDuo, BannerTrio, and CategoriesSection are all similar components with this setup of image, title, and description... maybe can move this to its own component!
const CategoriesSectionItem = ({ items, item, gridItemSize, editable, index }) => {
  const valuesPrefix = `items.${index}`; // Collection of items. i.e. items.0.title, items.0.imageUrl
  return(
    <Grid item xs={gridItemSize}>
      <div className='item-container'>
        <PreviewBannerImage banner={item} />
  
        <PreviewEditableTitleAndDescription
          values={{
            items: items
          }}
          titleKey={`${valuesPrefix}.title`}
          editable={editable} 
        />
      </div>
    </Grid>
  )
};

const CategoriesSectionSettings = () => {
  const { id: nodeId, actions: { setProp }, props } = useNode((node) => ({
    props: node.data.props,
  }));

  return(
    <>
      { props.items.map((item, index) => {
        const itemPrefix = `items.${index}`; // items[].title, items[].imageUrl
        return(
          <React.Fragment key={index}>
            <FormLabel>
              Categoria {index + 1}
            </FormLabel>
            <SettingsBannerWithDescription
              imageUrlKey={`${itemPrefix}.imageUrl`}
              resourceIdKey={`${itemPrefix}.resourceId`}
              resourceUrlKey={`${itemPrefix}.resourceUrl`}
              titleKey={`${itemPrefix}.title`}
              imageSettings={{
                sequence: index + 1, // Sequence is 1-indexed
                display_type: "GENERAL"
              }}
              imageWidth={155}
              imageHeight={155}
            />
            <Divider />
          </React.Fragment>
        )
      })}
    </>
  )
};

CategoriesSection.craft = {
  props: defaultProps,
  related: {
    name: () => "Categorías",
    settings: CategoriesSectionSettings
  }  
}

CategoriesSection.propTypes = propTypes;
CategoriesSection.defaultProps = defaultProps;

export default CategoriesSection;