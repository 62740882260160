import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import Col from 'react-bootstrap/Col';

const propTypes = {
  errors: PropTypes.shape({
    // canton: PropTypes.string,
    // province: PropTypes.string,
    // district: PropTypes.string,
  }),

  locationsDataset: PropTypes.object.isRequired, // { "San José": { cantons: { "San José": { districts: { "Carmen": { zip_code: "1000" } } } } ... }
  onChange: PropTypes.func.isRequired,

  initialValues: PropTypes.shape({
    province: PropTypes.string,
    canton: PropTypes.string,
    district: PropTypes.string,
  }),
}

const defaultProps = {
  initialValues: {
    province: null,
    canton: null,
    district: null,
  }
}

// PENDING add errors like AppInput
const LocationDropdowns = (props) => {
  const locationsDataset = props.locationsDataset;
  
  const [selectedValues, setSelectedValues] = useState(props.initialValues);
  const [provinces, setProvinces] = useState( Object.keys(locationsDataset) );
  const [cantons, setCantons] = useState([]);
  const [districts, setDistricts] = useState([]);

  // Initialize options if initialValues are set
  useEffect(() => {
    if(props.initialValues.province){
      const province = handleProvinceChange({ target: { value: props.initialValues.province } });
      if(province){ // check if province was valid before continuing
        const canton = handleCantonChange({ target: { value: props.initialValues.canton } });
        if(canton){ // check if canton was valid before continuing
          handleDistrictChange({ target: { value: props.initialValues.district } });
        }
      }
    }
  }, []);

  const handleProvinceChange = (event) => {
    const value = event.target.value;
    props.onChange("province", value);
    const province = locationsDataset[value];

    if(!!province){
      setCantons( Object.keys(province.cantons) );
      setDistricts([]);
      setSelectedValues({ province: province.name, canton: null, district: null });
    }

    return province;
  }

  const handleCantonChange = (event) => {
    const value = event.target.value;
    props.onChange("canton", value);

    const canton = locationsDataset[selectedValues.province].cantons[value];
    if(!!canton){
      setDistricts( Object.keys(canton.districts) );
      setSelectedValues({ ...selectedValues, canton: canton.name, district: null });
    }

    return canton;
  }
  
  const handleDistrictChange = (event) => {
    const value = event.target.value;
    props.onChange("district", value);

    const zipValue = locationsDataset[selectedValues.province].cantons[selectedValues.canton].districts[value]?.zip_code;
    props.onChange("zip", zipValue);
    setSelectedValues({ ...selectedValues, district: value });

    return zipValue;
  }

  return (
    <>
      <Dropdown
        value={ selectedValues.province }
        label="Provincia" name="province" options={ provinces }
        onChange={ handleProvinceChange }
      />

      <Dropdown
        value={ selectedValues.canton }
        label="Cantón" name="canton" options={ cantons }
        onChange={ handleCantonChange }
      />

      <Dropdown
        value={ selectedValues.district }
        label="Distrito" name="district" options={ districts }
        onChange={ handleDistrictChange }
      />

    </> 
  )
};

const Dropdown = (props) => (
  <Form.Group as={Col} sm={4} xs={12}>
    <Form.Label>{ props.label }</Form.Label>
    <Form.Control value={ props.value } onChange={ props.onChange } name={ props.name }  as="select" custom>
      <option key={ "placeholder" } value={ "" }>{ "Seleccionar..." }</option>
      { props.options.map( (option, index) =>
        <option key={ option} value={ option }>{ option }</option>
      )}
    </Form.Control>
  </Form.Group>
);

LocationDropdowns.propTypes = propTypes;
LocationDropdowns.defaultProps = defaultProps;

export default LocationDropdowns;