import {
  Editor,
  Transforms,
  Element as SlateElement
} from 'slate';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

// Block node types
export const ELEMENT_TYPES = {
  BLOCK_QUOTE: 'block-quote',
  BULLET_LIST: 'bulleted-list',
  NUMBERED_LIST: 'numbered-list',
  LIST_ITEM: 'list-item', // child of bulleted-list or numbered-list
  HEADING_ONE: 'heading-one',
  HEADING_TWO: 'heading-two',
  PARAGRAPH: 'paragraph',
  // DEFAULT is paragraph
};

export const LEAF_TYPES = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  LINK: 'link',
  CODE: 'code',
}

/**
 * Add remove "type" property from node.
 * If type is align, add/remove "align" property from node.
 * @param {*} editor 
 * @param {*} format 
 */
export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, { // Split list nodes on selection (if any)
    match: n =>
      !Editor.isEditor(n) && // Not the editor itself
      SlateElement.isElement(n) && // Is an element
      LIST_TYPES.includes(n.type) && // Is a list
      !TEXT_ALIGN_TYPES.includes(format), // Not an alignment (special kind of "block") - Actually property
    split: true,
  });

  let newProperties; // Properties to add/remove on node // Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) { // Alignments can be applied as an extra property to the block, so a block can have "type" and "align" properties.
    newProperties = {
      align: isActive ? undefined : format, // Toggle off if active, set alignment format if not active
    }
  } 
  else { // Block node type.
    let newType; // type property to set on node
    if(isActive){ // When disabling a block, set the type back to 'paragraph'
      newType = 'paragraph';
    }
    else if(isList){ // Toggling on a list (bullet/numbered), set the type to 'list-item'
      newType = 'list-item';
    }
    else{ // Toggling on unique block types
      newType = format;
    }

    console.log("Toggle Block", newType, format, isActive, isList);
      
    newProperties = { type: newType }
  }
  Transforms.setNodes(editor, newProperties) // Update node properties on selection

  if (!isActive && isList) { // When toggling on a list, wrap nodes into new list block
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

/**
 *  Add/Remove "mark" from selection, this allow to create text formatting like bold, italic, underline, etc. (inline blocks)
 * markValue is used to set the value of the mark, for most marks, there is no value required so true is used by default. For links, the value is the URL.
 * @param {*} editor 
 * @param {*} format 
 * @param {*} markValue 
 */
export const toggleMark = (editor, format, markValue = true) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format); // Remove mark on selection
  } 
  else {
    Editor.addMark(editor, format, markValue); // Add mark on selection
  }
}

/** For a given editor selection, check if the mark is active for the given "format" (e.g. 'bold', 'italic') */
export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  if(marks && marks[format]){ // There are marks and the mark is set for the "format"
    switch(format){
      case 'link':
        return !!marks[format]; // Non-empty string
        break;
      default:
        return marks[format] === true; // for general marks, check if value is exactly true
    }
  }
  else{ // No marks or mark for the format is not set
    return false;
  }
};

/**
 * For a given editor selection, check if the block is active for the given "format" (e.g. 'numbered-list', 'bulleted-list'), not to be confused with "mark" (e.g. 'bold', 'italic')
 * blockType can be 'type' or 'align'.
 * About alignments:
 * 1. Alignments are stored in the "align" property of the element as only one alignment can be applied at a time.
 * 2. Alignments are not exactly "blocks" or "marks" as they do not insert new elements, but rather apply a property to the existing block as styles, they are treated as blocks because they are applied on block level.
 * @param {*} editor 
 * @param {*} format 
 * @param {*} blockType 
 * @returns 
 */
export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) =>
        !Editor.isEditor(node) &&
        SlateElement.isElement(node) &&
        node[blockType] === format,
    })
  )

  return !!match
};
