import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Toast from 'react-bootstrap/Toast';

const propTypes = {
  toastStyle: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,

  handleClose: PropTypes.func.isRequired,
}

const defaultProps = {
  toastStyle: {
    right: "-38px",
    top: 0
  }
}

const AppToast = (props) => {
  return (
    <Toast style={{ position: "absolute", zIndex: 90, ...props.toastStyle }} autohide delay={3500} show={ !!props.message } onClose={ props.handleClose }>
      <Toast.Header>
        <strong className="mr-auto">{ props.title }</strong>
      </Toast.Header>
      <Toast.Body>
        { props.message }
      </Toast.Body>
    </Toast>
  )
};

AppToast.propTypes = propTypes;
AppToast.defaultProps = defaultProps;

export default AppToast;